import Modal from "react-modal";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
const timestampToSeconds = require("timestamp-to-seconds");
import axios from "axios";
import Datetime from "react-datetime";
import date from "date-and-time";
import { useTranslation } from "react-i18next";

import filters from "../filters";
import timeManager from "../../models/timeManager";
import { server } from "../../config";
import ColorGrading from "./ColorGrading";
import Input from "../shared/UI/Input/Input";

var convert = require("color-convert");

Modal.setAppElement(document.body);

const AddFilterDialog = (props) => {
  const [filter, setFilter] = useState(filters.videoFilters[0].id);
  const [level, setLevel] = useState(100);
  const { t } = useTranslation();
  const [showColorGradientModal, setShowColorGradientModal] = useState(false);

  const [filterAlreadyApplied, setFilterAlreadyApplied] = useState(false);
  const [startDuration, setStartDuration] = useState("");
  const [endDuration, setEndDuration] = useState("");
  const [soundDbLevel, setSoundDbLevel] = useState(20);
  const [initialValueStartDuration, setInitialValueStartDuration] =
    useState(null);
  const [initialValueEndDuration, setInitialValueEndDuration] = useState(null);
  const [initialValueSoundDb, setInitialValueSoundDb] = useState(null);
  const [fadeInFadeOutLevel, setFadeInFadeOutLevel] = useState(1);
  const [currentFilterName, setCurrentFilterName] = useState("");
  const [hexColor, setHexColor] = useState("");

  useEffect(() => {
    if (props.filterName) {
      if (props.filterName === "Fade Out Volume") {
        setCurrentFilterName(t("shared.decreaseVolume"));
      } else if (props.filterName === "Fade In Volume") {
        setCurrentFilterName(t("shared.increaseVolume"));
      } else if (props.filterName === "Fade In Brightness") {
        setCurrentFilterName("Fade In");
      } else if (props.filterName === "Fade Out Brightness") {
        setCurrentFilterName("Fade Out");
      } else if (props.filterName === "Background Colour") {
        setCurrentFilterName(t("timeline.colorOverlay"));
      } else {
        setCurrentFilterName(props.filterName);
      }
    }
  }, [props.filterName]);

  useEffect(() => {
    let stateFiltername;
    if (
      props.filterName === "Brightness" ||
      props.filterName === "brightness"
    ) {
      stateFiltername = "brightness";
    } else if (
      props.filterName === "Background Colour" ||
      props.filterName === "lift_gamma_gain"
    ) {
      stateFiltername = "lift_gamma_gain";
    } else if (
      props.filterName === "Fade In Brightness" ||
      props.filterName === "fadeInBrightness"
    ) {
      stateFiltername = "fadeInBrightness";
    } else if (
      props.filterName === "Fade Out Brightness" ||
      props.filterName === "fadeOutBrightness"
    ) {
      stateFiltername = "fadeOutBrightness";
    } else if (
      props.filterName === "Fade In Volume" ||
      props.filterName === "fadeInVolume"
    ) {
      stateFiltername = "fadeInVolume";
    } else if (
      props.filterName === "Fade Out Volume" ||
      props.filterName === "fadeOutVolume"
    ) {
      stateFiltername = "fadeOutVolume";
    }
    setFilter(stateFiltername);
    const item = props.getItem(props.item);
    const allFilter = item.filters.map((el) => el.service);
    const val = allFilter.filter((el) => el.toString() === stateFiltername);
    if (stateFiltername === "fadeInBrightness") {
      const filterParameterName = Object.values(item.filters).filter(
        (el) => el.service === "fadeInBrightness"
      )[0];
      if (filterParameterName) {
        let level = Object.values(filterParameterName.options).filter(
          (el) => el.name === "level"
        )[0].value;
        let timeParameter2 = level.split(";")[1].split("=")[0];
        let temp = timeParameter2.split(",");
        const duration =
          parseInt(temp[0].split(":")[2]) * 1000 + parseInt(temp[1]);
        setFadeInFadeOutLevel(duration);
      } else {
        setFadeInFadeOutLevel(0);
      }
    } else if (stateFiltername === "brightness") {
      const filterParameterName = Object.values(item.filters).filter(
        (el) => el.service === "brightness"
      )[0];
      console.log(filterParameterName, "filterParameterName");
      if (filterParameterName) {
        let level = Object.values(filterParameterName.options).filter(
          (el) => el.name === "level"
        )[0].value;
        console.log(level, "level");
        let timeParameter = level * 100;

        setLevel(timeParameter);
      } else {
        setLevel(100);
      }
    } else if (stateFiltername === "fadeOutBrightness") {
      const filterParameterName = Object.values(item.filters).filter(
        (el) => el.service === "fadeOutBrightness"
      )[0];

      if (filterParameterName) {
        let level = Object.values(filterParameterName.options).filter(
          (el) => el.name === "level"
        )[0].value;
        let timeParameter = level.split("=")[0];
        let timeParameter2 = level.split(";")[1].split("=")[0];
        console.log(item, timeParameter, timeParameter2);
        timeParameter = timeManager.subDuration(timeParameter2, timeParameter);
        let temp = timeParameter.split(",");
        const duration =
          parseInt(temp[0].split(":")[2]) * 1000 + parseInt(temp[1]);
        setFadeInFadeOutLevel(duration);
      } else {
        setFadeInFadeOutLevel(0);
      }
    } else if (stateFiltername === "fadeInVolume") {
      if (val?.length > 0) {
        const filterParameterName = Object.values(item.filters).filter(
          (el) => el.service === "fadeInVolume"
        )[0];
        let startDurationInitial = Object.values(
          filterParameterName?.options
        ).filter((el) => el.name === "in")[0].value;
        let endDurationInitial = Object.values(
          filterParameterName?.options
        ).filter((el) => el.name === "out")[0].value;
        let initialSoundDb = Object.values(filterParameterName?.options).filter(
          (el) => el.name === "level"
        )[0].value;
        initialSoundDb = initialSoundDb.split("Db")[0];

        // it means fadeInVolume filter already exists and we need to show the existing value in the input fields
        setInitialValueStartDuration(startDurationInitial);
        setInitialValueEndDuration(endDurationInitial);
        setEndDuration(endDurationInitial);
        setInitialValueSoundDb(initialSoundDb);
        setSoundDbLevel(initialSoundDb);
        setStartDuration(startDurationInitial);
      }
    } else if (stateFiltername === "lift_gamma_gain") {
      if (val?.length > 0) {
        const filterParameterName = Object.values(item.filters).filter(
          (el) => el.service === "lift_gamma_gain"
        )[0];
        let backColor = Object.values(filterParameterName?.options).find(
          (el) => el.name === "color"
        )?.value;
        if (backColor) {
          props.sethexColor(backColor);
          props.setgradientColor(backColor);
        }
      }
    }

    if (val.length > 0) {
      setFilterAlreadyApplied(true);
    }
  }, []);

  const backgroundColorHandler = (e) => {
    props.sethexColor(e.value);
    setHexColor(e.value);
    let rgbVal = convert.keyword.rgb(e.value);
    if (!rgbVal) {
      // this means the color is not red but something like "#518419"
      rgbVal = convert.hex.rgb(e.value);
    }
    // to change color value to percent multiply by 0.39 for melt
    props.setgradientColor({
      r: rgbVal[0] * 0.39,
      g: rgbVal[1] * 0.39,
      b: rgbVal[2] * 0.39,
    });
  };

  const handleCloseColorGradientModal = () => {
    setShowColorGradientModal(false);
  };

  const startTimeHandler = (e) => {
    if (e._d) {
      const start = date.format(new Date(e._d.getTime()), "HH:mm:ss,SSS");
      setStartDuration(start);
    }
  };

  const endTimeHandler = (e) => {
    if (e._d) {
      const d = new Date(e._d.getTime());
      const end = date.format(d, "HH:mm:ss,SSS");
      setEndDuration(end);
    }
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCloseDialog = () => {
    setLevel(100);
    setFilter(filters.videoFilters[0].id);
    props.onClose();
  };

  const textColourButtonHandler = () => {
    setShowColorGradientModal(true);
  };

  const getFilter = (id) => {
    for (let filter of filters.videoFilters) {
      if (filter.id === id) {
        return filter;
      }
    }
    for (let filter of filters.audioFilters) {
      if (filter.id === id) {
        return filter;
      }
    }
    return null;
  };

  const handleAddFilter = async (event) => {
    event.preventDefault();
    props.setLoading(true);

    const item = props.getItem(props.item);
    let curFilter = getFilter(filter);
    // error handling for different filters starts here
    if (filter === "fadeOutVolume" || filter === "fadeInVolume") {
      if (
        !timeManager.isValidDurationWithZeroIncluded(startDuration) ||
        !timeManager.isValidDuration(endDuration)
      ) {
        props.setLoading(false);
        return toast.error(`${t("error.insertBothStartAndEndDuration")}`);
      } else if (
        timestampToSeconds(startDuration.toString()) >
        timestampToSeconds(item.out)
      ) {
        props.setLoading(false);
        return toast.error(`${t("error.startTimeExceedAssetTime")}`);
      } else if (
        timestampToSeconds(endDuration.toString()) >
        timestampToSeconds(item.out)
      ) {
        return toast.error(`${t("error.endTimeExceedAssetTime")}`);
      }
    } else if (filter === "lift_gamma_gain") {
      if (!props.hexColor) {
        props.setLoading(false);
        return toast.error(`${t("error.backgroundColour")}`);
      }
    }
    // error handling ends here
    if (filter === "fadeInVolume" || filter === "fadeOutVolume") {
      let htmlSoundChangeValue;
      if (soundDbLevel <= 20 && soundDbLevel > 10) {
        htmlSoundChangeValue = 0.8;
      } else if (soundDbLevel <= 10 && soundDbLevel > 0) {
        htmlSoundChangeValue = 0.6;
      } else if (soundDbLevel <= 0 && soundDbLevel > -10) {
        htmlSoundChangeValue = 0.4;
      } else if (soundDbLevel <= -10 && soundDbLevel > -20) {
        htmlSoundChangeValue = 0.2;
      } else if (soundDbLevel <= -20 && soundDbLevel > -30) {
        htmlSoundChangeValue = 0.2;
      } else if (soundDbLevel <= -30 && soundDbLevel > -40) {
        htmlSoundChangeValue = 0.1;
      } else if (soundDbLevel < -40) {
        htmlSoundChangeValue = 0;
      }

      let newFilter = {
        filter: filter,
        params: {
          in: startDuration,
          out: endDuration,
          level: `${soundDbLevel}Db`,
          htmlSoundChangeValue,
        },
      };
      newFilter.id = props.getItem(props.item).resource;
      newFilter.track = props.item.split(":")[0];
      newFilter.item = Number(props.item.split(":")[1]);

      if (filterAlreadyApplied) {
        await handleDelFilter(filter);
        setTimeout(async () => {
          await props.onAdd(newFilter, "Edit");
        }, 100);
      } else {
        await props.onAdd(newFilter);
      }
      props.setLoading(false);
      return handleCloseDialog();
    }
    // adding text to image/video   and sound ends here
    let newFilter = {
      filter,
      params: {},
    };
    const input = {};
    const itemPath = props.item.split(":");
    newFilter.track = itemPath[0];
    newFilter.item = Number(itemPath[1]);

    if (filter === "lift_gamma_gain" && props.gradientColor) {
      for (let output of curFilter.out) {
        input[curFilter.in[0].id] = level;
        newFilter.params[output.id] = output.value(props.gradientColor, item);
      }
      newFilter.params.color = props.hexColor;
    } else {
      for (let output of curFilter.out) {
        if (
          newFilter.filter !== "brightness" &&
          filter !== "fadeInVolume" &&
          filter !== "fadeOutVolume"
        ) {
          let dur = fadeInFadeOutLevel * 1;
          if (filter === "fadeInBrightness" || filter === "fadeOutBrightness") {
            const quotient = Math.floor(dur / 1000);
            const reminder = dur % 1000;
            dur = `00:00:0${quotient},${reminder.toString().padStart(3, "0")}`;
          }
          input[curFilter.in[0].id] = dur;
        } else {
          input[curFilter.in[0].id] = level;
        }
        newFilter.params[output.id] = output.value(input, item);
      }
    }

    if (filterAlreadyApplied) {
      await handleDelFilter(filter);
      await props.onAdd(newFilter, "Edit");
    } else {
      props.onAdd(newFilter);
    }
    props.setLoading(false);
    handleCloseDialog();
  };

  const soundDbChangeHandler = (el) => {
    setSoundDbLevel(el);
    setInitialValueSoundDb(null);
  };

  const handleDelFilter = async (filterId) => {
    const itemPath = props.item.split(":");
    const url = `${server.apiUrl}/project/${props.project}/filter`;
    const data = {
      track: itemPath[0],
      item: Number(itemPath[1]),
      filter: filterId,
    };

    try {
      const res = await axios.delete(url, { data });
      props.onDel(data, res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const globalBrandColorList = props.globalBrandColor?.map((el) => {
    return {
      label: el,
      value: el,
      color: el,
    };
  });

  return (
    <div>
      {filter === "lift_gamma_gain" && showColorGradientModal && (
        <ColorGrading
          gradientColor={props.gradientColor}
          setgradientColor={props.setgradientColor}
          showColorGradientModal={handleCloseColorGradientModal}
          closeHandleDialog={handleCloseDialog}
          hexColor={props.hexColor}
          sethexColor={props.sethexColor}
        />
      )}
      <Modal
        isOpen={true}
        contentLabel="Add New FIlter"
        className={"modal"}
        overlayClassName={"overlay"}
        onRequestClose={handleCloseDialog}
      >
        <h2
          style={{ textAlign: "center", padding: "20px", fontFamily: "Lato" }}
        >
          {props.openAddFilterDialogEdit || filterAlreadyApplied
            ? t("shared.editEffect")
            : t("shared.addEffect")}
          {currentFilterName}
        </h2>
        <hr />

        {filterAlreadyApplied && !props.openAddFilterDialogEdit && (
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "20px auto",
                    background: "#6c4343 none repeat scroll 80% 0%",
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  {t("addFilterDialog.currentlyEditing")}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {(filter === "fadeInBrightness" || filter === "fadeOutBrightness") && (
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "20px auto",
                    background: "#0a7aad none repeat scroll 80% 0%",
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  {t("addFilterDialog.maxDurationToBeApplied")}
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div>
          <div className="filter-form">
            {getFilter(filter).in[0].id === "level" && (
              <div className="filter-form__value">
                <label htmlFor={"level"}>
                  {t("shared.level")} <span>( {level} % )</span>{" "}
                </label>
                <input
                  type={"range"}
                  name={"level"}
                  min={0}
                  max={200}
                  value={level}
                  onChange={handleLevelChange}
                />
              </div>
            )}
            {getFilter(filter).in[0].id === "backgroundColour" && (
              <div className="filter-form__info">
                {props.globalBrandColor?.length > 0 ? (
                  <>
                    <Input
                      element="react-select"
                      onChange={backgroundColorHandler}
                      selectedList={globalBrandColorList}
                      removeMainClass={true}
                      stylesLabel={{
                        minWidth: "100%",
                      }}
                      label={`${t("timeline.colorOverlay")}`}
                      placeholder={`${t("timeline.colorOverlay")}`}
                      getOptionLabel={(option) => (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "60px",
                              height: "24px",
                              borderRadius: "4px",
                              border: "1px solid #777",
                              marginRight: "4px",
                              backgroundColor: `${option.color}`,
                            }}
                          >
                            &nbsp;
                          </div>
                          <span
                            style={{
                              color: `${
                                option.color === "default" ||
                                hexColor === option.color ||
                                props.hexColor === option.color
                                  ? "white"
                                  : "black"
                              }`,
                            }}
                          >
                            {option.label}
                          </span>
                        </div>
                      )}
                      defaultValue={{
                        label: props.hexColor
                          ? props.hexColor
                          : `${t("timeline.colorOverlay")}`,
                        value: props.hexColor ? props.hexColor : " ",
                        color: props.hexColor ? props.hexColor : `default`,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor={"level"}>
                      {t("addText.backgroundColour")}{" "}
                    </label>
                    <div className="filter-form__buttonInfo">
                      {props.hexColor
                        ? props.hexColor
                        : t("shared.noColourSelected")}
                      <button onClick={textColourButtonHandler}>
                        {props.hexColor
                          ? t("shared.change")
                          : t("shared.select")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {/* // volume change */}
            {(filter === "fadeInVolume" || filter === "fadeOutVolume") && (
              <>
                <div className="filter-form__info">
                  <label htmlFor={"filter"}>
                    {t("shared.sound")}
                    <span>
                      ({" "}
                      {initialValueSoundDb ? initialValueSoundDb : soundDbLevel}{" "}
                      Db )
                    </span>{" "}
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {" "}
                    <input
                      type={"range"}
                      name={"level"}
                      min={-60}
                      max={20}
                      value={soundDbLevel}
                      onChange={(e) => soundDbChangeHandler(e.target.value)}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{t("shared.low")}</div>
                      <div>{t("shared.normal")}</div>
                    </div>
                  </div>
                </div>

                <div className="filter-form__value">
                  <label htmlFor={"duration"}>
                    {t("shared.startDuration")}
                    <br />
                    <span style={{ fontSize: "10px" }}>
                      {t("shared.hour-minute-second-milli")}
                    </span>
                  </label>
                  <Datetime
                    input={true}
                    timeFormat="HH:mm:ss,SSS"
                    dateFormat={false}
                    className="datetime-picker"
                    timeConstraints={{
                      hours: { min: 0, max: 99 },
                      minutes: { min: 0, max: 59 },
                      seconds: { min: 0, max: 59 },
                      milliseconds: { min: 0, max: 999 },
                    }}
                    value=""
                    initialValue={
                      initialValueStartDuration && initialValueStartDuration
                    }
                    onChange={(e) => startTimeHandler(e)}
                  />
                </div>
                <div className="filter-form__value">
                  <label htmlFor={"duration"}>
                    {t("shared.endDuration")}
                    <br />
                    <span style={{ fontSize: "10px" }}>
                      {t("shared.hour-minute-second-milli")}
                    </span>
                  </label>
                  <Datetime
                    input={true}
                    timeFormat="HH:mm:ss,SSS"
                    dateFormat={false}
                    className="datetime-picker"
                    timeConstraints={{
                      hours: { min: 0, max: 99 },
                      minutes: { min: 0, max: 59 },
                      seconds: { min: 0, max: 59 },
                      milliseconds: { min: 0, max: 999 },
                    }}
                    value=""
                    initialValue={
                      initialValueEndDuration && initialValueEndDuration
                    }
                    onChange={(e) => endTimeHandler(e)}
                  />
                </div>
              </>
            )}
            {/* Fadein and fadeout */}
            {(filter === "fadeInBrightness" ||
              filter === "fadeOutBrightness") && (
              <>
                <div className="filter-form__info">
                  {" "}
                  <label htmlFor={"filter"} style={{ fontSize: "18px" }}>
                    {t("shared.duration")}
                    <br />
                    <span>
                      {fadeInFadeOutLevel / 1000} {t("shared.second")}
                    </span>
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {" "}
                    <input
                      type={"range"}
                      name={"level"}
                      min={0}
                      max={3000}
                      value={fadeInFadeOutLevel}
                      onChange={(e) => setFadeInFadeOutLevel(e.target.value)}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="filter-form__button">
              <button
                onClick={handleAddFilter}
                style={{ background: "#0a7aad" }}
              >
                {props.openAddFilterDialogEdit || filterAlreadyApplied
                  ? t("shared.editEffect")
                  : t("shared.addEffect")}{" "}
              </button>
              <button onClick={handleCloseDialog}>{t("shared.cancel")}</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddFilterDialog;
