import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faDesktop,
    faFastBackward,
    faFastForward,
    faFileExport,
    faFileImport,
    faFileUpload,
    faFileVideo,
    faFolder,
    faPause,
    faPlay,
    faTrash,
    faVideo,
    faVolumeMute,
    faVolumeUp,
    faSave,
    faSun,
    faEraser,
    faMoon,
    faCircleNotch,
    faEdit,
    faPalette,
    faFont,
    faObjectUngroup,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faFont,
    faObjectUngroup,

    faPalette,
    faTrash,
    faVideo,
    faFileImport,
    faDesktop,
    faFileUpload,
    faPlay,
    faPause,
    faVolumeMute,
    faVolumeUp,
    faFolder,
    faFileExport,
    faFileVideo,
    faFastForward,
    faFastBackward,
    faSave,
    faSun,
    faEraser,
    faMoon,
    faCircleNotch,
    faVolumeUp,
    faEdit
);
