import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { drawImageAspectVideoFrame, getVideoStartTime, splitTime } from "../../shared/utils";
import ColorGrading from "../ColorGrading";
import Modal from "../../shared/UI/Modal/Modal";
import { useMyContext } from "../../contexts/StateHolder";
import Input from "../../shared/UI/Input/Input";
import classes from "./ShapesModal.module.css";

let color, itemSelectedEditIndex;

export default function ImageModal(props) {
    const { t } = useTranslation();

    const canvasRef3 = useRef(null);
    const videoRef = useRef(null);
    const [hexColor1, sethexColor1] = useState("black");
    const [imagePosition, setImagePosition] = useState(null);
    const [showColorGradientModalBackground, setShowColorGradientModalBackground] = useState(false);

    const { globalBrandColor } = useMyContext();

    let videoCurrentTime;
    let ctx,
        canvasOffset,
        offsetX,
        offsetY,
        startX,
        startY,
        pi2,
        resizerRadius,
        draggingResizer,
        imageX = 10,
        imageY = 10,
        imageWidth,
        imageHeight,
        imageRight,
        draggingImage,
        rr,
        imageBottom,
        mouseX,
        itemSelectedEdit,
        mouseY;
    let canvas;

    useEffect(() => {
        if (props.showImageModal) {
            if (!canvasRef3) return;
        }

        // finding video item at current timeline pointer
        let currentseconds, vstarttime;
        const allItems = props.timelineRef.getItemsAtCurrentTime(props.time).find((el) => el.includes("videotrack0") || el.includes("blankvidtrack"));
        const seekedvideoitem = props.timelineRef.itemsData.get().find((el) => el.id == allItems);
        
        if (seekedvideoitem) {
            currentseconds = (props.time - seekedvideoitem.start) / 1000;

            // we need to find the current time of that particular video
            vstarttime = getVideoStartTime(seekedvideoitem);
            videoCurrentTime = vstarttime + currentseconds;

            if (videoRef.current) {
                videoRef.current.src = seekedvideoitem.url;
                videoRef.current.currentTime = videoCurrentTime;
                videoRef.current.load();
            }
            console.log("videoRef.current", videoRef.current);
        } else {
            videoRef.current.src = "blankVideo/blank.mp4#t=1";
            videoRef.current.currentTime = 1;
            videoRef.current.load();
        }

        // need to check if user wants to edit shapes
        let images = props.timeline.video[2];
        if (props.editShapeModal) {
            const itemSplit = props.selectedItems[0].split(":");
            const itemSelectedIndex = itemSplit[itemSplit.length - 1];
            itemSelectedEditIndex = itemSelectedIndex * 1;
            console.log(itemSelectedEditIndex);
            itemSelectedEdit = images.items[itemSelectedIndex];

            let imageCoordinates = Object.values(Object.values(itemSelectedEdit.filters).find((el) => el.service === "mask_start").options);
            console.log("imageCoordinates", imageCoordinates);
            props.setImageDuration(itemSelectedEdit.out);
            props.setShapeDuration(itemSelectedEdit.out);

            imageX = Object.values(imageCoordinates).find((el) => el.name === "filter.imageX").value * 1;
            imageY = Object.values(imageCoordinates).find((el) => el.name === "filter.imageY").value * 1;
            imageHeight = Object.values(imageCoordinates).find((el) => el.name === "filter.imageHeight").value * 1;
            imageWidth = Object.values(imageCoordinates).find((el) => el.name === "filter.imageWidth").value * 1;
            color = Object.values(imageCoordinates).find((el) => el.name === "filter.color").value;
            sethexColor1(color);
        }
        canvas = canvasRef3.current;

        ctx = canvas.getContext("2d");

        canvasOffset = getOffset(canvas);
        offsetX = canvasOffset.left;
        offsetY = canvasOffset.top;

        startX;
        startY;
        pi2 = Math.PI * 2;
        resizerRadius = 8;
        rr = resizerRadius * resizerRadius;
        draggingResizer = {
            x: 0,
            y: 0,
        };
        if (props.editShapeModal) {
            imageX = imageX * 1;
            imageY = imageY * 1;
            imageWidth = imageWidth * 1;
            imageHeight = imageHeight * 1;
        } else {
            imageX = 20;
            imageY = 10;
            imageWidth = 200;
            imageHeight = 200;
        }

        draggingImage = false;
        imageRight = imageX * 1 + imageWidth * 1;
        imageBottom = imageY * 1 + imageHeight * 1;
        draw(true, false);

        canvas?.addEventListener("mousedown", (e) => {
            handleMouseDown(e);
        });
        canvas?.addEventListener("mousemove", (e) => {
            handleMouseMove(e);
        });
        canvas?.addEventListener("mouseup", (e) => {
            handleMouseUp(e);
        });
        canvas?.addEventListener("mouseout", (e) => {
            handleMouseOut(e);
        });
        return () => {
            sethexColor1("black");
            color = "";
            props.setImageDuration("");
        };
    }, [canvasRef3, props.showImageModal]);

    const getOffset = (element) => {
        if (!element.getClientRects().length) {
            return { top: 0, left: 0 };
        }

        let rect = element.getBoundingClientRect();
        let win = element.ownerDocument.defaultView;
        return {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset,
        };
    };
    
    const draw = (withAnchors, withBorders) => {
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawImageAspectVideoFrame(ctx, videoRef.current);
        setImagePosition({ imageX, imageY, imageWidth, imageHeight });

        ctx.fillStyle = color ? color : "black";
        ctx.fillRect(imageX, imageY, imageWidth, imageHeight);

        // for circle
        // ctx.beginPath();
        // ctx.arc(imageX, imageY, imageWidth / 2, 0, imageHeight, false);
        // ctx.fillStyle = hexColor1 ? hexColor1 : 'black';
        // ctx.fill();

        // eend of circle
        // optionally draw the draggable anchors
        if (withAnchors) {
            drawDragAnchor(imageX, imageY);
            drawDragAnchor(imageRight, imageY);
            drawDragAnchor(imageRight, imageBottom);
            drawDragAnchor(imageX, imageBottom);
        }

        // optionally draw the connecting anchor lines
        if (withBorders) {
            ctx.beginPath();
            ctx.moveTo(imageX, imageY);
            ctx.lineTo(imageRight, imageY);
            ctx.lineTo(imageRight, imageBottom);
            ctx.lineTo(imageX, imageBottom);
            ctx.closePath();
            ctx.stroke();
        }
    };

    const drawDragAnchor = (x, y) => {
        ctx.beginPath();
        ctx.arc(x, y, resizerRadius, 0, pi2, false);
        ctx.closePath();
        ctx.fill();
    };

    const anchorHitTest = (x, y) => {
        var dx, dy;

        // top-left
        dx = x - imageX;
        dy = y - imageY;
        if (dx * dx + dy * dy <= rr) {
            return 0;
        }
        // top-right
        dx = x - imageRight;
        dy = y - imageY;
        if (dx * dx + dy * dy <= rr) {
            return 1;
        }
        // bottom-right
        dx = x - imageRight;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= rr) {
            return 2;
        }
        // bottom-left
        dx = x - imageX;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= rr) {
            return 3;
        }
        return -1;
    };

    const hitImage = (x, y) => {
        return x > imageX && x < imageX + imageWidth && y > imageY && y < imageY + imageHeight;
    };

    const handleMouseDown = (e) => {
        startX = parseInt(e.clientX - offsetX);
        startY = parseInt(e.clientY - offsetY);
        draggingResizer = anchorHitTest(startX, startY);
        draggingImage = draggingResizer < 0 && hitImage(startX, startY);
    };

    const handleMouseUp = (e) => {
        draggingResizer = -1;
        draggingImage = false;
        draw(true, false);
    };

    const handleMouseOut = (e) => {
        handleMouseUp(e);
    };

    const handleMouseMove = (e) => {
        offsetX = offsetX * 1;
        offsetY = offsetY * 1;
        if (draggingResizer > -1) {
            mouseX = parseInt(e.clientX * 1 - offsetX);
            mouseY = parseInt(e.clientY * 1 - offsetY);
            // resize the image
            switch (draggingResizer) {
                case 0:
                    //top-left
                    imageX = mouseX;
                    imageWidth = imageRight - mouseX;
                    imageY = mouseY;
                    imageHeight = imageBottom - mouseY;
                    canvas.style.cursor = "ns-resize";

                    break;
                case 1:
                    //top-right
                    imageY = mouseY;
                    imageWidth = mouseX - imageX;
                    imageHeight = imageBottom - mouseY;
                    canvas.style.cursor = "ns-resize";

                    break;
                case 2:
                    //bottom-right
                    imageWidth = mouseX - imageX;
                    imageHeight = mouseY - imageY;
                    canvas.style.cursor = "ew-resize";

                    break;
                case 3:
                    //bottom-left
                    imageX = mouseX;
                    imageWidth = imageRight - mouseX;
                    imageHeight = mouseY - imageY;
                    canvas.style.cursor = "nesw-resize";

                    break;
            }
            if (imageWidth < 25) {
                imageWidth = 25;
            }
            if (imageHeight < 25) {
                imageHeight = 25;
            }

            // set the image right and bottom
            imageRight = imageX + imageWidth;
            imageBottom = imageY + imageHeight;
            canvas.style.cursor = "ew-resize";
            // redraw the image with resizing anchors
            draw(true, true);
        } else if (draggingImage) {
            mouseX = parseInt(e.clientX - offsetX);
            mouseY = parseInt(e.clientY - offsetY);
            // move the image by the amount of the latest drag
            var dx = mouseX - startX;
            var dy = mouseY - startY;
            imageX += dx;
            imageY += dy;
            imageRight += dx;
            imageBottom += dy;
            // reset the startXY for next time
            startX = mouseX;
            startY = mouseY;
            // redraw the image with border
            canvas.style.cursor = "auto";
            draw(false, true);
        }
    };
    const backgroundColourButtonHandler = () => {
        color = hexColor1;
        textColourButtonHandler();
    };
    const textColourButtonHandler = () => {
        setShowColorGradientModalBackground(!showColorGradientModalBackground);
    };

    const insertShapeHandler = () => {
        // imagePosition, color, props

        let duration = props.shapeDuration;
        if (!duration || (duration && splitTime(duration) <= 0)) {
            return toast.error(`${t("error.invalidShapeDuration")} ...`);
        }

        props.insertShapeHandler(
            imagePosition,
            hexColor1 ? hexColor1 : "black",
            duration,
            itemSelectedEditIndex,

            props.editShapeModal
        );
    };
    const closeShapeModal = () => {
        sethexColor1("black");
        color = "";
        props.shapeHanlderCancel();
    };
    const globalBrandColorList = globalBrandColor?.map((el) => {
        return {
            label: el,
            value: el,
            color: el,
        };
    });

    return (
        <>
            <Modal show={true}>
                {showColorGradientModalBackground && <ColorGrading sethexColor1={sethexColor1} closeHandleDialog={backgroundColourButtonHandler} />}
                <h2 style={{ textAlign: "center", paddingBottom: "20px" }}>{t("shapeModal.shapePositionAndDuration")}</h2>
                <div className={classes.canvasContainer}>
                    <canvas id="canvas100" ref={canvasRef3} width="640" height="360" style={{ background: "white" }}></canvas>
                    <video controls={false} preload="metadata" ref={videoRef} style={{ display: "none" }}></video>
                </div>
                {
                    <div className={classes.container}>
                        {" "}
                        {/* // if no brand color is defined use color picker instead */}
                        {globalBrandColor?.length > 0 ? (
                            <Input
                                element="react-select"
                                onChange={(el) => {
                                    sethexColor1(el?.value);
                                    color = el?.value;
                                }}
                                selectedList={globalBrandColorList}
                                removeMainClass={true}
                                label={`${t("shapeModal.selectColour")}`}
                                placeholder={`${t("shapeModal.selectColour")}`}
                                getOptionLabel={(option) => (
                                    <div style={{ display: 'flex'}}>
                                        <div style={{width: '60px', height: '24px', borderRadius: '4px', border: '1px solid #777', marginRight: '4px', backgroundColor: `${option.color}`}}>&nbsp;</div>
                                        <span style={{ color: `${ hexColor1 === option.color ? 'white': 'black'}`}}>{option.label}</span>
                                    </div>
                                )}
                                defaultValue={{
                                    label: hexColor1 ? hexColor1 : `${t("shapeModal.selectColour")}`,

                                    value: hexColor1 ? hexColor1 : "",
                                    color: hexColor1 ? hexColor1 : 'white',
                                }}
                            />
                        ) : (
                            <div className="filter-form__value">
                                <label htmlFor={"duration"}>{t("shapeModal.selectColour")}</label>
                                <div className="textColour textColour_shape_modal">
                                    <div className="textColour-input"></div>
                                    <div className="textColour-button textColour_shape_modal_button ">
                                        {" "}
                                        <p>{hexColor1}</p>
                                        <button onClick={textColourButtonHandler}>{`${t("addText.select")}`}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Input
                            element="datetime"
                            input={true}
                            timeFormat="HH:mm:ss,SSS"
                            dateFormat={false}
                            removeMainClass={true}
                            className="datetime-picker"
                            timeConstraints={{
                                hours: { min: 0, max: 99 },
                                minutes: { min: 0, max: 59 },
                                seconds: { min: 0, max: 59 },
                                milliseconds: { min: 0, max: 999 },
                            }}
                            value={props.imageDuration}
                            onChange={(e) => props.imageInputHandler(e)}
                            labelSpan={
                                <span style={{ fontSize: "10px" }}>
                                    <br />

                                    {`${t("addText.hour-minute-second-milli")}`}
                                </span>
                            }
                            label={` ${t("shared.duration")}`}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            <button onClick={insertShapeHandler} style={{ padding: "10px 30px", background: "#0a7aad" }}>
                                {props.editShapeModal ? t("shared.edit") : t("shared.insert")}
                            </button>
                            <button onClick={closeShapeModal} style={{ padding: "10px 30px" }}>
                                {t("shared.cancel")}
                            </button>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}
