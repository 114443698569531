import React from "react";

export default function BrandFontItem(props) {
  return (
    <>
      {props.item && (
        <tr
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <td>
            <div>
              <i className="material-icons resource-preview" aria-hidden="true">
                font_download
              </i>
            </div>
          </td>
          <td style={{ flex: 2 }}>
            {props.item}
            <br />
          </td>
          <td className="column-right">
            <>
              <button
                onClick={() => props.onRemove(props.item)}
                className="sources-delete"
              >
                <i className="material-icons" aria-hidden="true">
                  delete
                </i>
              </button>
            </>
          </td>
        </tr>
      )}
    </>
  );
}
