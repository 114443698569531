import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { Icon } from "@mdi/react";
import moment from "moment";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { mdiFileAccount, mdiDelete, mdiFolderLockOpen, mdiLoading } from "@mdi/js";
import { useTranslation } from "react-i18next";

import FetchErrorDialog from "../editor/FetchErrorDialog";
import { server } from "../../config";
import { useMyContext } from "../contexts/StateHolder";
import SaveModal from "../editor/Modal/SaveModal";
import LoadingDialog1 from "../editor/LoadingDialog1";

Modal.setAppElement(document.body);
function useQuery() {
    return new URLSearchParams(document.location.search.toString());
}

export default function NewProjectDialog(props) {
    const { t, i18n } = useTranslation();

    const { setprojectUrl } = useMyContext();

    let history = useHistory();
    let query = useQuery();

    const [showFetchError, setshowFetchError] = useState(false);
    const [fetchError, setfetchError] = useState("");
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [allProjectResults, setAllProjectResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["userToken"]);

    useEffect(() => {
        setLoading(true);

        const fetchToken = async () => {
            try {
                let languageId = query.get("lang");

                let token = window.location.search.split("token=")[1];

                if (languageId) {
                    if (token.includes("&lang")) {
                        token = window.location.search.split("token=")[1].split("&lang")[0];
                    }
                }

                if (!token) {
                    //read the token from cookie if theere is no token send an errorr
                    if (cookies.userToken) {
                        token = cookies.userToken;
                        token = decodeURIComponent(token);
                        console.log(token);
                    } else {
                        setshowFetchError(true);
                        setfetchError([`${t("error.openApplicationFromSuiteAgain")}`, `${t("error.unauthorizedAccess")}`]);
                        setLoading(false);
                        return;
                    }
                }

                if (languageId) {
                    setCookie("lang", languageId, {
                        options: { path: "/" },
                    });
                } else {
                    setCookie("lang", "en_US", {
                        options: { path: "/" },
                    });
                    languageId = cookies.lang;
                }
                console.log("languageIdLast", languageId);
                if (languageId?.includes("fi")) {
                    console.log("languageIdLast", languageId);

                    i18n.changeLanguage("fi_FI");
                } else {
                    i18n.changeLanguage("en_US");
                }
                token = encodeURIComponent(token);

                console.log(token, "encodetoken");
                const url = `${server.apiUrl}/token`;
                const res = await axios.get(url, {
                    params: {
                        token: token,
                    },
                });

                console.log(res);
                props.setTokenExpiryTime(res.data.expiry);
                setCookie("userToken", res.data.tokenUser, {
                    options: { path: "/" },
                    expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
                });
                if (res.data.access) {
                    // user acceess level
                    let accessLevel = res.data.access.split(" ")[1];
                    if (accessLevel)
                        setCookie("ual", accessLevel === "Administrator" ? 1 : 0, {
                            options: { path: "/" },
                            expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
                        });
                }
            } catch (error) {
                console.log(error);
                setshowFetchError(true);
                setfetchError([`${t("error.openApplicationFromSuiteAgain")}`, `${t("error.unauthorizedAccess")}`]);
                setLoading(false);
            }
        };
        !props.tokenExpiryTime && fetchToken();
    }, [props.tokenExpiryTime]);

    useEffect(() => {
        if (props.tokenExpiryTime) {
            const fetchALLProject = async () => {
                try {
                    const res = await axios.get(`${server.apiUrl}/organizationID`);
                    setAllProjectResults(res.data.results);
                    setLoading(false);
                } catch (error) {
                    setshowFetchError(true);
                    setfetchError([`${t("error.openApplicationFromSuiteAgain")}`, `${t("error.unauthorizedAccess")}`]);
                    setLoading(false);
                }
            };
            fetchALLProject();
        }
    }, [props.tokenExpiryTime]);
    const openprojectButtonHandler = (el) => {
        history.push(`/project/${el.fileID}`);
        <Redirect to={`/project/${el.fileID}`} />;
    };

    const createProjectName = async () => {
        setShowCreateProjectModal(!showCreateProjectModal);
    };

    const deleteprojectButtonHandler = async (el) => {
        try {
            let f = [...allProjectResults];

            let toDeleteItem = f.find((el2) => el2.id == el.id);
            const index = f.indexOf(toDeleteItem);
            toDeleteItem.loadingDelete = true;
            f[index] = toDeleteItem;
            setAllProjectResults(f);
            const url = `${server.apiUrl}/delete/${el.fileID}/${el.id}`;
            await axios.get(url);

            const updatedProjectList = allProjectResults.filter((el1) => el1.id !== el.id);
            setTimeout(() => {
                setAllProjectResults(updatedProjectList);
                toast.info(`${el.name} deleted successfully`);
            }, 10);
        } catch (error) {
            setfetchError("Server Error . Please try Later");
        }
    };

    const createProject = async () => {
        try {
            const url = `${server.apiUrl}/project`;

            const data = {
                name: projectName,
            };
            const res = await axios.post(url, data);

            await setprojectUrl(`${res.data.project}`);

            history.push(`/project/${res.data.project}`);
            <Redirect to={`/project/${res.data.project}`} />;
        } catch (error) {
            openFetchErrorDialog(error);
        }
    };
    const cancelButtonHandler = () => {
        setShowCreateProjectModal(!showCreateProjectModal);
    };

    const closeFetchErrorDialog = () => {
        setshowFetchError(false);
        setfetchError("");
    };
    const openFetchErrorDialog = (msg) => {
        setshowFetchError(true);
        setfetchError(msg);
    };

    return (
        <>
            {props.showTokenModal && (
                <SaveModal
                    showOnlyTextMessage={true}
                    confirmIcon={mdiFileAccount}
                    confirmHandler={props.extendSession}
                    cancelHandler={props.continueSession}
                    textMessage={t("newProjectDialog.extendSessionMessage")}
                    inputLabel={t("newProjectDialog.sessionTimeout")}
                    confirmButtonText={t("newProjectDialog.extendSession")}
                />
            )}
            {loading && <LoadingDialog1 />}
            {/* <ToastContainer /> */}
            {showFetchError && <FetchErrorDialog msg={fetchError} onClose={closeFetchErrorDialog} hideButton />}
            {showCreateProjectModal && !showFetchError && (
                <SaveModal
                    inputValue={projectName}
                    setInputValue={setProjectName}
                    inputLabel={t("newProjectDialog.projectName")}
                    confirmHandler={createProject}
                    cancelHandler={() => cancelButtonHandler()}
                />
            )}

            {!showFetchError && (
                <>
                    <div className="newProjectDialog">
                        <div className="title">
                            <img src="./icons/img.png" alt="fff" />
                            <hr />
                            <div className="button">
                                {" "}
                                <button className="button-title" onClick={() => createProjectName()}>
                                    <Icon path={mdiFileAccount} title="User Profile" size={1} color="white" style={{ marginLeft: "10px" }} />
                                    {t("newProjectDialog.createProject")}
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "70%",
                                margin: "0 auto",
                                padding: "10px 0",
                                display: "flex",
                                gap: "20px",
                                flexDirection: "column",
                            }}
                        >
                            <h2>{t("newProjectDialog.allProjects")}</h2>

                            <div className="projects-info">
                                <div className="projects-details">
                                    <div className="projects-info__name">#</div>
                                    <div className="projects-info__name">{t("newProjectDialog.name")}</div>
                                    <div className="project-info__created">{t("newProjectDialog.created")}</div>
                                    <div className="project-info__action">{t("newProjectDialog.actions")}</div>
                                </div>
                                {allProjectResults && allProjectResults.length === 0 && (
                                    <h2 className="project-notFound">{t("newProjectDialog.noProjectFound")}</h2>
                                )}

                                {allProjectResults &&
                                    allProjectResults.length > 0 &&
                                    allProjectResults.map((el, i) => (
                                        <div key={el.id}>
                                            <div className="projects-detail" key={el.id}>
                                                <div className="projects-info__name">{i + 1}</div>
                                                <div className="projects-info__name">{el.name}</div>
                                                <div className="project-info__created">{moment(el.CreatedAt).format("MMMM Do YYYY, h:mm ")}</div>
                                                <div className="project-info__action">
                                                    <button className="project-info__openbutton" onClick={() => openprojectButtonHandler(el)}>
                                                        <Icon path={mdiFolderLockOpen} title="User Profile" size={1} color="white" />
                                                        {t("newProjectDialog.open")}
                                                    </button>
                                                    {!el?.loadingDelete ? (
                                                        <button className="project-info__deletebutton" onClick={() => deleteprojectButtonHandler(el)}>
                                                            <Icon path={mdiDelete} title="User Profile" size={1} color="white" /> {t("newProjectDialog.delete")}
                                                        </button>
                                                    ) : (
                                                        <button className="project-info__deletebutton" onClick={() => deleteprojectButtonHandler(el)} disabled>
                                                            <Icon path={mdiLoading} title="User Profile" size={1} color="white" spin />{" "}
                                                            {t("newProjectDialog.delete")}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
