import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EffectsModal({
    fadeInBrightnessHandler,
    fadeOutBrightnessHandler,
    brightnessHandler,
    backgroundColorHanndler,
    setShowAllEffectsModal,
    item,
    getItem,
}) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [listOfEffects, setlistOfEffects] = useState([
        {
            id: 1,
            icon: "sun",
            name: t("timeline.brightness"),
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/DiptoWhite.png",
            filterName: "Brightness",
            mltFilterName: "brightness",
            onClick: () => brightnessHandler(),
            editingMode: false,
        },
        {
            id: 2,
            icon: "eraser",
            name: t("timeline.colorOverlay"),
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/Burn.png",
            filterName: "Background Colour",
            onClick: () => backgroundColorHanndler(),
            editingMode: false,
            mltFilterName: "lift_gamma_gain",
        },
        {
            id: 3,
            icon: "moon",
            name: t("timeline.fadeIn"),
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/Fade.png",
            filterName: "Fade In Brightness",
            onClick: () => fadeInBrightnessHandler(),
            editingMode: false,
            mltFilterName: "fadeInBrightness",
        },
        {
            id: 4,
            icon: "circle-notch",
            name: t("timeline.fadeOut"),
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/DiptoBlack.png",
            filterName: "Fade Out Brightness",
            onClick: () => fadeOutBrightnessHandler(),
            editingMode: false,
            mltFilterName: "fadeOutBrightness",
        },
    ]);

    useEffect(() => {
        if (item) {
            const selectedItem = getItem(item);
            const allFilter = selectedItem.filters.map((el) => el.service);
            console.log("allFiler", allFilter);
            if (allFilter?.length > 0) {
                let r = [...listOfEffects];
                r.forEach((el) => {
                    console.log(allFilter.includes(el?.mltFilterName));
                    if (allFilter.includes(el?.mltFilterName)) {
                        el.editingMode = true;
                    }
                });
                setlistOfEffects(r);
            }
            setLoading(false);
        }
    }, [item]);
    
    return (
        <>
            <Modal isOpen={true} contentLabel="Select Any One" className={"modalTransition"} overlayClassName={"overlayAsset"}>
                <h2 style={{ textAlign: "center", paddingBottom: "20px" }}> Video Effects</h2>
                {!loading && (
                    <>
                        <div className="wrapperPrimary">
                            {listOfEffects.map((el) => (
                                <div className="wrapper" key={el?.id.toString()}>
                                    <div className="product-img">
                                        <img src={el?.image} />
                                    </div>
                                    <div className="product-info">
                                        <div className="product-text" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h3>{el.name}</h3>
                                        </div>
                                        <button
                                            type="button"
                                            style={{
                                                padding: "10px 30px",
                                                background: el?.editingMode ? "#147aa9" : "",
                                                minWidth: "150px",
                                                marginTop: "20px !important",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            onClick={() => {
                                                el?.onClick();
                                                setShowAllEffectsModal(false);
                                            }}
                                        >
                                            <FontAwesomeIcon color="white" style={{ marginRight: "5px", width: "14px", height: "14px" }} icon={el.icon} />
                                            <div> {el?.editingMode ? "Edit" : "Select"}</div>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ padding: "0", paddingBottom: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button
                                onClick={() => {
                                    setShowAllEffectsModal(false);
                                }}
                                style={{
                                    padding: "10px 30px",
                                    minWidth: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "rgb(108, 67, 67)",
                                }}
                            >
                                {`${t("shared.cancel")}`}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
}
