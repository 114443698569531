import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { Icon } from "@mdi/react";
import { mdiCancel, mdiFloppy } from "@mdi/js";

export default function SaveModal({
    inputValue,
    setInputValue,
    inputLabel,
    inputPlaceHolder,
    confirmHandler,
    cancelHandler,
    showOnlyTextMessage,
    confirmIcon,
    textMessage,
    confirmButtonText,
    cancelButtonText,
    showAdditonalInput,
    setInputValue1,
    inputPlaceHolder1,
    inputLabel1,
    inputValue1,
}) {
    const { t } = useTranslation();

    return (
        <Modal isOpen={true} contentLabel="New Project" className={"modalTitle"} overlayClassName={"overlay"}>
            <div className="title-inputPrimary">
                <div className="title-input">
                    <div className="title-input__label">{inputLabel}</div>
                    <div className="title-input__input">
                        {showOnlyTextMessage ? (
                            <>
                                <div className="title-input__input" style={{ textAlign: "center" }}>
                                    <p>{textMessage}</p>
                                </div>
                            </>
                        ) : (
                            <input
                                onChange={(e) => setInputValue(e.target.value)}
                                value={inputValue}
                                placeholder={inputPlaceHolder ? inputPlaceHolder : inputLabel}
                            ></input>
                        )}
                        {showAdditonalInput && (
                            <input
                                style={{ marginTop: "20px" }}
                                onChange={(e) => setInputValue1(e.target.value)}
                                value={inputValue1}
                                placeholder={inputPlaceHolder1 ? inputPlaceHolder1 : inputLabel1}
                            ></input>
                        )}
                    </div>
                </div>

                <div className="button">
                    {" "}
                    <button
                        className="button-title"
                        onClick={() => confirmHandler()}
                        disabled={!showOnlyTextMessage && (!inputValue || inputValue.trim() === "")}
                        style={{ background: "#0A7AAD" }}
                    >
                        {" "}
                        <Icon path={confirmIcon ? confirmIcon : mdiFloppy} title="User Profile" size={1} color="white" style={{ marginLeft: "10px" }} />
                        {confirmButtonText ? confirmButtonText : t("shared.save")}
                    </button>
                    <button
                        style={{
                            textAlign: "center",
                            marginTop: "20px",
                        }}
                        onClick={cancelHandler}
                    >
                        <Icon path={mdiCancel} title="User Profile" size={1} color="white" style={{ marginLeft: "10px" }} />
                        {cancelButtonText ? cancelButtonText : t("shared.cancel")}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
