import { mdiClose } from "@mdi/js";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import Icon from "@mdi/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { server } from "../../config";
import LoadingDialog from "./LoadingDialog";
import LoadingDialog1 from "./LoadingDialog1";
import { TailSpin } from "react-loader-spinner";
import classes from "./AssetsFromSuite.module.css";
import { useCallback } from "react";

Modal.setAppElement(document.body);

export default function AssetsFromSuite(props) {
  const { buttonLabel='import', buttonAction }=props;
  const { t } = useTranslation();

  const [dropDownOpen, setdropDownOpen] = useState(false);

  const [dropdownInputValue, setdropdownInputValue] = useState("Choose Folder");
  const [searchInput, setsearchInput] = useState("");
  const [allCategory, setallCategory] = useState(null);
  const [categoriesId, setCategoriesId] = useState(null);
  const [loading, setloading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [suiteAllAssets, setSuiteAllAssets] = useState([]);
  const [suiteAllUnfilteredAssets, setSuiteAllUnfilteredAssets] = useState(null);
  const [totalNumberOfAssets, settotalNumberOfAssets] = useState();
  const [initialTotalNumberOfAssets, setInitialTotalNumberOfAssets] = useState();
  const [pageStart, setPageStart] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  // we have to store the asset fro search to here so we dont loose the original state of all our assets
  const [searchedProjectAsset, setsearchedProjectAsset] = useState(null);
  const [latestFirstClassname, setlatestFirstClassname] = useState("active");
  const [characterFirstClassname, setcharacterFirstClassname] = useState("");
  
  const handleCloseDialog = () => {
    props.onAssetModalHandler();
  };

  const addClassNameHandler = () => {
    setdropDownOpen((el) => !el);
  };

  const clickHandler = async (el) => {
    setdropdownInputValue(el.title);
    setsearchInput("");
    setCategoriesId(el?.id);
    // we dont want to mege the data so paasin searchHandler as true
    fetchData(0, false, true, false, el?.id);
  };

  const searchAssetHandler = async () => {
    setdropdownInputValue("Choose Folder");
    setCategoriesId(null);
    // we will not search based on any categorieId
    setPageStart(0);
    await fetchData(0, false, true);
    setlatestFirstClassname("active");
    setcharacterFirstClassname("");
    setloading(false);
  };

  const clearButtonHandler = () => {
    setPageStart(0);
    settotalNumberOfAssets(initialTotalNumberOfAssets);
    setdropdownInputValue("Choose Folder");
    setCategoriesId(null);
    setsearchInput("");
    setSuiteAllAssets(suiteAllUnfilteredAssets);
  };

  const latestFirstHandler = () => {
    const latestFirst = searchedProjectAsset
      ? searchedProjectAsset.sort((a, b) => b.date - a.date)
      : suiteAllAssets.sort((a, b) => b.date - a.date);
    searchedProjectAsset
      ? setsearchedProjectAsset(latestFirst)
      : setSuiteAllAssets(latestFirst);
    setlatestFirstClassname("active");
    setcharacterFirstClassname("");
  };

  const characterFirstHandler = () => {
    const characterFirst = searchedProjectAsset
      ? searchedProjectAsset.sort((a, b) => a.name.localeCompare(b.name))
      : suiteAllAssets.sort((a, b) => a.name.localeCompare(b.name));

    searchedProjectAsset
      ? setsearchedProjectAsset(characterFirst)
      : setSuiteAllAssets(characterFirst);
    setlatestFirstClassname("");
    setcharacterFirstClassname("active");
  };

  const downloadAssetHandler = async (id, name, duration) => {
    try {
      setImporting(true);
      const url = `${server.apiUrl}/project/${props.project}/masterUrl`;
      const res = await axios.get(url, {
        params: {
          assetId: id,
          name: name,
          duration,
        },
      });

      if (res?.data?.msg === "no master url") {
        setImporting(false);
        return toast.error(`Asset does not have valid mp4 file`);
      }

      toast.info(`${t("assetsFromSuite.assetImportedSuccessfully")}`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
      });

      setImporting(false);
      props.loadData();
    } catch (error) {
      setImporting(false);
      toast.error(`${t("error.serverError")}`);
    }
  };

  const fetchMoreData = () => {
    // this will not show loading icon when addding val at end
    fetchData(pageStart, true, searchInput, false, categoriesId);
  };

  const fetchData = useCallback(
    async (page, dontreload, searchHandler, initialRender, catId) => {
      if (!dontreload) {
        setloading(true);
      }
      const url = `${server.apiUrl}/project/${props.projectUrl}/importSuite`;
      const data = {
        step: page,
        keyword: searchInput,
        findAllGroupItemIds: initialRender ? true : false,
        categoriesId: catId,
      };
      const res = await axios.post(url, data);
      if (res?.data?.allAsset?.status === "ok") {
        settotalNumberOfAssets(res?.data?.allAsset?.total);
        if (initialRender) {
          setSuiteAllUnfilteredAssets(res?.data?.allAsset?.assets);
          setInitialTotalNumberOfAssets(res?.data?.allAsset?.total);
        }

        if (page === 0 && searchHandler) {
          // we dont merge the data when user type something in search handler
          setSuiteAllAssets(res?.data?.allAsset?.assets);
        } else {
          setSuiteAllAssets((prev) => [
            ...prev,
            ...res?.data?.allAsset?.assets,
          ]);
        }

        setPageStart(page + 20);

        // let ASSETS_LIMIT = 20;
        if (res?.data?.allAsset?.total > pageStart) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        if (res?.data.category) {
          setallCategory(res?.data.category);
        }
      }
      if (!dontreload) {
        setloading(false);
      }
    },
    [searchInput, pageStart, categoriesId]
  );

  useEffect(() => {
    fetchData(0, false, searchInput, true);
  }, []);

  return (
    <Modal
      isOpen={true}
      className={"modalAsset"}
      overlayClassName={classes.overlayAsset1}
      onRequestClose={handleCloseDialog}
      preventScroll={false}
    >
      {importing && (
        <LoadingDialog heading={`${t("assetsFromSuite.importingAsset")}`} />
      )}
      {loading ? (
        <LoadingDialog1 />
      ) : (
        <div>
          <button onClick={handleCloseDialog} className="sort-cancel">
            <Icon
              path={mdiClose}
              title="Cancel"
              size={1.5}
              color="white"
              style={{ marginRight: "10px" }}
            />{" "}
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: "50px",
              padding: "20px",
              width: "100%",
            }}
          >
            <div className="wrapper-demo">
              <div
                id="dd"
                className={`wrapper-dropdown-2 ${dropDownOpen ? "active" : ""}`}
                tabIndex="1"
                onClick={addClassNameHandler}
              >
                {dropdownInputValue}
                <span>
                  <img src="/icons/menu.png" />
                </span>
                <ul className="dropdown">
                  {allCategory?.map((el) => (
                    <li key={el.id} onClick={() => clickHandler(el)}>
                      <a href="#">{el.title}</a>{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="wrapper-demo input">
              <input
                type="text"
                className="searchTerm"
                placeholder={`${t(
                  "assetsFromSuite.searchAssetFromSuitePlaceholder"
                )}`}
                value={searchInput}
                onChange={(e) => setsearchInput(e.target.value)}
              />
            </div>

            <button
              onClick={searchAssetHandler}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",

                padding: "10px 40px",

                background: "#147aa9",
                minHeight: "50px",
                maxHeight: "50px",
                borderRadius: "10px",
              }}
            >
              {t("shared.search")}
            </button>
            {(searchInput || categoriesId) && (
              <button
                onClick={clearButtonHandler}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",

                  padding: "10px 40px",

                  minHeight: "50px",
                  maxHeight: "50px",
                  borderRadius: "10px",
                  background: "rgb(108, 67, 67)",
                }}
              >
                {/* {t("shared.search")} */}
                Clear
              </button>
            )}
          </div>

          <div className="sort">
            <div className="sort-videos">
              {!searchedProjectAsset ? (
                <h3>
                  {totalNumberOfAssets} {t("shared.videos")}
                </h3>
              ) : (
                <h3>
                  {totalNumberOfAssets}
                  {""}
                  {t("shared.videos")}
                </h3>
              )}
            </div>

            <div className="sort-button">
              <button
                onClick={latestFirstHandler}
                className={latestFirstClassname}
              >
                {t("assetsFromSuite.latestFirst")}
              </button>
              <button
                onClick={characterFirstHandler}
                className={characterFirstClassname}
              >
                A-Z
              </button>
            </div>
          </div>

          {suiteAllAssets?.length > 0 && (
            <InfiniteScroll
              dataLength={suiteAllAssets.length}
              next={fetchMoreData}
              hasMore={hasMore}
              pageStart={pageStart}
              loader={
                <div className="display-flex-center">
                  <TailSpin
                    type="TailSpin"
                    color="#0a7aad"
                    height={50}
                    width={50}
                  />
                </div>
              }
            >
              <div className="wrapperPrimary">
                {suiteAllAssets.map((el) => (
                  <div className="wrapper" key={el.id}>
                    <div className="product-img">
                      <img
                        src={el.thumbnailSmall || el.bannerImageSmall}
                        onError={(e) =>
                          (e.target.src = "/Image/video_poster.jpeg")
                        }
                      />
                    </div>
                    <div className="product-info">
                      <div className="product-text">
                        <p>
                          {el?.name[el?.defaultLanguage] ||
                            el?.name["en_US"] ||
                            el?.name}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="product-price-btn"
                        id={el.id}
                        onClick={() =>buttonAction?
                          buttonAction(el):
                          downloadAssetHandler(
                            el.id,
                            el?.name[el?.defaultLanguage] ||
                              el?.name["en_US"] ||
                              el?.name,
                            el?.duration
                          )
                        }
                      >
                        {t(`shared.${buttonLabel}`)}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      )}
    </Modal>
  );
}
