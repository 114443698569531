import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../contexts/StateHolder";
import SaveModal from "./Modal/SaveModal";

export default function SideNavBar(props) {
    const { t } = useTranslation();

    const [checkTimeline, setcheckTimeline] = useState(null);
    const [showNewProjectNameModal, setShowNewProjectNameModal] = useState(false);
    const [newProjectName, setNewProjectName] = useState("");

    const { timeline } = useMyContext();

    useEffect(() => {
        if (timeline) {
            setcheckTimeline(timeline);
        }
    }, [JSON.stringify(timeline)]);
    
    const exportToSuiteButtonHandler = () => {
        if (checkTimeline.video[0].items.length > 0) {
            props.fileUploadSuiteModal();
        } else {
            return toast.error(`${t("error.noFilesAddVideoToTimeline")}`);
        }
    };

    const saveProject = () => {
        props.updateProject(newProjectName);
        setShowNewProjectNameModal(false);
        toast.info(`${t("shared.projectRenamed")}  ${newProjectName}`);
        return setNewProjectName("");
    };

    const SideNavBarButtons = [
        {
            icon: <FontAwesomeIcon icon="folder" />,
            link: true,
            route: "/",
            id: 1,
            text: t("newProjectDialog.allProjects"),
        },
        {
            icon: <FontAwesomeIcon icon="file-export" />,
            id: 2,
            text: t("sideNavBar.exportSuite"),
            onClick: () => {
                exportToSuiteButtonHandler();
            },
        },
        {
            icon: <FontAwesomeIcon icon="save" />,
            onClick: () => {
                setShowNewProjectNameModal(true);
            },
            id: 3,
            text: t("sideNavBar.saveas"),
        },
    ];
    
    return (
        <>
            {showNewProjectNameModal && (
                <>
                    <SaveModal
                        inputValue={newProjectName}
                        setInputValue={setNewProjectName}
                        inputLabel={t("newProjectDialog.projectName")}
                        confirmHandler={saveProject}
                        cancelHandler={() => setShowNewProjectNameModal(false)}
                    />
                </>
            )}
            <div className="sidebar-info">
                {SideNavBarButtons.map((el) => {
                    return el?.link ? (
                        <Link to={el?.route} key={el?.id.toString()}>
                            <div className="sidebar-info__icon">{el?.icon}</div>
                            <div className="sidebar-info__text">{el?.text}</div>
                        </Link>
                    ) : (
                        <button onClick={el?.onClick} key={el?.id.toString()}>
                            <div className="sidebar-info__icon">{el?.icon}</div>
                            <div className="sidebar-info__text">{el?.text}</div>
                        </button>
                    );
                })}
            </div>
        </>
    );
}
