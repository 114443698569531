import React from "react";
import Modal from "react-modal";
import { TailSpin } from "react-loader-spinner";

Modal.setAppElement(document.body);

export default function LoadingDialog(props) {
    return (
        <div>
            <Modal isOpen={true} contentLabel="Loading" className={"modalLoading"} overlayClassName={"overlayLoading"}>
                <h2 style={{ textAlign: "center" }}>{props.heading}</h2>
                <p style={{ textAlign: "center", paddingTop: "50px" }}>{props.subHeading && props.subHeading}</p>
                <div className="display-flex-center">
                    <TailSpin type="TailSpin" color="#0a7aad" height={50} width={50} />
                </div>
            </Modal>
        </div>
    );
}
