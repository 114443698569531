import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { server } from "../../config";
import SaveModal from "./Modal/SaveModal";

export default function Uploader(props) {
    const { t } = useTranslation();
    const [askForFileName, setAskForFileName] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [composerName, setComposerName] = useState("");

    const getUploadParams = async ({ file, meta }) => {
        try {
            let url;

            if (props.selectedMediaTab === "projectMediaFiles") {
                // for normal project files
                return { url: `${server.apiUrl}/project/${props.project}/file` };
            } else if (props.selectedMediaTab === "musicLibrary") {
                // for music library
                url = `${server.apiUrl}/project/${props.project}/musicLibrary/file`;

                const body = new FormData();
                // this seems the only way to send file name and we need to send filename to meta as well soit can be updated in front ends
                body.append(`${projectName}:${composerName}`, file);
                return {
                    url: url,
                    body,
                    method: "POST",
                    meta: { name: projectName, composer_name: composerName },
                };
            } else {
                // brand elements
                return { url: `${server.apiUrl}/project/${props.project}/admin/file` };
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [fileAll, setFileAll] = useState(null);

    const handleChangeStatus = ({ meta, file, xhr, remove }, status, fileWithMeta) => {
        try {
            if (status === "preparing" && !projectName && !props.autoUpload) {
                setAskForFileName(true);
                setFileAll(fileWithMeta);
            }

            if (status === "done") {
                const response = JSON.parse(xhr.response);
                toast.success(`${t("shared.addedSuccessfully")}`);

                const { name, type } = meta;
                props.onAdd({
                    id: response.resource_id,
                    name,
                    duration: response.length,
                    mime: type,
                    admin: props.selectedMediaTab === "projectMediaFiles" || props.selectedMediaTab === "musicLibrary" ? false : true,
                    musicLibraryFiles: props.selectedMediaTab === "musicLibrary" ? true : false,
                    composer_name: meta.composer_name,
                    resource_s3: response?.resource_s3,
                });
                setProjectName("");
                setComposerName("");

                remove();
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    const confirmButtonHandler = () => {
        fileAll[0].restart();
        setAskForFileName("");
    };

    const cancelButtonHandler = () => {
        // we also have to remove the file
        setAskForFileName(false);
        setProjectName("");
        setComposerName("");
        fileAll[0].remove();
    };

    return (
        <>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept={props.uploaderAcceptValues ? props.uploaderAcceptValues : "image/*,audio/*,video/*"}
                inputContent={(files, extra) =>
                    extra.reject ? t("error.uploaderMessage") : props?.uploaderText ? props?.uploaderText : t("shared.dragFromComputer")
                }
                inputWithFilesContent={"Upload Files"}
                styles={{
                    dropzoneReject: { borderColor: "#7a281b", backgroundColor: "#DAA" },
                }}
                // if autoUpload is false -< we have resume it by ourself ->we are doing it in useEffect after getting filename from user
                // https://react-dropzone-uploader.js.org/docs/props
                autoUpload={props.autoUpload}
            />
            {askForFileName && (
                <>
                    <SaveModal
                        inputValue={projectName}
                        setInputValue={setProjectName}
                        inputLabel={"Music Detail"}
                        inputPlaceHolder={"Enter music name"}
                        confirmHandler={confirmButtonHandler}
                        cancelHandler={() => cancelButtonHandler()}
                        inputValue1={composerName}
                        setInputValue1={setComposerName}
                        showAdditonalInput={true}
                        inputLabel1={"Enter composer name"}
                    />
                </>
            )}
        </>
    );
}
