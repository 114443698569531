import React, { useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
const mime = require("mime-types");

import { drawImageAspectVideoFrame, getVideoStartTime } from "../../shared/utils";
import Modal from "../../shared/UI/Modal/Modal";

export default function ImageModal(props) {
    const { t } = useTranslation();

    const canvasRef3 = useRef(null);
    const videoRef = useRef(null);
    let videoCurrentTime;
    let ctx,
        canvasOffset,
        offsetX,
        offsetY,
        startX,
        startY,
        pi2,
        resizerRadius,
        draggingResizer,
        imageX,
        imageY,
        imageWidth,
        imageHeight,
        imageRight,
        draggingImage,
        rr,
        imageBottom,
        img,
        mouseX,
        orig_src = {},
        itemSelectedEdit,
        imageCoordinatesSplit,
        mouseY;
    let canvas;

    const [imagePosition, setImagePosition] = useState(null);

    useEffect(() => {
        if (props.showImageModal) {
            if (!canvasRef3) return;
        }

        let images = props.timeline.video[1];
        // finding video item at current timeline pointer

        let currentseconds, vstarttime;

        const allItems = props.timelineRef.getItemsAtCurrentTime(props.time).find((el) => el.includes("videotrack0") || el.includes("blankvidtrack"));
        const seekedvideoitem = props.timelineRef.itemsData.get().find((el) => el.id == allItems);
        if (seekedvideoitem) {
            currentseconds = (props.time - seekedvideoitem.start) / 1000;

            // we need to find the current time of that particular video
            vstarttime = getVideoStartTime(seekedvideoitem);
            videoCurrentTime = vstarttime + currentseconds;

            if (videoRef.current) {
                videoRef.current.src = seekedvideoitem.url;
                videoRef.current.currentTime = videoCurrentTime;
                videoRef.current.load();
            }
        } else {
            videoRef.current.src = "blankVideo/blank.mp4#t=1";
            videoRef.current.currentTime = 1;
            videoRef.current.load();
        }
        // need to check if the user wants to edit the image
        if (props.editImage) {
            const itemSplit = props.selectedItems[0].split(":");
            const itemSelectedIndex = itemSplit[itemSplit.length - 1];

            itemSelectedEdit = images.items[itemSelectedIndex];
            props.setImageDuration(itemSelectedEdit?.out);

            // getting image coordinates
            let imageCoordinates = Object.values(Object.values(itemSelectedEdit.filters).find((el) => el.service === "affine").options).find(
                (el1) => el1.name === "transition.rect1"
            ).value;
            
            imageCoordinatesSplit = imageCoordinates.split(" ");
            imageX = imageCoordinatesSplit[0] * 1;
            imageY = imageCoordinatesSplit[1] * 1;
            imageWidth = imageCoordinatesSplit[2] * 1;
            imageHeight = imageCoordinatesSplit[3] * 1;
        }

        /*
            1) Check if there are already images inserted. if there is already image find the endDuration of image at timeline
        */

        img = new Image();
        canvas = canvasRef3.current;
        ctx = canvas.getContext("2d");
        canvasOffset = getOffset(canvas);
        offsetX = canvasOffset.left;
        offsetY = canvasOffset.top;

        startX;
        startY;

        pi2 = Math.PI * 2;
        resizerRadius = 8;
        rr = resizerRadius * resizerRadius;
        draggingResizer = {
            x: 0,
            y: 0,
        };

        if (!props.editImage) {
            imageX = 50;
            imageY = 50;
        } else {
            imageX = imageCoordinatesSplit[0] * 1;
            imageY = imageCoordinatesSplit[1] * 1;
        }
        draggingImage = false;

        let mimeValue;
        const mimeTypeEditImage =
            itemSelectedEdit && props.editImage && itemSelectedEdit?.admin
                ? props.adminresources[itemSelectedEdit?.resource]?.mime
                : props.resources[itemSelectedEdit?.resource]?.mime;
        
        mimeValue = props.editImage ? mime.extension(mimeTypeEditImage) : mime.extension(props.imageItem?.mime);
        
        if ((mimeValue.includes("jpeg") || mimeValue.includes("png")) && !itemSelectedEdit) {
            let mimeValueArray = props.imageItem?.name?.split(".").length;
            mimeValue = props.imageItem?.name?.split(".")[mimeValueArray - 1];
        } else if ((mimeValue.includes("jpeg") || mimeValue.includes("png"))&& itemSelectedEdit) {
            let mimeValueArray = itemSelectedEdit?.admin
                ? props.adminresources[itemSelectedEdit?.resource]?.name?.split(".").length
                : props.resources[itemSelectedEdit?.resource]?.name?.split(".").length;

            mimeValue = itemSelectedEdit?.admin
                ? props.adminresources[itemSelectedEdit?.resource]?.name?.split(".")[mimeValueArray - 1]
                : props.resources[itemSelectedEdit?.resource]?.name?.split(".")[mimeValueArray - 1];
        }

        img.src = props.editImage
            ? itemSelectedEdit?.resource_s3
                ? itemSelectedEdit?.resource_s3
                : itemSelectedEdit?.admin
                ? `/project/WORKER/AdminProjectFiles/${itemSelectedEdit.resource}.${mimeValue}`
                : `/project/WORKER/${props.project}/${itemSelectedEdit.resource}.${mimeValue}`
            : props.imageItem.resource_s3
            ? props.imageItem.resource_s3
            : props.adminItems
            ? `/project/WORKER/AdminProjectFiles/${props.imageItem.id}.${mimeValue}`
            : `/project/WORKER/${props.project}/${props.imageItem.id}.${mimeValue}`;

        img.onload = function () {
            if (props.editImage) {
                imageWidth = imageCoordinatesSplit[2] * 1;
                imageHeight = imageCoordinatesSplit[3] * 1;
                imageRight = imageX * 1 + imageWidth * 1;
                imageBottom = imageY * 1 + imageHeight * 1;
            } else {
                if (img.width > img.height) {
                    imageWidth = 200;
                    imageHeight = Math.floor(200 / img.width * img.height);
                } else {
                    imageHeight = 200;
                    imageWidth = Math.floor(200 / img.height * img.width);
                }
                imageRight = imageX * 1 + imageWidth * 1;
                imageBottom = imageY * 1 + imageHeight * 1;
                imageRight = imageX * 1 + imageWidth * 1;
                imageBottom = imageY * 1 + imageHeight * 1;
            }

            orig_src.height = img.height;
            orig_src.width = img.width;

            setImagePosition(null);
            draw(true, true, true);
        };

        canvas?.addEventListener("mousedown", (e) => {
            handleMouseDown(e);
        });
        canvas?.addEventListener("mousemove", (e) => {
            handleMouseMove(e);
        });
        canvas?.addEventListener("mouseup", (e) => {
            handleMouseUp(e);
        });
        canvas?.addEventListener("mouseout", (e) => {
            handleMouseOut(e);
        });
    }, [props.showImageModal, canvasRef3]);

    const getOffset = (element) => {
        if (!element.getClientRects().length) {
            return { top: 0, left: 0 };
        }

        let rect = element.getBoundingClientRect();
        let win = element.ownerDocument.defaultView;
        return {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset,
        };
    };

    const draw = (withAnchors, withBorders, initialRender) => {
        if (!ctx) {
            return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawImageAspectVideoFrame(ctx, videoRef.current);

        // checkImageDimension(initialRender);
        setImagePosition({ imageX, imageY, imageWidth, imageHeight });
        ctx.drawImage(
            img,
            imageX, // x-position
            imageY, // y-position
            imageWidth, // image-width
            imageHeight // image height
        );

        // optionally draw the draggable anchors
        if (withAnchors) {
            drawDragAnchor(imageX, imageY);
            drawDragAnchor(imageRight, imageY);
            drawDragAnchor(imageRight, imageBottom);
            drawDragAnchor(imageX, imageBottom);
        }

        // optionally draw the connecting anchor lines
        if (withBorders) {
            ctx.beginPath();
            ctx.moveTo(imageX, imageY);
            ctx.lineTo(imageRight, imageY);
            ctx.lineTo(imageRight, imageBottom);
            ctx.lineTo(imageX, imageBottom);
            ctx.closePath();
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#087aad';
            ctx.stroke();
        }
    };

    const drawDragAnchor = (x, y) => {
        ctx.beginPath();
        ctx.arc(x, y, resizerRadius, 0, pi2, false);
        ctx.closePath();
        ctx.fillStyle = '#087aad';
        ctx.fill();
    };

    const anchorHitTest = (x, y) => {
        var dx, dy;

        // top-left
        dx = x - imageX;
        dy = y - imageY;
        if (dx * dx + dy * dy <= rr) {
            return 0;
        }
        // top-right
        dx = x - imageRight;
        dy = y - imageY;
        if (dx * dx + dy * dy <= rr) {
            return 1;
        }
        // bottom-right
        dx = x - imageRight;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= rr) {
            return 2;
        }
        // bottom-left
        dx = x - imageX;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= rr) {
            return 3;
        }
        return -1;
    };

    const hitImage = (x, y) => {
        return x > imageX && x < imageX + imageWidth && y > imageY && y < imageY + imageHeight;
    };

    const handleMouseDown = (e) => {
        startX = parseInt(e.clientX - offsetX);
        startY = parseInt(e.clientY - offsetY);
        draggingResizer = anchorHitTest(startX, startY);
        draggingImage = draggingResizer < 0 && hitImage(startX, startY);
    };

    const handleMouseUp = (e) => {
        draggingResizer = -1;
        draggingImage = false;
        draw(true, false);
    };

    const handleMouseOut = (e) => {
        handleMouseUp(e);
    };

    const handleMouseMove = (e) => {
        if (draggingResizer > -1) {
            mouseX = parseInt(e.clientX - offsetX);
            mouseY = parseInt(e.clientY - offsetY);
            // resize the image
            switch (draggingResizer) {
                case 0:
                    //top-left
                    imageX = mouseX;
                    imageWidth = imageRight - mouseX;
                    imageY = mouseY;
                    imageHeight = imageBottom - mouseY;
                    canvas.style.cursor = "ns-resize";
                    // image aspect-ratio maintained here
                    if (e?.shiftKey) {
                        imageHeight = (imageWidth / orig_src.width) * orig_src.height;
                    }
                    break;
                case 1:
                    //top-right
                    imageY = mouseY;
                    imageWidth = mouseX - imageX;
                    imageHeight = imageBottom - mouseY;
                    canvas.style.cursor = "ns-resize";
                    // image aspect-ratio maintained here
                    if (e?.shiftKey) {
                        imageHeight = (imageWidth / orig_src.width) * orig_src.height;
                    }
                    break;
                case 2:
                    //bottom-right
                    imageWidth = mouseX - imageX;
                    imageHeight = mouseY - imageY;
                    canvas.style.cursor = "ew-resize";
                    // image aspect-ratio maintained here
                    if (e?.shiftKey) {
                        imageHeight = (imageWidth / orig_src.width) * orig_src.height;
                    }
                    break;
                case 3:
                    //bottom-left
                    imageX = mouseX;
                    imageWidth = imageRight - mouseX;
                    imageHeight = mouseY - imageY;
                    canvas.style.cursor = "nesw-resize";
                    // image aspect-ratio maintained here
                    if (e?.shiftKey) {
                        imageHeight = (imageWidth / orig_src.width) * orig_src.height;
                    }
                    break;
            }
            if (imageWidth < 25) {
                imageWidth = 25;
            }
            if (imageHeight < 25) {
                imageHeight = 25;
            }
            
            // set the image right and bottom
            imageRight = imageX + imageWidth;
            imageBottom = imageY + imageHeight;
            canvas.style.cursor = "ew-resize";
            // redraw the image with resizing anchors
            draw(true, true);
        } else if (draggingImage) {
            mouseX = parseInt(e.clientX - offsetX);
            mouseY = parseInt(e.clientY - offsetY);
            // move the image by the amount of the latest drag
            var dx = mouseX - startX;
            var dy = mouseY - startY;
            imageX += dx;
            imageY += dy;
            imageRight += dx;
            imageBottom += dy;
            // reset the startXY for next time
            startX = mouseX;
            startY = mouseY;
            // redraw the image with border
            canvas.style.cursor = "auto";
            draw(true, true);
        }
    };

    return (
        <>
            <Modal show={true}>
                <h2 style={{ textAlign: "center", fontFamily: "Lato" }}>{t("imageModal.imagePositionAndDuration")}</h2>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <canvas id="canvas100" ref={canvasRef3} width="640" height="360" style={{ background: "white" }}></canvas>
                    <video controls={false} preload="metadata" ref={videoRef} style={{ display: "none" }}></video>
                </div>
                <div className="a-tip">
                    <p>
                        <strong>{t("imageModal.hint")}:</strong> {t("imageModal.hold")}
                        <span>{t("imageModal.shift")}</span> {t("imageModal.resizeGuide")}
                    </p>
                </div>

                <div className="filter-form__value">
                    <label htmlFor={"duration"}>
                        {t("shared.imageDuration")}
                        <br />
                        <span style={{ fontSize: "10px" }}>{t("shared.hour-minute-second-milli")}</span>
                    </label>
                    <Datetime
                        input={true}
                        timeFormat="HH:mm:ss,SSS"
                        dateFormat={false}
                        className="datetime-picker"
                        timeConstraints={{
                            hours: { min: 0, max: 99 },
                            minutes: { min: 0, max: 59 },
                            seconds: { min: 0, max: 59 },
                            milliseconds: { min: 0, max: 999 },
                        }}
                        value={props.imageDuration}
                        onChange={(e) => props.imageInputHandler(e)}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <button
                        onClick={() => props.insertImageHandler(imagePosition, props.editImage ? itemSelectedEdit : props.adminItems)}
                        style={{ padding: "10px 30px", background: "#0a7aad" }}
                    >
                        {props.editImage ? t("shared.edit") : t("shared.insert")}
                    </button>
                    <button onClick={props.imageHanlderCancel} style={{ padding: "10px 30px" }}>
                        {t("shared.cancel")}
                    </button>
                </div>
            </Modal>
        </>
    );
}
