import React, { useState, useEffect } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import Modal from "react-modal";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import { mdiFileDownload } from "@mdi/js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import LoadingDialog from "./LoadingDialog";
import { server } from "../../config";
import SaveModal from "./Modal/SaveModal";

export default function AudioRecording({ setShowRecordModal, audioCapture, project, loadData, setAudioCapture, setresources, resources }) {
    const { t } = useTranslation();

    const [recordState, setRecordState] = useState(null);
    const [blobURL, setblobURL] = useState("");
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [showSaveProjectDialog, setShowSaveProjectDialog] = useState(false);
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        navigator.permissions.query({ name: "microphone" }).then(function (permissionStatus) {
            const permissionStateMicro = permissionStatus.state; // granted, denied, prompt
            if (permissionStateMicro !== "granted") {
                setShowRecordModal(false);
                setAudioCapture(false);
                return toast.error("Microphone Permission Denied. Please Allow Access.");
            }
        });
    }, []);

    const start = () => {
        setRecordState(RecordState.START);
        setRecording(true);
    };

    const stop = () => {
        setRecordState(RecordState.STOP);
        setRecording(false);
    };

    const onStop = (audioData) => {
        setblobURL(audioData);
    };

    const onPause = () => {
        setPaused(true);
        setRecordState(RecordState.PAUSE);
    };
    const onResume = () => {
        setPaused(false);
        start();
    };

    const save = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("video", blobURL.blob, projectName);
        const urlServer = `${server.apiUrl}/project/${project}/downloadFilesToProject`;
        let id1 = {
            id: Math.random(),
            name: projectName,
            admin: false,
            loading: true,
            mime: "audio/mp3",
            duration: null,
        };

        setTimeout(async () => {
            setresources({ id1, ...resources });
            setblobURL(null);
            setProjectName("");
            setShowSaveProjectDialog(false);
            setLoading(false);
            setShowRecordModal(false);
            setAudioCapture(false);

            try {
                await axios({
                    method: "post",
                    url: urlServer,
                    data: formData,
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    headers: {
                        "Content-Type": "multipart/form-data" + formData,
                    },
                });
                await loadData(true);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }, 2000);
    };

    const closeModalAndRecorder = () => {
        stop();
        setShowRecordModal(false);
        setAudioCapture(false);
    };

    return (
        <>
            {loading && <LoadingDialog heading={t("shared.assetName")} subHeading={t("error.donotCloseBrowser")} />}
            {showSaveProjectDialog && !loading && (
                <SaveModal
                    inputValue={projectName}
                    setInputValue={setProjectName}
                    inputLabel={t("newProjectDialog.projectName")}
                    confirmHandler={save}
                    cancelHandler={() => setShowSaveProjectDialog(false)}
                    confirmIcon={mdiFileDownload}
                />
            )}
            <Modal isOpen={audioCapture} contentLabel="New Project" className={"audioRecording"} overlayClassName={"overlay"}>
                <div className="audio-recorder">
                    <AudioReactRecorder state={recordState} onStop={onStop} />
                    <ReactAudioPlayer src={blobURL?.url} controls stye={{ color: "red" }} />

                    <div className="buttonWebCam" style={{ display: "flex" }}>
                        {" "}
                        {!recording && (
                            <button onClick={start} style={{ background: "#0a7aad" }}>
                                {t("shared.start")}
                            </button>
                        )}
                        {recording && <button onClick={stop}> {t("shared.stop")}</button>}
                        {recording && !paused && <button onClick={onPause}>Pause</button>}
                        {paused && <button onClick={onResume}> Resume</button>}
                        <button onClick={closeModalAndRecorder}> {t("shared.cancel")}</button>
                        {blobURL && (
                            <button onClick={() => setShowSaveProjectDialog(true)} style={{ background: "#0a7aad" }}>
                                {t("shared.save")}
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}
