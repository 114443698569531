import React from "react";
import classes from "./Button.module.css";

const Button = ({ onClick, children, style, inverse, danger }) => {
    return (
        <button onClick={onClick} style={style} className={`${classes.Button} ${inverse && classes.ButtonInverse} ${danger && classes.ButtonDanger}`}>
            {children}
        </button>
    );
};

export default Button;
