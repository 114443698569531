import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import Modal from "react-modal";
import axios from "axios";
import { Icon } from "@mdi/react";
import { mdiFileDownload, mdiRecordCircle } from "@mdi/js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { server } from "../../config";
import LoadingDialog from "./LoadingDialog";
import SaveModal from "./Modal/SaveModal";

let recChunks = [];
export default function WebcamStremCapture(props) {
    const { t } = useTranslation();

    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [showSaveProjectDialog, setShowSaveProjectDialog] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [loading, setLoading] = useState(false);
    const startTime = useRef(null);
    const [paused, setPaused] = useState(false);

    let recordedVideo, currentStream1;

    useEffect(() => {
        if (webcamRef.current) {
            webcamRef.current.classList.remove("videoDisplayNone");
        }
    }, []);

    const handleStartCaptureClick = useCallback(() => {
        // console.log(webcamRef.current);
        // check if the camera is ready . if it is not we send an error message
        // if (webcamRef.current && !webcamRef.current.stream && !webcamRef.current.state.hasUserMedia) {
        //     toast.error(`${t("error.permissionCameraAccess")}`);
        //     props.setwebcamCapture(false);
        //     return;
        // }
        if (webcamRef.current && webcamRef.current.stream) {
            recChunks = [];
            setRecordedChunks([]);
            recordedVideo = document.getElementById("recorded-video-webcam");
            currentStream1 = document.getElementById("currentStream");
            console.log(recordedVideo, currentStream1);
            currentStream1?.classList.remove("videoDisplayNone");
            recordedVideo.classList.add("videoDisplayNone");
            setCapturing(true);

            startTime.current = Date.now();
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm",
            });
            mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
            mediaRecorderRef.current.start();
        } else {
            toast.error(`${t("error.cameraLoading")}`);
        }
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
                recChunks = [...recChunks, data];
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setTimeout(() => {
            if (recChunks?.length > 0) {
                recordedVideo = document.getElementById("recorded-video-webcam");
                currentStream1 = document.getElementById("currentStream");
                currentStream1?.classList.add("videoDisplayNone");

                if (recordedVideo) {
                    recordedVideo.classList.remove("videoDisplayNone");
                    const blob = new Blob(recChunks, { type: "video/mp4" });
                    recordedVideo.src = URL.createObjectURL(blob);
                    recordedVideo.load();
                    recordedVideo.onloadeddata = function () {
                        recordedVideo.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    };
                }
            } else {
                console.log("no recording", recChunks);
            }
        }, 200);
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleDownload = useCallback(async () => {
        if (recordedChunks.length) {
            setLoading(true);
            const blob = new Blob(recordedChunks, {
                type: "video/webm;codecs=h264",
            });

            const formData = new FormData();
            formData.append("video", blob, projectName);
            const urlServer = `${server.apiUrl}/project/${props.project}/downloadFilesToProject`;
            let id1 = {
                id: Math.random(),
                name: projectName,
                admin: false,
                loading: true,
                mime: "video/mp4",
                duration: null,
            };

            setTimeout(async () => {
                props.setresources({ id1, ...props.resources });
                setProjectName("");
                setRecordedChunks([]);
                recChunks = [];
                setLoading(false);
                props.setwebcamCapture(false);
                props.setShowRecordModal(false);
                try {
                    await axios({
                        method: "post",
                        url: urlServer,
                        data: formData,
                        maxContentLength: Infinity,
                        maxBodyLength: Infinity,
                        headers: {
                            "Content-Type": "multipart/form-data" + formData,
                        },
                    });
                    await props.loadData(true);
                } catch (error) {
                    setLoading(false);
                }
            }, 2000);
        }
    }, [recordedChunks, projectName]);

    const handleCancelCaptureClick = () => {
        if (webcamRef.current.state === "recording") {
            handleStopCaptureClick();
        }
        setRecordedChunks([]);
        recChunks = [];
        setTimeout(() => {
            props.setwebcamCapture(false);
        }, 100);
    };

    const handlePauseCaptureClick = useCallback(
        (e) => {
            setPaused(true);
            console.log("webcamRef.current", mediaRecorderRef.current);
            mediaRecorderRef.current.pause();
        },
        [mediaRecorderRef, setCapturing]
    );

    const handleResumeCaptureClick = useCallback(
        (e) => {
            setPaused(false);
            mediaRecorderRef.current.resume();
        },
        [mediaRecorderRef, setCapturing]
    );

    return (
        <>
            {loading && <LoadingDialog heading={t("shared.savingFile")} subHeading={t("error.donotCloseBrowser")} />}
            {showSaveProjectDialog && !loading && (
                <SaveModal
                    inputValue={projectName}
                    setInputValue={setProjectName}
                    inputLabel={t("newProjectDialog.projectName")}
                    confirmHandler={handleDownload}
                    cancelHandler={() => setShowSaveProjectDialog(false)}
                    confirmIcon={mdiFileDownload}
                />
            )}
            {!showSaveProjectDialog && (
                <Modal isOpen={true} contentLabel="Add New FIlter" className={"webcamModal"} overlayClassName={"overlay"}>
                    <div className="buttonWebCa">
                        <div style={{ position: "relative" }}>
                            <Webcam audio={true} ref={webcamRef} style={{ aspectRatio: "16:9", width: "100%" }} muted id="currentStream" />
                            {paused && <h1 className="capture-video-paused">Paused</h1>}
                        </div>
                        <video id="recorded-video-webcam" className="videoDisplayNone" controls style={{ aspectRatio: "16:9", width: "100%" }}></video>

                        <div className="buttonWebCam">
                            {capturing ? (
                                <>
                                    <Icon path={mdiRecordCircle} title="User Profile" size={1} color="red" spin />
                                    <button onClick={handleStopCaptureClick}>{t("shared.stopRecording")}</button>
                                </>
                            ) : (
                                <button onClick={handleStartCaptureClick} style={{ background: "#0a7aad" }}>
                                    {t("shared.startRecording")}
                                </button>
                            )}
                            {capturing && !paused && <button onClick={handlePauseCaptureClick}>Pause</button>}
                            {paused && <button onClick={handleResumeCaptureClick}> Resume</button>}
                            <button onClick={handleCancelCaptureClick}>Cancel</button>
                            {recordedChunks.length > 0 && <button onClick={() => setShowSaveProjectDialog(true)}>{t("shared.saveRecording")}</button>}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
