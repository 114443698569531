import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

export default function PartialScreenInputModal(props) {
    const { t } = useTranslation();
    const {
        CROP_X,
        setCROP_X,
        CROP_Y,
        setCROP_Y,
        CROP_WIDTH,
        setCROP_WIDTH,
        CROP_HEIGHT,
        setCROP_HEIGHT,
        setCropScreenCapture,
        setShowCropScreenCaptureInputModal,
    } = props;

    // we need text input for x, y , width and height of crop screen capture
    const [currentAspectRatio, setCurrentAspectRatio] = useState(1);

    useEffect(() => {
        setCropScreenCapture(false);
    }, []);

    const cropScreenCaptureConfirmHandler = () => {
        if (CROP_X < 0 || CROP_Y < 0 || CROP_X > 1920 || CROP_Y > 1080 || CROP_WIDTH < 10 || CROP_HEIGHT < 10 || CROP_WIDTH > 1920 || CROP_HEIGHT > 1080) {
            // invalid input
            return toast.error(`${t("partialScreenCapture.invalidInput")}`, {
                position: "top-center",
                hideProgressBar: true,
                closeOnClick: true,
            });
        }
        // closing current input modal
        setShowCropScreenCaptureInputModal(false);
        // showing partial capture screen
        setCropScreenCapture(true);
    };

    const aspectRatioSelection = [
        {
            name: "16/9",
            value: 1,
        },
        {
            name: t("partialScreenCapture.none"),
            value: 0,
        },
    ];

    const changeWidthHeightAspectRatio = () => {
        // we will just change height

        let changedHeight = Math.floor(CROP_WIDTH / (16 / 9));

        setTimeout(() => {
            setCROP_HEIGHT(changedHeight);
        }, 100);
    };

    const changeAspectRatio = (val) => {
        // here if val is 1 means we have to manage aspect raio as well

        setCurrentAspectRatio(val);
        console.log("val", val);
        if (val * 1 === 1) {
            changeWidthHeightAspectRatio();
        }
    };

    const changeCropWidth = (val) => {
        // we have to check for aspect ratio
        if (currentAspectRatio * 1 === 1) {
            setCROP_WIDTH(val);
            setCROP_HEIGHT(Math.floor(val / (16 / 9)));
        } else {
            // dont have to maintain aspect ratio
            setCROP_WIDTH(val);
        }
    };

    const changeCropHeight = (val) => {
        // we have to check for aspect ratio
        if (currentAspectRatio * 1 === 1) {
            setCROP_HEIGHT(val);
            setCROP_WIDTH(Math.floor(val * (16 / 9)));
        } else {
            // dont have to maintain aspect ratio
            setCROP_HEIGHT(val);
        }
    };

    return (
        <>
            <Modal isOpen={true} contentLabel="Add New FIlter" className={"modal"} overlayClassName={"overlay"}>
                <h2
                    style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {t("partialScreenCapture.enterWidthAndHeight")}
                </h2>
                <div className="text-modal">
                    <div className="filterForm">
                        <div className="filterInput">
                            <label htmlFor={"duration"}>
                                {`${t("partialScreenCapture.screenCaptureWidth")}`}

                                <br />
                                <span style={{ fontSize: "10px" }}>
                                    {`${t("addText.valueRange")}`}
                                    (10-1920)
                                </span>
                            </label>
                            <input type="number" required value={CROP_WIDTH} onChange={(e) => changeCropWidth(e.target.value)} max={1920} min={10} />
                        </div>
                        <div className="filterInput">
                            <label htmlFor={"duration"}>
                                {`${t("partialScreenCapture.screenCaptureHeight")}`}

                                <br />
                                <span style={{ fontSize: "10px" }}>
                                    {`${t("addText.valueRange")}`}
                                    (10-1080)
                                </span>
                            </label>

                            <input type="number" required value={CROP_HEIGHT} onChange={(e) => changeCropHeight(e.target.value)} max={1080} min={10} />
                        </div>
                    </div>

                    <div className="filterForm">
                        <div className="filterInput">
                            <label htmlFor={"duration"}>
                                {`${t("partialScreenCapture.screenCaptureX")}`}

                                <br />
                                <span style={{ fontSize: "10px" }}>
                                    {`${t("addText.valueRange")}`}
                                    (0-1920)
                                </span>
                            </label>

                            <input type="number" required value={CROP_X} onChange={(e) => setCROP_X(e.target.value)} max={1920} min={0} />
                        </div>
                        <div className="filterInput">
                            <label htmlFor={"duration"}>
                                {`${t("partialScreenCapture.screenCaptureY")}`}

                                <br />
                                <span style={{ fontSize: "10px" }}>
                                    {`${t("addText.valueRange")}`}
                                    (0-1080)
                                </span>
                            </label>
                            <input type="number" required value={CROP_Y} onChange={(e) => setCROP_Y(e.target.value)} max={1080} min={10} />
                        </div>
                    </div>
                    <div className="filterForm">
                        <div className="filterInput">
                            <label htmlFor={"duration"}>{`${t("partialScreenCapture.aspectRatio")}`}</label>
                            <select
                                onChange={({ target }) => {
                                    changeAspectRatio(target.value);
                                }}
                            >
                                {aspectRatioSelection.map((el) => (
                                    <option key={el.value} value={el.value}>
                                        {el.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <button
                        onClick={() => cropScreenCaptureConfirmHandler()}
                        style={{
                            padding: "10px 30px",
                            background: "#147aa9",
                            minWidth: "150px",
                            marginTop: "20px !important",
                        }}
                    >
                        {`${t("partialScreenCapture.confirm")}`}
                    </button>
                    <button
                        onClick={() => {
                            props.setShowCropScreenCaptureInputModal(false);
                        }}
                        style={{
                            padding: "10px 30px",
                            minWidth: "150px",
                            marginTop: "20px !important",
                        }}
                    >
                        {`${t("shared.cancel")}`}
                    </button>
                </div>
            </Modal>
        </>
    );
}
