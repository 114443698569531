import React from "react";
import Modal from "react-modal";
import { TailSpin } from "react-loader-spinner";

Modal.setAppElement(document.body);

export default function LoadingDialog1() {
    return (
        <div>
            <Modal
                isOpen={true}
                contentLabel="Loading"
                className={"loadingDialog1"}
                overlayClassName={"overlayloadingDialog1"}
                style={{ pointerEvents: "none" }}
            >
                <div className="display-flex-center" style={{ height: "100%" }}>
                    <TailSpin type="TailSpin" color="#0a7aad" height={50} width={50} />
                </div>
            </Modal>
        </div>
    );
}
