import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

Modal.setAppElement(document.body);

export default function FetchErrorDialog({ msg, onClose, hideButton }) {
    const { t } = useTranslation();

    return (
        <Modal contentLabel={t("error.errorCommunicatingWithServer")} isOpen={true} className={"modalLoading"} overlayClassName={"overlayLoading"}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src="/Image/server_down.svg" alt="" height={200} width={200} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        minHeight: "100px",
                    }}
                >
                    <p> Something went wrong. </p>
                    <p>{t("error.refreshPage")}</p>
                </div>
            </div>
        </Modal>
    );
}
