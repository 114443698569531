import React, { useRef, useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import { mdiFileDownload } from "@mdi/js";
import { useTranslation } from "react-i18next";

import { server } from "../../config";
import LoadingDialog from "./LoadingDialog";
import RecordRTC from "recordrtc";
import { useMyContext } from "../contexts/StateHolder";
import SaveModal from "./Modal/SaveModal";

let recorder;

export default function PartialScreenCapture(props) {
    const { t } = useTranslation();
    const { CROP_X, CROP_Y, CROP_WIDTH, CROP_HEIGHT } = props;
    const { projectUrl } = useMyContext();

    let stream, audio, recordedVideo;
    const canvasRef = useRef(null);

    const [capturing, setCapturing] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSaveProjectDialog, setShowSaveProjectDialog] = useState(false);
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        handleStartCaptureClick();
    }, []);

    const cropFrame = useCallback(
        (video) => {
            let htmlCanvasElement = document.querySelector("#c13");
            let _canvas = htmlCanvasElement;
            let _context = htmlCanvasElement?.getContext("2d");

            // we need to define these from somewhere
            let CROP_W = CROP_WIDTH,
                CROP_H = CROP_HEIGHT;

            if (_canvas) {
                _canvas.width = CROP_W;
                _canvas.height = CROP_H;
            }

            let dx = 0;
            let dy = 0;
            if (_context) {
                _context.drawImage(video, CROP_X, CROP_Y, CROP_W, CROP_H, dx, dy, CROP_W, CROP_H);
            }
        },
        [CROP_X, CROP_Y, CROP_HEIGHT, CROP_WIDTH]
    );

    function setupVideoFeedback() {
        if (stream) {
            let video = document.getElementById("mediaElement");
            video.srcObject = stream;
            video.play();
            video.ontimeupdate = function () {
                video && cropFrame(video);
            };
        } else {
            console.log("No stream available");
        }
    }

    const handleStartCaptureClick = useCallback(async () => {
        try {
            recorder = null;
            recordedVideo = document.getElementById("recorded-video");
            recordedVideo?.classList.add("videoDisplayNone");
            let canvas1 = document.querySelector("#c13");
            canvas1?.classList.remove("videoDisplayNone");

            let displayMediaOptions = {
                video: {
                    cursor: "always",
                    aspectRatio: 1.777777778,
                    width: 1280,
                    height: 720,
                },
            };

            setCapturing(true);
            stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            audio = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
            });

            // RecordRTC goes here
            let canvas = canvasRef.current;
            let captureStream = canvas.captureStream();
            recorder = await RecordRTC(captureStream, {
                type: "video",
            });
            recorder.screen = captureStream;
            await recorder.startRecording();
            setupVideoFeedback();
            // closing default desgin og stop streaming when user use pur button
            stream.getVideoTracks()[0].addEventListener("ended", () => handleStopCaptureClick());
        } catch (error) {
            console.log(error);
            toast.error(`${t("shared.permissionDenied")}`);
            setCapturing(false);
            props.setCropScreenCapture(false);
        }
    }, [setCapturing]);

    const handleStopCaptureClick = useCallback(
        async (e) => {
            recordedVideo = document.getElementById("recorded-video");
            recordedVideo?.classList.remove("videoDisplayNone");
            stream.getTracks().forEach((track) => track.stop());
            audio.getTracks().forEach((track) => track.stop());
            recorder.stopRecording(function () {
                let blob = recorder?.getBlob();
                recordedVideo.srcObject = null;
                recordedVideo.src = URL.createObjectURL(blob);
                recordedVideo.load();
                let canvas = canvasRef.current;
                // hiding canvas
                canvas?.classList.add("videoDisplayNone");
                setCapturing(false);

                if (recorder.screen && recorder.screen.getVideoTracks) {
                    recorder.screen.stop();
                    recorder.screen = null;
                }
            });

            setCapturing(false);
        },
        [setCapturing]
    );

    const handleCancelCaptureClick = async () => {
        // we have to stop thee reecorrding when the user press cancel button while video is being recorded
        if (recorder) {
            await handleStopCaptureClick();
            recorder = null;
        }

        props.setCropScreenCapture(false);
    };

    const handleDownload = useCallback(async () => {
        if (recorder) {
            setLoading(true);
            console.log("recorder", recorder);

            // const blob = new Blob(recorder.getBlob(), {
            //   type: 'video/mp4',
            // });

            const blob = recorder.getBlob();
            console.log(blob);

            const formData = new FormData();
            formData.append("video", blob, projectName);

            const urlServer = `${server.apiUrl}/project/${projectUrl}/downloadFilesToProject`;
            let id2 = {
                id: Math.random(),
                name: projectName,
                admin: false,
                loading: true,
                mime: "video/mp4",
                duration: null,
            };
            setTimeout(async () => {
                props.setresources({ id2, ...props.resources });

                setProjectName("");
                recorder = null;

                setLoading(false);
                props.setCropScreenCapture(false);

                try {
                    const res = await axios({
                        method: "post",
                        url: urlServer,

                        data: formData,
                        maxContentLength: Infinity,
                        maxBodyLength: Infinity,
                        headers: {
                            "Content-Type": "multipart/form-data" + formData,
                        },
                    });

                    console.log(res);

                    await props.loadData(true);
                } catch (error) {
                    setLoading(false);
                }
            }, 2000);
        }
    }, [projectName]);

    const changeDimension = async () => {
        // need to stop capture first
        await handleStopCaptureClick();
        props.setShowCropScreenCaptureInputModal(true);
    };

    const handlePauseCaptureClick = useCallback(
        (e) => {
            setPaused(true);
            recorder.pauseRecording();
        },
        [paused, recorder]
    );

    const handleResumeCaptureClick = useCallback(
        (e) => {
            setPaused(false);
            recorder.resumeRecording();
        },
        [paused, recorder]
    );
    return (
        <>
            {loading && <LoadingDialog heading={t("shared.savingFile")} subHeading={t("error.donotCloseBrowser")} />}

            {showSaveProjectDialog && !loading && (
                <SaveModal
                    inputValue={projectName}
                    setInputValue={setProjectName}
                    inputLabel={t("newProjectDialog.projectName")}
                    confirmHandler={handleDownload}
                    cancelHandler={() => setShowSaveProjectDialog(false)}
                    confirmIcon={mdiFileDownload}
                />
            )}

            {!showSaveProjectDialog && !loading && (
                <Modal isOpen={true} contentLabel="Screen Capture" className={"modalScreen"} overlayClassName={"overlay"}>
                    <>
                        <h1
                            style={{
                                padding: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0 0 20px 0",
                            }}
                        >
                            {t("partialScreenCapture.partialScreenCapture")}
                        </h1>

                        <div style={{ position: "relative" }}>
                            <canvas
                                id="c13"
                                ref={canvasRef}
                                style={{
                                    width: "640px",
                                    height: "360px",
                                }}
                            ></canvas>

                            {paused && <h1 className="capture-video-paused">Paused</h1>}
                        </div>
                    </>
                    <video id="mediaElement" className="videoDisplayNone" style={{ width: "100%", aspectRatio: "16/9", opacity: 0 }}></video>

                    <video id="recorded-video" className="videoDisplayNone" controls style={{ aspectRatio: "16/9", width: "100%" }}></video>

                    <div className="buttonWebCam">
                        {recorder && !paused && !capturing && (
                            <button onClick={changeDimension} style={{ backgroundColor: "#0a7aad" }}>
                                {t("partialScreenCapture.changeDimension")}
                            </button>
                        )}
                        {!capturing ? (
                            <button onClick={handleStartCaptureClick}>{t("shared.recordNew")}</button>
                        ) : (
                            <button onClick={handleStopCaptureClick}> {t("shared.stop")}</button>
                        )}
                        {capturing && !paused && <button onClick={handlePauseCaptureClick}>Pause</button>}
                        {paused && recorder && capturing && <button onClick={handleResumeCaptureClick}> Resume</button>}
                        <button onClick={handleCancelCaptureClick}> {t("shared.cancel")}</button>
                        {recorder && !paused && !capturing && (
                            <button onClick={() => setShowSaveProjectDialog(true)} style={{ backgroundColor: "#0a7aad" }}>
                                {t("shared.save")}
                            </button>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
