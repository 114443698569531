import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Modal from "react-modal";
import date from "date-and-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import ImageModal from "./Modal/ImageModal";
import { manageImageXPosition, manageImageYPosition } from "../shared/utils";
import SourcesTableRow from "./SourcesTableRow";
import Uploader from "./Uploader";
import timeManager from "../../models/timeManager";
import { server } from "../../config";
import BrandColorItem from "./BrandColorItem";
import ColorGrading from "./ColorGrading";
import BrandFontItem from "./BrandFontItem";
import { useMyContext } from "../contexts/StateHolder";
import { Player } from "video-react";

const mime = require("mime-types");

export default function Sources(props) {
  console.log("props", props);
  const { t } = useTranslation();
  const [videoURL, setVideoURL] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  // image modal
  const [showImageModal, setShowImageModal] = useState(false);
  const [brandColorSelectModal, setBrandColorSelectModal] = useState(false);
  const [brandFontSelectModal, setBrandFontSelectModal] = useState(false);
  const [selectFont, setSelectFont] = useState("");
  const [imageDuration, setImageDuration] = useState("");
  const [audioID, setAudioID] = useState(null);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [hexColor, sethexColor] = useState("");
  // for showing partial and full screen
  const [showScreenCaptureModal, setShowScreenCaptureModal] = useState(false);
  // modal for brand color picker
  const [showBrandColorModal, setshowBrandColorModal] = useState(false);
  const [selectedMediaTab, setSelectedMediaTab] = useState("projectMediaFiles");
  const [projectMediaFilteredItems, setProjectMediaFilteredItems] =
    useState(null);

  const [cookies] = useCookies(["uac"]);

  const {
    setscreenCapture,
    globalAvailableFonts,
    setGlobalAvailableFonts,
    globalBrandColor,
    setGlobalBrandColor,
    setwebcamCapture,
    timeline,

    setPauseCanvas,
    time,
    projectUrl,
    setXMLFiles,
    XMLFiles,
  } = useMyContext();

  const delResource = async (id) => {
    try {
      let f = [...projectMediaFilteredItems];
      const curDeleteItem = f.find((el) => el.id === id);
      let index = f.indexOf(curDeleteItem);
      curDeleteItem.loadingDelete = true;
      f[index] = curDeleteItem;
      setProjectMediaFilteredItems(f);

      const url = `${server.apiUrl}/project/${projectUrl}/file/${id}`;

      const res = await axios.delete(url);
      if (res.data.msg === "Resource in use") {
        let f1 = [...projectMediaFilteredItems];
        const curDeleteItem1 = f1.find((el) => el.id === id);
        let index1 = f1.indexOf(curDeleteItem1);
        curDeleteItem1.loadingDelete = false;
        f1[index1] = curDeleteItem1;
        setProjectMediaFilteredItems(f1);

        return toast.error(`${t("sources.assetInUse")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      }

      props.onDelResource(id);
    } catch (error) {
      props.fetchError(error.message);
    }
  };
  const imageHandler = () => {
    if (!showImageModal && imageDuration) {
      if (!timeManager.isValidDuration(imageDuration)) {
        alert(`${t("error.shouldBeInFormat")} HH:MM:SS,sss`);
        return;
      }
    }
  };
  const recordButtonhandler = () => {
    setPauseCanvas(true);

    setShowRecordModal(true);
  };
  const importButtonhandler = async () => {
    setPauseCanvas(true);

    props.setshowAssetModal(true);
  };

  const putResource = async (id, adminItems, musicLibraryItems) => {
    setPauseCanvas(true);

    try {
      let duration = null;
      // if the mime type is image then we ask user for duration
      let currentItem;
      // if music library then we should select from musicLibraryResources
      if (musicLibraryItems) {
        currentItem = props.musicLibraryresources;
      } else {
        currentItem = adminItems ? props.adminItems : props.items;
      }

      if (new RegExp(/^image\//).test(currentItem[id].mime)) {
        setAudioID(id);
        setShowImageModal(true);
        imageHandler(id);
      }
      if (!new RegExp(/^image\//).test(currentItem[id].mime)) {
        let track = currentItem[id].mime.includes("audio/")
          ? "audiotrack0"
          : "videotrack0";
        if (currentItem[id].mime.includes("image/")) {
          track = "videotrack1";
        }
        const url = `${server.apiUrl}/project/${projectUrl}/file/${id}`;
        const data = {
          musicLibraryItems: musicLibraryItems,
          track,
          duration,
          adminItems,
          id,
          currentItem: currentItem[id],
          resource_s3: currentItem[id]?.resource_s3,
        };

        const res = await axios.put(url, data);
        // we will save the version of current xml
        let oldXml = [...XMLFiles];
        oldXml.push(res?.data?.projectXMLFILE);
        setXMLFiles(oldXml);

        props.onPutResource(
          id,
          duration,
          track,
          adminItems,
          currentItem,
          musicLibraryItems
        );
      }
    } catch (error) {
      props.fetchError(error.message);
    }
  };

  const onAddResource = (resource, admin, musicLibrary) => {
    setPauseCanvas(true);

    props.onAddResource(resource, admin, musicLibrary);
  };

  const onPlay = (el, adminItems, musicLibrary) => {
    setPauseCanvas(true);
    setShowVideoModal(true);
    let mimeValue = mime.extension(el.mime);
    if (mimeValue === "qt") {
      mimeValue = "mov";
    } else if (mimeValue === "mpga") {
      mimeValue = "mp3";
    }

    const videoInitial = `${el.id}.${mimeValue}`;
    if (musicLibrary) {
      setVideoURL(
        `/project/WORKER/AdminProjectFiles/MusicLibraryFiles/${videoInitial}`
      );
    } else {
      !adminItems
        ? setVideoURL(`/project/WORKER/${projectUrl}/${videoInitial}`)
        : setVideoURL(`/project/WORKER/AdminProjectFiles/${videoInitial}`);
      // the file is in s3
      if (el?.resource_s3) {
        setVideoURL(el?.resource_s3);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowVideoModal(!showVideoModal);
  };

  const imageInputHandler = (e) => {
    if (e._d) {
      const d = new Date(e._d.getTime());
      const start = date.format(d, "HH:mm:ss,SSS");
      setImageDuration(start);
    }
  };
  const imageHanlderCancel = async () => {
    setShowImageModal(!showImageModal);
    setImageDuration("");
  };
  const insertImageHandler = async (el, adminItems) => {
    const currentItem = adminItems ? props.adminItems : props.items;
    if (!timeManager.isValidDuration(imageDuration)) {
      return toast.error(`${t("error.insertTimeInFormat")} HH:MM:SS,sss`);
    }
    let track = currentItem[audioID].mime.includes("audio/")
      ? "audiotrack0"
      : "videotrack0";
    if (currentItem[audioID].mime.includes("image/")) {
      track = "videotrack1";
    }

    const url = `${server.apiUrl}/project/${projectUrl}/file/${audioID}`;
    let duration = imageDuration;
    const data = {
      track,
      duration,
      adminItems,
      id: audioID,
      currentItem: currentItem[audioID],
    };

    let res;
    let X = manageImageXPosition(el.imageX);
    let Y = manageImageYPosition(el.imageY);
    let imageWid = manageImageXPosition(el.imageWidth);
    let imageHei = manageImageYPosition(el.imageHeight);

    if (track === "videotrack1") {
      // we are passing item id so we can add more filter and
      res = await axios.put(url, data, timeline.video[1].items.length);

      let rect = `${X} ${Y} ${imageWid} ${imageHei} 1`;
      let rect1 = `${el.imageX} ${el.imageY} ${el.imageWidth} ${el.imageHeight}`;
      let fill = 1;
      let distort = 1;
      let threads = 0;
      let valign = "middle";
      let halign = "center";

      let newFilter = {
        filter: "affine",
        params: {
          "transition.rect": rect,
          "transition.rect1": rect1,
          "transition.fill": fill,
          "transition.distort": distort,
          "transition.threads": threads,
          "transition.valign": valign,
          "transition.halign": halign,
        },
        duration,
        // if adminItems ->it belongs to brandElement
        adminItems,
        currentItem: currentItem[audioID],
      };
      newFilter.id = audioID;
      newFilter.track = track;
      newFilter.item = timeline.video[1].items.length;

      // props.onPutResource(audioID, duration, track, adminItems, currentItem);
      props.onAdd(newFilter, false, true);
    } else {
      res = await axios.put(url, data);
      props.onPutResource(audioID, duration, track, adminItems, currentItem);
    }

    let oldXml = [...XMLFiles];
    oldXml.push(res?.data?.projectXMLFILE);
    setXMLFiles(oldXml);
    setShowImageModal(!showImageModal);
    setImageDuration("");
  };

  useEffect(() => {
    const t = Object.values(props.items)
      .filter((el) => !el.admin)
      .filter((el1) => !el1.musicLibraryFile);
    setProjectMediaFilteredItems(t);
  }, [props.items]);

  const selectAudioRecord = () => {
    setShowRecordModal(false);
    props.setAudioCapture(true);
  };

  const selectVideoRecord = () => {
    props.setShowRecordModal(false);
    setwebcamCapture(true);
  };

  const delBrandColor = async (item) => {
    try {
      const url = `${server.apiUrl}/database/colors`;
      let addedColorList = globalBrandColor.filter((el) => el !== item);
      await axios.post(url, {
        colorsItem: addedColorList,
      });
      sethexColor("");
      setGlobalBrandColor(addedColorList);
    } catch (error) {
      console.log(error);
    }
  };

  const delBrandFont = async (item) => {
    try {
      const url = `${server.apiUrl}/database/fonts`;
      let addedFontList = props.globalFonts.filter((el) => el !== item);
      setGlobalAvailableFonts([...globalAvailableFonts, item]);
      await axios.post(url, {
        fontsItem: addedFontList,
      });

      props.setGlobalFonts(addedFontList);
    } catch (error) {
      console.log(error);
    }
  };

  const brandColorHandler = async () => {
    try {
      const url = `${server.apiUrl}/database/colors`;
      let addedColorList = [...globalBrandColor, hexColor];
      await axios.post(url, {
        colorsItem: addedColorList,
      });
      setGlobalBrandColor(addedColorList);
      sethexColor("");
      setBrandColorSelectModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const brandFontHandler = async () => {
    try {
      const url = `${server.apiUrl}/database/fonts`;
      let addedFontList = [...props.globalFonts, selectFont];
      await axios.post(url, {
        fontsItem: addedFontList,
      });
      props.setGlobalFonts(addedFontList);
      let s = globalAvailableFonts.filter((el) => el !== selectFont);
      setGlobalAvailableFonts(s);
      setSelectFont("");
      setBrandFontSelectModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const brandColorPickerHandler = () => {
    setshowBrandColorModal((el) => !el);
  };

  // Note: Brand element here includes brand element brand color and brand fonts
  //  Brand element can be addded by just the admin of that organization and they are limited to that organization i.e brand elements of one organization cannot be used by other organization
  // simillarly project media files also can be accessed by that specifc project only i.e if the projects add 10 videos and audios files it is private to that project and can only be used in that specific project
  // On the other hand music library is neither organization nor project specific. They are shared among all existing organization and project and as of now only admin can add those

  return (
    <>
      {/* // modal based on different brand and project media files starts here */}

      {showBrandColorModal && (
        <ColorGrading
          sethexColor={sethexColor}
          closeHandleDialog={brandColorPickerHandler}
        />
      )}

      {brandColorSelectModal && (
        <Modal
          isOpen={true}
          className={"modalVideo"}
          overlayClassName={"overlay"}
        >
          <h2
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Add Brand Color
          </h2>
          <div className="text-modal">
            <span
              style={{
                fontSize: "10px",
                textAlign: "center",
                justifySelf: "center",
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {`Use Hex Color format for better result example : '#fff'`}
            </span>{" "}
            <div className="filterForm">
              <div className="filterInput">
                <label htmlFor={"duration"}> Brand Color</label>

                <div className="textColour">
                  <div
                    className="textColour-input"
                    style={{ width: "100%", padding: "0 10px" }}
                  >
                    <input
                      type="text"
                      required
                      value={hexColor}
                      onChange={(e) => {
                        sethexColor(e.target.value);
                      }}
                      style={{
                        outline: "none",
                        width: "150px",
                        maxWidth: "150px",
                        minWidth: "150px",
                        border: "none",
                      }}
                    />{" "}
                    <button
                      onClick={brandColorPickerHandler}
                      style={{
                        overflow: "hidden",
                        margin: 0,
                        background: "#147aa9",
                      }}
                    >
                      {"Select"}
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  brandColorHandler();
                }}
                style={{
                  padding: "10px 30px",
                  background: "#147aa9",
                  minWidth: "150px",
                  marginTop: "20px !important",
                }}
                disabled={!hexColor}
              >
                {"Add"}
              </button>
              <button
                onClick={() => {
                  sethexColor("");
                  setBrandColorSelectModal(false);
                }}
                style={{
                  padding: "10px 30px",
                  minWidth: "150px",
                  marginTop: "20px !important",
                }}
              >
                {`${t("shared.cancel")}`}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {brandFontSelectModal && (
        <Modal
          isOpen={true}
          className={"modalVideo"}
          overlayClassName={"overlay"}
        >
          <h2
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Add Brand Font
          </h2>
          <div className="text-modal">
            <div className="filterForm">
              <div className="filterInput">
                <label htmlFor={"duration"}>Select Brand Font</label>
                <select onChange={({ target }) => setSelectFont(target.value)}>
                  <option value="" disabled selected>
                    Select your font
                  </option>

                  {globalAvailableFonts.map((el) => (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  brandFontHandler();
                }}
                style={{
                  padding: "10px 30px",
                  background: "#147aa9",
                  minWidth: "150px",
                  marginTop: "20px !important",
                }}
                disabled={!selectFont}
              >
                {"Add"}
              </button>
              <button
                onClick={() => {
                  setSelectFont("");
                  setBrandFontSelectModal(false);
                }}
                style={{
                  padding: "10px 30px",
                  minWidth: "150px",
                  marginTop: "20px !important",
                }}
              >
                {`${t("shared.cancel")}`}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showImageModal && (
        <ImageModal
          imageDuration={imageDuration}
          imageInputHandler={imageInputHandler}
          insertImageHandler={insertImageHandler}
          imageHanlderCancel={imageHanlderCancel}
          showImageModal={showImageModal}
          imageItem={
            props.adminItems[audioID]
              ? props.adminItems[audioID]
              : props.items[audioID]
          }
          project={projectUrl}
          items={props.items}
          timeline={timeline}
          adminItems={!props.adminItems[audioID] ? false : true}
          resources={props.items}
          adminresources={props.adminItems}
          timelineRef={props.timelineref}
          time={time}
        />
      )}
      {showScreenCaptureModal && (
        <Modal
          isOpen={true}
          contentLabel="Select Any One"
          className={"modalVideo"}
          overlayClassName={"overlay"}
          onRequestClose={handleCloseDialog}
        >
          <div style={{ padding: "50px" }}>
            <h1
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 0 20px 0",
              }}
            >
              {t("sources.selectAnyOne")}
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  setShowScreenCaptureModal(false);
                  setscreenCapture(true);
                }}
                style={{ padding: "10px 30px", background: "#0a7aad" }}
              >
                {t("sources.fullScreenCapture")}
              </button>
              <button
                onClick={() => {
                  setShowScreenCaptureModal(false);
                  props.setShowCropScreenCaptureInputModal(true);
                }}
                style={{ padding: "10px 30px", background: "#0a7aad" }}
              >
                {t("partialScreenCapture.partialScreenCapture")}
              </button>
              <button
                onClick={() => setShowScreenCaptureModal(false)}
                style={{ padding: "10px 30px" }}
              >
                {t("shared.cancel")}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showRecordModal && (
        <Modal
          isOpen={true}
          contentLabel="Select Any One"
          className={"modalVideo"}
          overlayClassName={"overlay"}
          onRequestClose={handleCloseDialog}
        >
          <div style={{ padding: "50px" }}>
            <h1
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 0 20px 0",
              }}
            >
              {t("sources.selectAnyOne")}
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={selectVideoRecord}
                style={{ padding: "10px 30px", background: "#0a7aad" }}
              >
                {t("sources.audioAndVideo")}
              </button>
              <button
                onClick={selectAudioRecord}
                style={{ padding: "10px 30px", background: "#0a7aad" }}
              >
                {t("sources.audio")}
              </button>
              <button
                onClick={() => setShowRecordModal(false)}
                style={{ padding: "10px 30px" }}
              >
                {t("shared.cancel")}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showVideoModal && (
        <Modal
          isOpen={true}
          contentLabel="Add New FIlter"
          className={"modalVideo"}
          overlayClassName={"overlay"}
          onRequestClose={handleCloseDialog}
        >
          <div
            style={{
              position: "relative",

              width: "500px",
            }}
          >
            <Player src={videoURL} controls />
          </div>
        </Modal>
      )}

      {/*  modal based on different brand and project media files ends  here */}

      <div id={"sources"}>
        {/* // heading name for different brands and project media files */}
        <div className="headingSources">
          <h2
            className={
              selectedMediaTab === "projectMediaFiles"
                ? "headingSources_active"
                : "headingSources"
            }
            onClick={() => setSelectedMediaTab("projectMediaFiles")}
          >
            <i className="material-icons" aria-hidden={true}>
              movie_filter
            </i>
            {t("sources.projectMediaFiles")}
          </h2>

          <h2
            className={
              selectedMediaTab === "brandElements"
                ? "headingSources_active"
                : "headingSources"
            }
            onClick={() => setSelectedMediaTab("brandElements")}
          >
            <i className="material-icons" aria-hidden={true}>
              movie_filter
            </i>
            {t("sources.brandElements")}
          </h2>
          <h2
            className={
              selectedMediaTab === "musicLibrary"
                ? "headingSources_active"
                : "headingSources"
            }
            onClick={() => setSelectedMediaTab("musicLibrary")}
          >
            <i className="material-icons" aria-hidden={true}>
              library_music{" "}
            </i>
            Music library
          </h2>
          {cookies.ual == 1 && (
            <>
              <h2
                className={
                  selectedMediaTab === "colorElements"
                    ? "headingSources_active"
                    : ""
                }
                onClick={() => setSelectedMediaTab("colorElements")}
              >
                <i className="material-icons" aria-hidden={true}>
                  palette
                </i>
                {t("shared.brandColor")}
              </h2>
              <h2
                className={
                  selectedMediaTab === "fontElements"
                    ? "headingSources_active"
                    : ""
                }
                onClick={() => setSelectedMediaTab("fontElements")}
              >
                <i className="material-icons" aria-hidden={true}>
                  font_download
                </i>
                {/* {t('shared.brandColor')} */}
                Fonts
              </h2>
            </>
          )}
        </div>

        {/* // content for different heading */}

        {selectedMediaTab === "projectMediaFiles" &&
          (projectMediaFilteredItems && projectMediaFilteredItems.length > 0 ? (
            <div className="table-container">
              <table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {projectMediaFilteredItems.map((el) => (
                    <SourcesTableRow
                      key={el.id}
                      item={el}
                      onRemove={delResource}
                      onInsert={putResource}
                      onPlay={onPlay}
                      projectUrl={projectUrl}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sources-drag">
              <div className="sources-drag__heading">
                <FontAwesomeIcon icon="file-video" />
                {t("sources.noFilesAdded")}
              </div>
            </div>
          ))}

        {selectedMediaTab === "brandElements" &&
          (props.adminItems && Object.keys(props.adminItems).length > 0 ? (
            <div
              className="table-container"
              style={{
                minHeight:
                  selectedMediaTab !== "projectMediaFiles" && cookies.ual == 1
                    ? "360px"
                    : `calc(360px + 5vh)`,
              }}
            >
              <table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {Object.keys(props.adminItems).map((el) => (
                    <SourcesTableRow
                      key={el}
                      item={props.adminItems[el]}
                      onRemove={delResource}
                      onInsert={putResource}
                      onPlay={onPlay}
                      // if adminItems is true it will not show delete button

                      adminItems={true}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sources-drag">
              <div className="sources-drag__heading">
                <FontAwesomeIcon icon="file-video" />
                {t("sources.noBrandElementAdded")}
              </div>
            </div>
          ))}

        {selectedMediaTab === "musicLibrary" &&
          (props.musicLibraryresources &&
          Object.keys(props.musicLibraryresources).length > 0 ? (
            <div
              className="table-container"
              style={{
                minHeight: `calc(360px + 5vh)`,
                background: "#292929",
                margin: 0,
              }}
            >
              <table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {Object.keys(props.musicLibraryresources).map((el) => (
                    <SourcesTableRow
                      key={el}
                      item={props.musicLibraryresources[el]}
                      onRemove={delResource}
                      onInsert={putResource}
                      onPlay={onPlay}
                      musicLibraryresources={true}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sources-drag">
              <div className="sources-drag__heading">
                <FontAwesomeIcon icon="file-video" />
                {t("sources.noFilesAdded")}
              </div>
            </div>
          ))}

        {selectedMediaTab === "colorElements" &&
          cookies.ual == 1 &&
          (globalBrandColor && globalBrandColor.length > 0 ? (
            <div
              className="table-container"
              style={{
                minHeight:
                  selectedMediaTab !== "projectMediaFiles" && cookies.ual == 1
                    ? "360px"
                    : `calc(360px + 5vh)`,
              }}
            >
              <table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {globalBrandColor.map((el, i) => (
                    <BrandColorItem
                      key={`${el}${i}`}
                      item={el}
                      onRemove={(el) => delBrandColor(el)}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sources-drag">
              <div className="sources-drag__heading">
                {/* {t('sources.noBrandElementAdded')} */}
                No brand color added
              </div>
            </div>
          ))}
        {selectedMediaTab === "fontElements" &&
          cookies.ual == 1 &&
          (props.globalFonts && props.globalFonts.length > 0 ? (
            <div
              className="table-container"
              style={{
                minHeight:
                  selectedMediaTab !== "projectMediaFiles" && cookies.ual == 1
                    ? "360px"
                    : `calc(360px + 5vh)`,
              }}
            >
              <table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {props.globalFonts.map((el, i) => (
                    <BrandFontItem
                      key={`${el}${i}`}
                      item={el}
                      onRemove={(el) => delBrandFont(el)}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sources-drag">
              <div className="sources-drag__heading">
                {/* {t('sources.noBrandElementAdded')} */}
                No brand font added
              </div>
            </div>
          ))}

        {/* button handler for project mdia and brand element */}
        {selectedMediaTab === "projectMediaFiles" && (
          <div className="sources-button">
            <button onClick={importButtonhandler}>
              <FontAwesomeIcon icon="file-import" />
              {t("shared.import")}
            </button>

            <button onClick={recordButtonhandler}>
              <FontAwesomeIcon icon="video" />
              {t("shared.record")}
            </button>
            {/* <button onClick={screenCaptureButtonhandler}> */}
            <button
              onClick={() => {
                setShowScreenCaptureModal(true);
                setPauseCanvas(true);
              }}
            >
              <FontAwesomeIcon icon="desktop" />
              {t("shared.screenCapture")}
            </button>
            <button className="sources-button-upload">
              <FontAwesomeIcon
                icon="file-upload"
                style={{ color: "#373737", backgroundColor: "#373737" }}
                className="file-upload__icon"
              />
              <Uploader
                onAdd={(resource, admin) => onAddResource(resource, admin)}
                project={projectUrl}
                selectedMediaTab={selectedMediaTab}
                autoUpload={true}
              />
            </button>
          </div>
        )}
        {selectedMediaTab === "brandElements" && cookies.ual == 1 && (
          <div className="sources-button">
            <button className="sources-button-upload">
              <FontAwesomeIcon
                icon="file-upload"
                style={{ color: "#373737", backgroundColor: "#373737" }}
                className="file-upload__icon"
              />
              <Uploader
                onAdd={(resource, admin) => onAddResource(resource, admin)}
                project={projectUrl}
                selectedMediaTab={selectedMediaTab}
                autoUpload={true}
              />
            </button>
          </div>
        )}
        {selectedMediaTab === "colorElements" && cookies.ual == 1 && (
          <div className="sources-button">
            <button
              onClick={() => {
                setBrandColorSelectModal(true);
                setPauseCanvas(true);
              }}
            >
              <FontAwesomeIcon icon="palette" />
              {/* {t('shared.screenCapture')} */}
              Add Color
            </button>
          </div>
        )}
        {selectedMediaTab === "fontElements" && cookies.ual == 1 && (
          <div className="sources-button">
            <button
              onClick={() => {
                setBrandFontSelectModal(true);
                setPauseCanvas(true);
              }}
            >
              <FontAwesomeIcon icon="font" />
              {/* {t('shared.screenCapture')} */}
              Add Font
            </button>
          </div>
        )}
        {selectedMediaTab === "musicLibrary" && cookies.ual == 1 && (
          <div className="sources-button">
            <button className="sources-button-upload">
              <FontAwesomeIcon
                icon="file-upload"
                style={{ color: "#373737", backgroundColor: "#373737" }}
                className="file-upload__icon"
              />
              <Uploader
                onAdd={(resource, admin) => onAddResource(resource, admin)}
                project={projectUrl}
                selectedMediaTab={selectedMediaTab}
                // todo language change
                uploaderText={
                  "Drag and drop your music files or upload from computer"
                }
                // accept only audio values
                uploaderAcceptValues={"audio/*"}
                // we have to ask for filename
                autoUpload={false}
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
