import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const { detect } = require("detect-browser");
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { server } from "../config";
import NewProjectDialog from "./newProject/NewProjectDialog";
import Dashboard from "./editor/Dashboard";

import "./shared/FontAwesomeIcon/FontAwesome";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
// video player
import "video-react/dist/video-react.css"; // import css

export default function App() {
    const { t } = useTranslation();

    const [tokenExpiryTime, setTokenExpiryTime] = useState(null);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [cookies, setCookie] = useCookies(["userToken"]);

    let timeInterval;
    const browser = detect();

    // console.log = function () {}; // Disable all console.logs from console
    if (process.env.NODE_ENV === "production") {
        console.log = () => {};
    }
    useEffect(() => {
        // const updateDbField = async () => {
        //   const url = `${server.apiUrl}/database/table`;

        //   const res = await axios.get(url);
        // };
        // updateDbField();

        if (browser) {
            if (browser.name === "safari") {
                toast.error(`${t("app.safariNoSupport")}`, {
                    position: "top-center",
                    autoClose: 500000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, []);

    useEffect(() => {
        if (tokenExpiryTime) {
            const time = Math.floor(Date.parse(tokenExpiryTime) - Date.now()) - 300000;
            timeInterval = setTimeout(() => {
                setShowTokenModal(true);
            }, time);
        }
    }, [tokenExpiryTime]);

    const extendSession = async () => {
        try {
            const url = `${server.apiUrl}/token`;
            const res = await axios.get(url, {
                params: {
                    extendSession: true,
                },
            });
            console.log(res, "res");
            setTokenExpiryTime(res?.data?.expiry);
            setCookie("userToken", res.data.tokenUser, {
                options: { path: "/" },
                expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
            });
            setShowTokenModal(false);
            toast.info(`${t("app.sessionExtended")}`);
        } catch (error) {
            toast.error(`${t("error.startApplicationAgain")}`);
        }
    };

    const continueSession = async () => {
        setTokenExpiryTime(null);
        setShowTokenModal(!showTokenModal);
        clearTimeout(timeInterval);
        toast.error(`${t("error.sessionExpiring")}`);
    };

    let route = (
        // <div className={classes.hello}>
        <>
            <ToastContainer limit={3} />

            <Switch>
                <Route exact path="/project/:projectURL">
                    <Dashboard
                        tokenExpiryTime={tokenExpiryTime}
                        setTokenExpiryTime={setTokenExpiryTime}
                        setShowTokenModal={setShowTokenModal}
                        showTokenModal={showTokenModal}
                        extendSession={extendSession}
                        continueSession={continueSession}
                    />
                </Route>
            </Switch>

            <Switch>
                <Route exact path="/">
                    <NewProjectDialog
                        tokenExpiryTime={tokenExpiryTime}
                        setTokenExpiryTime={setTokenExpiryTime}
                        setShowTokenModal={setShowTokenModal}
                        showTokenModal={showTokenModal}
                        extendSession={extendSession}
                        continueSession={continueSession}
                    />
                </Route>
            </Switch>
            {/* </div> */}
        </>
    );

    return route;
}
