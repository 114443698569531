import React, { useRef, useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import { mdiFileDownload } from "@mdi/js";
import { useTranslation } from "react-i18next";

import { server } from "../../config";
import LoadingDialog from "./LoadingDialog";
import { useMyContext } from "../contexts/StateHolder";
import SaveModal from "./Modal/SaveModal";

let recChunks = [];

export default function ScreenCapture(props) {
    const { t } = useTranslation();

    let stream, audio, videoStream, recordedVideo;

    const { setscreenCapture, projectUrl } = useMyContext();

    const screenCamRef = useRef(null);
    const screenRecorderRef = useRef(null);

    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSaveProjectDialog, setShowSaveProjectDialog] = useState(false);
    const [paused, setPaused] = useState(false);

    const startTime = useRef(null);

    useEffect(() => {
        try {
            const runFun = async () => {
                let displayMediaOptions = {
                    video: {
                        cursor: "always",
                        width: 1280,
                        height: 720,
                        aspectRatio: { ideal: 1.7777777778 },
                    },
                };

                setCapturing(true);
                // here if the user denies camera we have to go back
                try {
                    stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
                    console.log(stream);
                    // for audio capturee
                    audio = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            echoCancellation: true,
                            noiseSuppression: true,
                            sampleRate: 44100,
                        },
                    });
                    setupVideoFeedback();
                } catch (error) {
                    console.log(error);
                    toast.error(`${t("shared.permissionDenied")}`);
                    setCapturing(false);
                    setscreenCapture(false);
                    return;
                }

                stream.getVideoTracks()[0].addEventListener("ended", () => handleStopCaptureClick());
                startTime.current = Date.now();
                let mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()]);

                screenRecorderRef.current = new MediaRecorder(mixedStream);
                screenRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
                screenRecorderRef.current.start();
            };
            runFun();
        } catch (error) {
            console.log(error);
            toast.error(`${t("shared.permissionDenied")}`);
            setCapturing(false);
            setscreenCapture(false);
        }
    }, []);

    function setupVideoFeedback() {
        if (stream) {
            videoStream = document.getElementById("videoStream");
            videoStream.classList.remove("videoDisplayNone");
            videoStream.srcObject = stream;
            videoStream.play();
        } else {
            console.log("No stream available");
        }
    }

    const handleStartCaptureClick = useCallback(async () => {
        try {
            recChunks = [];
            setRecordedChunks([]);
            startTime.current = Date.now();
            let displayMediaOptions = {
                video: {
                    cursor: "always",
                    aspectRatio: 1.777777778,
                },
            };

            stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            navigator.mediaDevices.getSupportedConstraints();
            audio = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
            });

            stream.getVideoTracks()[0].addEventListener("ended", () => handleStopCaptureClick());
            setCapturing(true);

            let mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()]);
            screenRecorderRef.current = new MediaRecorder(mixedStream);
            screenRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
            screenRecorderRef.current.start();
        } catch (error) {
            console.log(error);
            toast.error(`${t("shared.permissionDenied")}`);
            setCapturing(false);
            setscreenCapture(false);
        }
    }, [screenCamRef, setCapturing, screenRecorderRef]);
    
    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
                recChunks = [...recChunks, data];
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(
        (e) => {
            videoStream = document.getElementById("videoStream");
            videoStream.classList?.add("videoDisplayNone");
            screenRecorderRef.current.stop();
            setPaused(false);
            
            if (!screenRecorderRef.current.stream) return;
            
            stream.getTracks().forEach((track) => track.stop());
            audio.getTracks().forEach((track) => track.stop());

            setTimeout(() => {
                if (recChunks?.length > 0) {
                    recordedVideo = document.getElementById("recorded-video");
                    if (recordedVideo) {
                        recordedVideo?.classList?.remove("videoDisplayNone");
                        const blob = new Blob(recChunks, { type: "video/mp4" });

                        recordedVideo.src = URL.createObjectURL(blob);

                        recordedVideo.load();
                        recordedVideo.onloadeddata = function () {
                            recordedVideo.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                        };
                    }
                }
            }, 200);
            setCapturing(false);
        },
        [screenRecorderRef, screenCamRef, setCapturing]
    );

    const handlePauseCaptureClick = useCallback(
        (e) => {
            setPaused(true);
            screenRecorderRef.current.pause();
        },
        [screenRecorderRef, screenCamRef, setCapturing]
    );

    const handleResumeCaptureClick = useCallback(
        (e) => {
            setPaused(false);
            screenRecorderRef.current.resume();
        },
        [screenRecorderRef, screenCamRef, setCapturing]
    );

    const handleDownload = useCallback(async () => {
        if (recordedChunks.length) {
            setLoading(true);
            const blob = new Blob(recordedChunks, {
                type: "video/mp4",
            });
            const formData = new FormData();
            formData.append("video", blob, projectName);
            const urlServer = `${server.apiUrl}/project/${projectUrl}/downloadFilesToProject`;
            let id2 = {
                id: Math.random(),
                name: projectName,
                admin: false,
                loading: true,
                mime: "video/mp4",
                duration: null,
            };
            setTimeout(async () => {
                props.setresources({ id2, ...props.resources });
                setProjectName("");
                setRecordedChunks([]);
                recChunks = [];
                setLoading(false);
                setscreenCapture(false);

                try {
                    await axios({
                        method: "post",
                        url: urlServer,
                        data: formData,
                        maxContentLength: Infinity,
                        maxBodyLength: Infinity,
                        headers: {
                            "Content-Type": "multipart/form-data" + formData,
                        },
                    });
                    await props.loadData(true);
                } catch (error) {
                    setLoading(false);
                }
            }, 2000);
        }
    }, [recordedChunks, projectName]);

    const handleCancelCaptureClick = async () => {
        // we have to stop thee reecorrding when the user press cancel button while video is being recorded
        if (screenRecorderRef.current.state === "recording" || paused) {
            handleStopCaptureClick();
        }
        setRecordedChunks([]);
        recChunks = [];
        setTimeout(() => {
            // wait 1 sec
            setscreenCapture(false);
        }, 100);
    };

    return (
        <>
            {loading && <LoadingDialog heading={t("shared.savingFile")} subHeading={t("error.donotCloseBrowser")} />}

            {showSaveProjectDialog && !loading && (
                <SaveModal
                    inputValue={projectName}
                    setInputValue={setProjectName}
                    inputLabel={t("newProjectDialog.projectName")}
                    confirmHandler={handleDownload}
                    cancelHandler={() => setShowSaveProjectDialog(false)}
                    confirmIcon={mdiFileDownload}
                />
            )}

            {!showSaveProjectDialog && !loading && (
                <Modal isOpen={true} contentLabel="Screen Capture" className={"modalScreen"} overlayClassName={"overlay"}>
                    <h1
                        style={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 0 20px 0",
                        }}
                    >
                        {t("shared.screenCapture")}
                    </h1>
                    <div style={{ position: "relative" }}>
                        <video id="videoStream" className="videoDisplayNone" style={{ width: "100%", aspectRatio: "16/9" }}></video>
                        {paused && <h1 className="capture-video-paused">Paused</h1>}
                    </div>
                    <video id="recorded-video" className="videoDisplayNone" controls style={{ aspectRatio: "16/9", width: "100%" }}></video>

                    <div className="buttonWebCam">
                        {screenRecorderRef.current && screenRecorderRef.current.state === "inactive" && !capturing ? (
                            <button onClick={handleStartCaptureClick}> {t("shared.recordNew")}</button>
                        ) : (
                            <button
                                // style={{ backgroundColor: '#821717' }}
                                onClick={handleStopCaptureClick}
                            >
                                {t("shared.stop")}
                            </button>
                        )}

                        {capturing && !paused && <button onClick={handlePauseCaptureClick}>Pause</button>}
                        {paused && <button onClick={handleResumeCaptureClick}> Resume</button>}
                        {!capturing ? (
                            <button
                                onClick={handleCancelCaptureClick}
                                // style={{ backgroundColor: '#821717' }}
                            >
                                {t("shared.cancel")}
                            </button>
                        ) : (
                            <button onClick={handleCancelCaptureClick}> {t("shared.cancel")} </button>
                        )}

                        {recordedChunks.length > 0 && (
                            <button onClick={() => setShowSaveProjectDialog(true)} style={{ backgroundColor: "#0a7aad" }}>
                                {t("shared.save")}
                            </button>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
