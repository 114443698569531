import timeManager from "../../models/timeManager";
import TimelineModel from "../editor/TimelineModel";

export const manageImageXPosition = (imageX, canvasWidth = 640, mltWidth = 1280) => {
    return (imageX / canvasWidth) * mltWidth;
};
export const manageImageYPosition = (imageY, canvasHeight = 360, mltHeight = 720) => {
    return (imageY / canvasHeight) * mltHeight;
};

export const splitTime = (el) => {
    if (el) {
        const t1 = el.split(",")[1].split("")[0] * 1;
        const t2 = el.split(",")[1].split("")[1] * 1;
        
        const t = el
            .split(",")[0]
            .split(":")
            .reduce((acc, time) => 60 * acc + +time);
        if (t1 === 0) {
            return t;
        } else {
            if (t2) {
                return `${t}.${t1}${t2}`;
            } else {
                return `${t}.${t1}`;
            }
        }
    }
};

export const getVideoStartTime = function (item) {
    let inittime = new Date(1970, 0, 1);

    return (TimelineModel.dateFromString(item.item.in) - inittime) / 1000;
};
export const getVideoEndTime = function (item) {
    let inittime = new Date(1970, 0, 1);

    return (TimelineModel.dateFromString(item.item.out) - inittime) / 1000;
};
export const getAudioVolumeEndTime = function (item) {
    let inittime = new Date(1970, 0, 1);
    let audiostarttime = new Date(JSON.parse(JSON.stringify(item.start)));

    let volend = TimelineModel.dateFromString(item.volumeEnd);
    audiostarttime.setMilliseconds(volend - inittime);

    return audiostarttime;
};

export const getAudioStartTime = function (item) {
    let inittime = new Date(1970, 0, 1);

    return (TimelineModel.dateFromString(item.item.in) - inittime) / 1000;
};
export const getAudioVolumeStartTime = function (item) {
    let inittime = new Date(1970, 0, 1);
    let audiostarttime = new Date(JSON.parse(JSON.stringify(item.start)));

    let volstart = TimelineModel.dateFromString(item.volumeStart);
    audiostarttime.setMilliseconds(volstart - inittime);

    return audiostarttime;
};

export const drawImageAspectVideoFrame = (ctx, video) => {
    video.crossOrigin = "Anonymous";
    let canvas = ctx.canvas;
    let hRatio = canvas.width / video.videoWidth;
    let vRatio = canvas.height / video.videoHeight;
    let ratio = Math.min(hRatio, vRatio);
    let centerShift_x = (canvas.width - video.videoWidth * ratio) / 2;
    let centerShift_y = (canvas.height - video.videoHeight * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, centerShift_x, centerShift_y, video.videoWidth * ratio, video.videoHeight * ratio);
};

export const drawTransitionFrame = (ctx, service, invert, startTime, endTime, currentTime, video, videoSecond) => {
    let canvas = ctx.canvas;
    let hRatio = canvas.width / video.videoWidth;
    let vRatio = canvas.height / video.videoHeight;
    let ratio = Math.min(hRatio, vRatio);
    let centerShift_x = (canvas.width - video.videoWidth * ratio) / 2;
    let centerShift_y = (canvas.height - video.videoHeight * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    let hRatio1 = canvas.width / videoSecond.videoWidth;
    let vRatio1 = canvas.height / videoSecond.videoHeight;
    let ratio1 = Math.min(hRatio1, vRatio1);
    let centerShift_x1 = (canvas.width - videoSecond.videoWidth * ratio1) / 2;
    let centerShift_y1 = (canvas.height - videoSecond.videoHeight * ratio1) / 2;

    ctx.globalCompositeOperation = 'overlay';
    if (service === 'luma02.pgm') {
        
        const effectRatio = video.videoHeight / (endTime - startTime);
        const offsetHeight = effectRatio * (currentTime - startTime);
        const height = video.videoHeight - offsetHeight;

        // wrap Up down case
        if (invert == 0) {
            // UP case
            ctx.drawImage(video, 0, 0, video.videoWidth, height, centerShift_x, centerShift_y, video.videoWidth * ratio, height * ratio);
        } else if (invert == 1) {
            ctx.drawImage(video, 0, offsetHeight, video.videoWidth, height, centerShift_x, centerShift_y + offsetHeight * ratio, video.videoWidth * ratio, height * ratio);
        }
    } else if (service === 'luma01.pgm') {
        // wrap Left Right case
        const effectRatio = video.videoWidth / (endTime - startTime);
        const offsetWidth = effectRatio * (currentTime - startTime);
        const width = video.videoWidth - offsetWidth;

        if (invert == 0) {
            // Right
            ctx.drawImage(video, 0, 0, width, video.videoHeight, centerShift_x, centerShift_y, width * ratio, video.videoHeight * ratio);
        } else if (invert == 1) {
            ctx.drawImage(video, offsetWidth, 0, width, video.videoHeight, centerShift_x + offsetWidth * ratio, centerShift_y, width * ratio, video.videoHeight * ratio);
        }
    }

    ctx.drawImage(videoSecond, 0, 0, videoSecond.videoWidth, videoSecond.videoHeight, centerShift_x1, centerShift_y1, videoSecond.videoWidth * ratio1, videoSecond.videoHeight * ratio1);
};
// image track
export const videoTrack1Template = (item, src, callback) => {
    const div = document.createElement("div");

    div.className = "timeline-custom-div";
    const div2 = document.createElement("div");
    const div3 = document.createElement("div");
    const div4 = document.createElement("div");
    div4.style.marginLeft = "2px";
    div4.style.fontSize = "80%";

    div4.innerHTML = item.content;

    div2.classList.add("vis-delete");
    div3.classList.add("imageContainer");

    const image = document.createElement("img");
    image.src = src;

    image.alt = "Icareus Image";
    div3.append(image);
    div3.append(div4);
    div2.addEventListener("click", () => {
        callback(item);
    });
    div3.append(div2);
    div.append(div3);
    return div;
};

export const videoTrack1TemplateWithoutString = () => (item, callback) => {
    const div = document.createElement("div");
    const div2 = document.createElement("div");

    div2.classList.add("vis-delete");
    div.innerHTML = item.content;
    div2.addEventListener("click", () => {
        callback(item);
    });
    div.append(div2);
    return div;
};
// shape track
export const videoTrack2Template = (item, div) => {
    let i = item.id.split(":")[1];
    // taking shape bg color if any

    let shape_filter = Object.values(item?.item?.filters?.find((el) => el?.service === "mask_start"));
    let shape_filter_color = "#66ccd1";
    if (shape_filter?.length > 0) {
        shape_filter_color = Object.values(shape_filter[1].find((el) => el.name === "filter.color"))[1];
    }
    div.innerHTML = `Shape-${i * 1 + 1}`;
    div.style.background = shape_filter_color;
    div.style.borderRadius = "5px";
};

export const addFilterTemplate = (div, item, openSelectedFilter, itemCallBack) => {
    const div2 = document.createElement("div");

    div2.classList.add("vis-delete");
    const filterEffect = document.createElement("div");
    filterEffect.classList.add("vis-filterEffects");
    let dfr;
    item.item?.filters?.map((el) => {
        dfr = document.createElement("div");
        if (el?.service === "brightness") {
            dfr.classList.add("vis-brightness");
        } else if (el?.service === "lift_gamma_gain") {
            dfr.classList.add("vis-backgroundColor");
        } else if (el?.service === "fadeInBrightness") {
            dfr.classList.add("vis-fadeIn");
        } else if (el?.service === "fadeOutBrightness") {
            dfr.classList.add("vis-fadeOut");
        } else if (el?.service === "fadeInVolume") {
            dfr.classList.add("vis-volume");
        }
        dfr.addEventListener("click", () => {
            el?.service && openSelectedFilter(el?.service);
            return;
        });
        filterEffect.appendChild(dfr);
    });

    if (item?.item?.transitionTo && item?.item?.transitionFrom) {
        let dfr1 = document.createElement("div");
        // there is luma transtion as well
        dfr1.classList.add("vis-luma");
        filterEffect.appendChild(dfr1);
    }

    div.appendChild(filterEffect);

    div2.addEventListener("click", () => {
        itemCallBack(item);
    });
    div.append(div2);

    return div;
};

export const videoBlankTrack0Item = (track, index, item, items, start, end, inn, out, urlIn) => {
    let blankItem1 = {
        admin: "false",
        start: start ? start : TimelineModel.dateFromString(track.items[index - 1].end),
        end: end ? end : TimelineModel.dateFromString(item.start),
        in: inn ? inn : "00:00:00,000",
        out: out ? out : timeManager.subDuration(item.start, track.items[index - 1].end),
        filters: [],
        transitionTo: "",
        transitionFrom: "",
        blank: true,
        resource: "blank",
    };

    return items.push({
        id: `blankvidtrack${index}`,
        start: blankItem1?.start,
        end: blankItem1?.end,
        group: track.id,
        previousGroup: track.id,
        className: "blankvideo",
        item: blankItem1,
        url: `blankVideo/blank.mp4#t=${urlIn ? urlIn : 0},${splitTime(blankItem1.out)}`,
    });
};

export const audioBlankTrackItem = (track, index, item, items, start, end, inn, out, urlIn, urlOut) => {
    let blankItem1 = {
        admin: "false",
        musicLibraryFile: false,
        start: start ? start : TimelineModel.dateFromString(track.items[index - 1].end),
        end: end ? end : TimelineModel.dateFromString(item.start),
        in: inn ? inn : "00:00:00,000",
        out: out ? out : timeManager.subDuration(item.start, track.items[index - 1].end),
        filters: [],
        transitionTo: "",
        transitionFrom: "",
        blank: true,
        resource: "blank",
        volumeEnd: null,
        volumeLevel: null,
        volumeStart: null,
    };

    return items.push({
        id: track.id.includes("audiotrack0") ? `blankaud0track${index}` : `blankaud1track${index}`,
        start: blankItem1?.start,
        end: blankItem1?.end,
        group: track.id,
        previousGroup: track.id,
        className: "blankvideo",
        item: blankItem1,
        url: `blankVideo/blank.mp3#t=${urlIn ? urlIn : 0},${urlOut ? urlOut : splitTime(blankItem1.out)}`,
    });
};

export const videoTrack0Items = (item, items, otherParams) => {
    const { track, index, contentDimImage, className, content1, url, title } = otherParams;
    let brightnessFilterLevel,
        fadeInLevel = {},
        fadeOutLevel = {},
        backgroundColorLevel = {},
        videoVolumeLevel = {};

    if (item.filters.length > 0) {
        // brightness
        let initialValBrightness = item.filters.find((el) => el.service === "brightness");
        if (initialValBrightness) {
            brightnessFilterLevel = Object.values(initialValBrightness.options).find((el) => el.name === "level").value * 100;
        }

        // fadeIn brightness
        let fadeInBrightnessLevel = item.filters.find((el) => el.service === "fadeInBrightness");
        if (fadeInBrightnessLevel) {
            fadeInBrightnessLevel = Object.values(fadeInBrightnessLevel.options).find((el) => el.name === "level").value;
            fadeInLevel.startDuration = splitTime(fadeInBrightnessLevel.split("=")[0]) + splitTime(item.in);
            fadeInLevel.EndDuration = splitTime(fadeInBrightnessLevel.split("=")[1].split(";")[1]) + splitTime(item.in);
        }
        
        // fadeOutBrightness
        let fadeOutBrightnessLevel = item.filters.find((el) => el.service === "fadeOutBrightness");
        if (fadeOutBrightnessLevel) {
            fadeOutBrightnessLevel = Object.values(fadeOutBrightnessLevel.options).find((el) => el.name === "level").value;
            fadeOutLevel.startDuration = splitTime(fadeOutBrightnessLevel.split("=")[0]) * 1;
            fadeOutLevel.EndDuration = splitTime(fadeOutBrightnessLevel.split("=")[1].split(";")[1]) * 1;
        }
        
        // lift_gamma_gain
        let backgroundColorval = item.filters.find((el) => el.service === "lift_gamma_gain");
        if (backgroundColorval) {
            backgroundColorLevel.gain_r = Object.values(backgroundColorval.options).find((el) => el.name === "gain_r").value;
            backgroundColorLevel.gain_g = Object.values(backgroundColorval.options).find((el) => el.name === "gain_g").value;
            backgroundColorLevel.gain_b = Object.values(backgroundColorval.options).find((el) => el.name === "gain_b").value;
        }

        // volume video
        let vidVolume = item.filters.find((el) => el.service === "fadeInVolume");

        if (vidVolume) {
            videoVolumeLevel.volumeStart = (splitTime(Object.values(vidVolume.options).find((el) => el.name === "in").value) + splitTime(item?.in)) * 1;
            videoVolumeLevel.volumeEnd = (splitTime(Object.values(vidVolume.options).find((el) => el.name === "out").value) + splitTime(item?.in)) * 1;
            videoVolumeLevel.volumeLevel = Object.values(vidVolume.options).find((el) => el.name === "htmlSoundChangeValue")?.value * 1;
        }
    }

    let prevTransDurationTotal = 0;
    let prevTransDuration = 0;
    // check previous item have transition effect
    const previousItems = track.items.filter(x => x.start < item.start);
    previousItems.map((x, index) => {
        // from first video to previous video , total transDuration
        if (index !== previousItems.length - 1 && x.videoTransitions?.length > 0) {
            prevTransDurationTotal += parseFloat(splitTime(x.transitionTo)) - parseFloat(splitTime(x.transitionFrom));
        } else if (index === previousItems.length - 1 && x.videoTransitions?.length > 0) {
            prevTransDuration = parseFloat(splitTime(x.transitionTo)) - parseFloat(splitTime(x.transitionFrom));
        }
    });
    
    let start = TimelineModel.dateFromString(item.start);
    start.setSeconds(start.getSeconds() -  prevTransDurationTotal);

    let end = TimelineModel.dateFromString(item.end);
    end.setSeconds(end.getSeconds() -  prevTransDuration - prevTransDurationTotal);

    items.push({
        id: track.id + ":" + index,
        content: contentDimImage.innerHTML,
        start: start,
        end: end,
        group: track.id,
        previousGroup: track.id,

        className: className,
        contentID: content1,
        title,
        item,
        url,
        brightnessFilterLevel,
        fadeInLevel,
        fadeOutLevel,
        backgroundColorLevel,
        videoVolumeLevel,
    });
};

export const audioTrackItems = (item, items, otherParams, audioGraph) => {
    const { track, index, className, content1, url, title } = otherParams;
    let volumeStart = null;
    let volumeEnd = null;
    let volumeLevel = null;

    if (item.filters.length > 0) {
        const g = item.filters.find((el) => el.service === "fadeInVolume");

        if (g) {
            volumeStart = Object.values(g.options).find((el) => el.name === "in").value;
            volumeEnd = Object.values(g.options).find((el) => el.name === "out").value;
            volumeLevel = Object.values(g.options).find((el) => el.name === "htmlSoundChangeValue")?.value;
        }
    }
    items.push({
        id: track.id + ":" + index,
        content: audioGraph,
        start: TimelineModel.dateFromString(item.start),
        end: TimelineModel.dateFromString(item.end),
        group: track.id,
        previousGroup: track.id,

        className: className,
        contentID: content1,
        title,
        item,
        url,
        volumeEnd,
        volumeLevel,
        volumeStart,
    });
};

export const videoFilters = (item, brightnessFilterLevel, fadeInLevel, fadeOutLevel, backgroundColorLevel, videoVolumeLevel) => {
    // brightness
    let initialValBrightness = item.filters.find((el) => el.service === "brightness");
    if (initialValBrightness) {
        brightnessFilterLevel = Object.values(initialValBrightness.options).find((el) => el.name === "level").value * 100;
    }

    // fadeIn brightness
    let fadeInBrightnessLevel = item.filters.find((el) => el.service === "fadeInBrightness");
    if (fadeInBrightnessLevel) {
        fadeInBrightnessLevel = Object.values(fadeInBrightnessLevel.options).find((el) => el.name === "level").value;
        fadeInLevel.startDuration = splitTime(fadeInBrightnessLevel.split("=")[0]) + splitTime(item.in);
        fadeInLevel.EndDuration = splitTime(fadeInBrightnessLevel.split("=")[1].split(";")[1]) + splitTime(item.in);
    }

    // fadeOutBrightness
    let fadeOutBrightnessLevel = item.filters.find((el) => el.service === "fadeOutBrightness");
    if (fadeOutBrightnessLevel) {
        fadeOutBrightnessLevel = Object.values(fadeOutBrightnessLevel.options).find((el) => el.name === "level").value;
        fadeOutLevel.startDuration = splitTime(fadeOutBrightnessLevel.split("=")[0]) * 1;
        fadeOutLevel.EndDuration = splitTime(fadeOutBrightnessLevel.split("=")[1].split(";")[1]) * 1;
    }

    // lift_gamma_gain
    let backgroundColorval = item.filters.find((el) => el.service === "lift_gamma_gain");
    if (backgroundColorval) {
        backgroundColorLevel.gain_r = Object.values(backgroundColorval.options).find((el) => el.name === "gain_r").value;
        backgroundColorLevel.gain_g = Object.values(backgroundColorval.options).find((el) => el.name === "gain_g").value;
        backgroundColorLevel.gain_b = Object.values(backgroundColorval.options).find((el) => el.name === "gain_b").value;
    }

    // volume video
    let vidVolume = item.filters.find((el) => el.service === "fadeInVolume");
    if (vidVolume) {
        videoVolumeLevel.volumeStart = (splitTime(Object.values(vidVolume.options).find((el) => el.name === "in").value) + splitTime(item?.in)) * 1;
        videoVolumeLevel.volumeEnd = (splitTime(Object.values(vidVolume.options).find((el) => el.name === "out").value) + splitTime(item?.in)) * 1;
        videoVolumeLevel.volumeLevel = Object.values(vidVolume.options).find((el) => el.name === "htmlSoundChangeValue")?.value * 1;
    }
    return;
};

export const audioFilter = (volumeStart, volumeEnd, volumeLevel, g) => {
    volumeStart = Object.values(g.options).find((el) => el.name === "in").value;
    volumeEnd = Object.values(g.options).find((el) => el.name === "out").value;
    volumeLevel = Object.values(g.options).find((el) => el.name === "htmlSoundChangeValue")?.value;
};
