import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  drawTransitionFrame,
  drawImageAspectVideoFrame,
  getAudioStartTime,
  getAudioVolumeEndTime,
  getAudioVolumeStartTime,
  getVideoEndTime,
  getVideoStartTime,
  manageImageXPosition,
  manageImageYPosition,
  splitTime,
} from "../shared/utils";
import axios from "axios";
import { server } from "../../config";
import TimelineModel from "./TimelineModel";
import { useMyContext } from "../contexts/StateHolder";

import { TailSpin } from "react-loader-spinner";

import Input from "../shared/UI/Input/Input";

let requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame;

let opacityFadeOut = 1;
let opacity = 0;

let imageIndex;

let currentVideoTime;

let playPromise;
let playSecondPromise;

let playAudioPromise;
let playSecondAudioPromise;

let currentVRef;
let nextVRef;

const mime = require("mime-types");

let cropStartX = 10,
  cropStartY = 10,
  cropStartRight = 650,
  cropStartBottom = 370,
  resizerRadius = 10,
  cropWidth = 640,
  cropHeight = 360;
let draggingResizer = -1,
  mouseDown = false;
let startX, startY, offsetX, offsetY, mouseX, mouseY;
let canvasMask;

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}
const selectedCropList = [
  {
    label: "16:9",
    value: 1.777777777777777,
  },
  {
    label: "9:16",
    value: 9 / 16,
  },
  {
    label: "5:4",
    value: 5 / 4,
  },
  {
    label: "1:1",
    value: 1,
  },
  {
    label: "Customize ...",
    value: "custom",
  },
];
export default function Canvas(props) {
  const { t } = useTranslation();

  const {
    pauseCanvas,
    setPauseCanvas,
    timeline,
    projectUrl,
    time,
    setTime,
    timelineLoading,
  } = useMyContext();

  let textApp = {};
  const [volumeIcon, setVolumeIcon] = useState("volume_off");

  const forceUpdate = useForceUpdate();

  // imageItems
  const [imageItems, setImageItems] = useState(null);
  const [shapeItems, setShapeItems] = useState(null);
  const [ImageAssets, setImageAssets] = useState([]);
  const [shapeAssets, setShapeAssets] = useState([]);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [currentVideoTime2, setcurrentVideoTime2] = useState("");

  const [ratio, setRatio] = useState(
    props.customCropCoord ? "custom" : props.cropRatio
  );
  if (props.customCropCoord) {
    var cropCoord = JSON.parse(props.customCropCoord);
    cropStartX = cropCoord.cropStartX;
    cropStartY = cropCoord.cropStartY;
    cropWidth = cropCoord.cropWidth;
    cropHeight = cropCoord.cropHeight;
    cropStartBottom = cropStartY + cropHeight;
    cropStartRight = cropStartX + cropWidth;
  }

  const canvasRef = useRef(null);
  const canvasMaskRef = useRef(null);
  const canvasRef2 = useRef(null);
  const canvasRef3 = useRef(null);
  const videoRef = useRef(null);
  const videoRefSecond = useRef(null);
  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);

  const tplayref = useRef("paused");
  const a1playref = useRef("paused");

  const muteref = useRef(false);

  const currentvideoitem = useRef(-1);
  const [tplay, setTplay] = useState("paused");

  let c1, c2, c3;

  let imageSource = [];

  let ctx, ctx2, ctx3;
  let number = 0;
  let inittime = new Date(1970, 0, 1);

  useEffect(() => {
    if (!currentVRef) {
      currentVRef = videoRef;
    }
    if (!nextVRef) {
      nextVRef = videoRefSecond;
    }
    let v1 = currentVRef.current;
    c1 = canvasRef.current;
    c2 = canvasRef2.current;
    c3 = canvasRef3.current;

    v1 && v1.removeAttribute("controls");
    let controls = document.querySelector(".controls");
    if (
      localStorage.getItem("muteRef") &&
      localStorage.getItem("muteRef") === "true"
    ) {
      if (v1) {
        v1.volume = 0;
      }
      muteref.current = true;
      setVolumeIcon("volume_off");
    } else {
      if (v1) {
        v1.volume = 1;
      }
      muteref.current = false;
      setVolumeIcon("volume_up");
    }

    if (controls) {
      controls.style.visibility = "visible";
    }

    let allImageAssetInfo = [];
    let allShapeAssetInfo = [];

    // seperating property from video asset
    const imageClone = [...timeline.video[1].items];
    const shapeClone = [...timeline.video[2].items];

    // getting image data
    let r1 = {};
    imageClone.map((el) => {
      let currentElementResource = Object.values(props.resources).filter(
        (element) => element.id === el["resource"]
      );
      let imageTypeCheck;
      if (
        currentElementResource[0].mime?.includes("jpeg") ||
        currentElementResource[0].mime?.includes("png")
      ) {
        // we have to differentiate between jpg and jpeg
        let mimeValueArray = currentElementResource[0].name?.split(".").length;
        imageTypeCheck =
          currentElementResource[0].name?.split(".")[mimeValueArray - 1];
      }

      r1 = {};
      [
        r1["filters"],
        r1["resource"],
        r1["resource_s3"],

        r1["in"],
        r1["out"],
        r1["start"],
        r1["end"],
        r1["mime"],
        r1["startSecond"],
        r1["endSecond"],
        r1["admin"],
        r1["imageTypeCheck"],
      ] = [
        el["filters"],
        el["resource"],
        el["resource_s3"],
        el["in"],
        el["out"],
        el["start"],
        el["end"],
        Object.values(currentElementResource)[0].mime,
        splitTime(el["start"]),
        splitTime(el["end"]),
        el["admin"],
        imageTypeCheck,
      ];

      allImageAssetInfo = [...allImageAssetInfo, r1];
    });
    let imageSourceAsset = [...allImageAssetInfo];
    let d1 = {};
    if (imageSourceAsset.length > 0) {
      Object.values(imageSourceAsset).map((el) => {
        let mimeValue = mime.extension(el.mime);

        [
          d1["resource"],
          d1["source"],
          d1["start"],
          d1["end"],
          d1["in"],
          d1["out"],
          d1["admin"],
          d1["name"],
          d1["resource_s3"],
        ] = [
          el.resource,
          `${el.resource}.${el.imageTypeCheck ? el.imageTypeCheck : mimeValue}`,
          splitTime(el.start),
          splitTime(el.end),

          el.in,
          el.out,
          el.admin,
          el.imageTypeCheck,
          el.resource_s3,
        ];
        imageSource = [...imageSource, d1];
        d1 = {};
      });
    }
    setImageAssets(imageSource);

    // shape track info
    let r4 = {};
    shapeClone.map((el) => {
      r4 = {};
      [
        r4["filters"],
        r4["resource"],
        r4["in"],
        r4["out"],
        r4["start"],
        r4["end"],
        r4["startSecond"],
        r4["endSecond"],
        r4["admin"],
      ] = [
        el["filters"],
        el["resource"],

        el["in"],
        el["out"],
        el["start"],
        el["end"],
        splitTime(el["start"]),
        splitTime(el["end"]),
        el["admin"],
      ];

      allShapeAssetInfo = [...allShapeAssetInfo, r4];
    });
    let shapeSourceAsset = [...allShapeAssetInfo];

    setShapeAssets(shapeSourceAsset);

    let imageFilter = Object.values(allImageAssetInfo).map((item) => {
      return item.filters.map((el) => {
        return { [el.service]: el.options };
      });
    });

    // just taking rect1 from filter cause we just need this in canvas
    imageFilter = imageFilter.map((el) =>
      el[0]?.affine?.filter((el1) => el1.name === "transition.rect")
    );
    setImageItems(imageFilter);

    let shapeFilter = Object.values(allShapeAssetInfo).map((item) => {
      return item.filters.map((el) => {
        return { [el.service]: el.options };
      });
    });

    // just taking rect1 from filter cause we just need this in canvas
    shapeFilter = shapeFilter.map((el) =>
      el[0]?.mask_start?.filter(
        (el1) =>
          el1.name === "filter.imageX" ||
          el1.name === "filter.imageY" ||
          el1.name === "filter.imageWidth" ||
          el1.name === "filter.imageHeight" ||
          el1.name === "filter.color"
      )
    );

    setShapeItems(shapeFilter);
  }, [JSON.stringify(timeline.video), timeline.video]);

  useEffect(() => {
    if (pauseCanvas) {
      if (tplayref.current == "playing") {
        videoPause();
      }
    }
  }, [pauseCanvas, tplayref.current]);

  // change the canvas time while playing
  useEffect(() => {
    currentVideoTime = TimelineModel.dateToString(time);
    setcurrentVideoTime2(TimelineModel.dateToString(time));
  }, [time]);

  useEffect(() => {
    let inittime = new Date(1970, 0, 1);
    let seektime;
    try {
      seektime = new Date(
        JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
      );
    } catch {
      seektime = new Date(props.timelineTimeToCanvas2.split("#")[0]);
    }

    // check also if date is valid
    if (props.timelineref && seektime && !isNaN(seektime.getTime())) {
      if (seektime <= inittime) {
        inittime.setMilliseconds(inittime.getMilliseconds() + 1);
        seektime = inittime;
      }
      setTime(seektime);
      currentVideoTime = TimelineModel.dateToString(time);
      setcurrentVideoTime2(TimelineModel.dateToString(time));
      videoSeek(seektime);
      setTimeout(function () {
        drawVideoFrame();
        forceUpdate();
      }, 1000);
    }

    if (canvasMaskRef.current) {
      if (ratio != "custom") {
        cropMaskEffect(ratio);
      } else {
        canvasMask = canvasMaskRef.current;
        drawCustomizeMaskRect();
        canvasMask.addEventListener("mousedown", handleMouseDown);
        canvasMask.addEventListener("mousemove", handleMouseMove);
        canvasMask.addEventListener("mouseup", handleMouseUp);
        canvasMask.addEventListener("mouseout", handleMouseOut);
        return () => {
          canvasMask.removeEventListener("mousedown", handleMouseDown);
          canvasMask.removeEventListener("mousemove", handleMouseMove);
          canvasMask.removeEventListener("mouseup", handleMouseUp);
          canvasMask.removeEventListener("mouseout", handleMouseOut);
        };
      }
    }
  }, [props.timelineTimeToCanvas2, timelineLoading]);

  const getDuration = () => {
    let realDuration = parseFloat(splitTime(props.duration));
    let imageDuration = 0;
    let txtDuration = 0;
    if (props.timelineref && props.timelineref.itemsData) {
      let allitems = props.timelineref.itemsData.get();
      let allvideos = allitems
        .filter(
          (el) =>
            el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
        )
        .slice()
        .sort((a, b) => a.start - b.start);

      let allImages = allitems
        .filter((el) => el.id.includes("videotrack1"))
        .slice()
        .sort((a, b) => a.start - b.start);
      if (allImages.length > 0) {
        imageDuration = parseFloat(
          splitTime(allImages[allImages.length - 1].item.end)
        );
      }
      let allTexts = allitems
        .filter((el) => el.id.includes("videotrack2"))
        .slice()
        .sort((a, b) => a.start - b.start);
      if (allTexts.length > 0) {
        txtDuration = parseFloat(
          splitTime(allTexts[allTexts.length - 1].item.end)
        );
      }

      // if there is transition effect between videos, substract the transition time from duration
      for (let i = 0; i < allvideos.length; i++) {
        if (
          allvideos[i].item.videoTransitions &&
          allvideos[i].item.videoTransitions.length > 0
        ) {
          const startTime = parseFloat(
            splitTime(allvideos[i].item.transitionFrom)
          );
          const endTime = parseFloat(splitTime(allvideos[i].item.transitionTo));
          realDuration -= endTime - startTime;
        }
      }
    }
    const maxDuration =
      txtDuration > imageDuration ? txtDuration : imageDuration;
    if (realDuration < maxDuration) {
      realDuration = maxDuration;
    }
    var t = new Date(1970, 0, 1); // Epoch
    t.setMilliseconds(realDuration * 1000);
    return TimelineModel.dateToString(t);
  };
  const videoPlay = function () {
    // this function changes the playing state and then calls the animation loop
    setPauseCanvas(false);
    setTplay("playing");
    tplayref.current = "playing";
    requestAnimationFrame(runtimeline);
  };

  // used only for seek and first time video play
  const videoLoadandPlay = function (item) {
    opacityFadeOut = 1;
    opacity = 0;
    let d = currentVRef.current;
    let vstarttime = getVideoStartTime(item);
    d.src = item.url;
    d.currentTime = vstarttime;
    d.load();
    playPromise = d.play();
    setTplay("playing");
    tplayref.current = "playing";

    requestAnimationFrame(runtimeline);
  };

  const videoPause = function () {
    let d = currentVRef.current;

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          d.pause();
          playPromise = undefined;
        })
        .catch((error) => {
          console.log("DEBUG error in videoPause", error);
        });
    }

    if (playSecondPromise !== undefined) {
      playSecondPromise
        .then((_) => {
          const a = nextVRef.current;
          a.pause();
          playSecondPromise = undefined;
        })
        .catch((error) => {
          console.log("DEBUG error in videoPause", error);
        });
    }

    setTplay("paused");
    tplayref.current = "paused";
    audio1Pause();
    audio2Pause();
    return;
  };

  const audio1Pause = () => {
    let aud1 = audioRef1.current;
    if (playAudioPromise !== undefined) {
      playAudioPromise
        .then((_) => {
          aud1?.pause();
          playAudioPromise = undefined;
        })
        .catch((error) => {
          console.log("DEBUG error in audioPause", error);
        });
    }
  };

  const audio2Pause = () => {
    let aud2 = audioRef2.current;
    if (playSecondAudioPromise !== undefined) {
      playSecondAudioPromise
        .then((_) => {
          aud2?.pause();
          playSecondAudioPromise = undefined;
        })
        .catch((error) => {
          console.log("DEBUG error in audioPause", error);
        });
    }
  };

  const videoRewind = function (seconds) {
    videoPause();
    let seektime = new Date(
      JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
    );
    seektime.setMilliseconds(seektime.getMilliseconds() - 300);
    videoSeek(seektime);
  };

  const videoForward = function (seconds) {
    videoPause();
    let seektime = new Date(
      JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
    );
    seektime.setMilliseconds(seektime.getMilliseconds() + 300);
    videoSeek(seektime);
  };

  const getfirstvideo = function () {
    let allitems = props.timelineref.itemsData.get();
    let allvideos = allitems
      .filter(
        (el) => el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
      )
      .slice()
      .sort((a, b) => a.start - b.start);

    let firstitem = allvideos[0];
    return firstitem;
  };

  const getlastvideo = function () {
    let allitems = props.timelineref.itemsData.get();
    let allvideos = allitems
      .filter(
        (el) => el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
      )
      .slice()
      .sort((a, b) => a.start - b.start);

    let lastitem = allvideos[allvideos.length - 1];
    return lastitem;
  };

  const getcurrentvideo = function (timeobj) {
    let currentvideo = false;
    let onitems = props.timelineref.getItemsAtCurrentTime(timeobj);
    onitems = onitems.filter(
      (el) => el.includes("videotrack0") || el.includes("blankvidtrack")
    );

    if (onitems.length === 0) return;

    let allitems = props.timelineref.itemsData.get();

    let allvideos = allitems
      .filter(
        (el) => el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
      )
      .sort((a, b) => a.start - b.start);

    let isvideo = allvideos.find((el) => el.id == onitems[0]);
    currentvideo = isvideo;

    if (!currentvideo) {
      if (timeobj.getTime() == getfirstvideo().start.getTime()) {
        currentvideo = getfirstvideo();
      } else if (timeobj.getTime() == getlastvideo().end.getTime()) {
        currentvideo = getlastvideo();
      } else {
        currentvideo = getfirstvideo();
      }
    }

    return currentvideo;
  };

  const getnextvideo = function () {
    let allitems = props.timelineref.itemsData.get();
    let allvideos = allitems
      .filter(
        (el) => el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
      )
      .slice()
      .sort((a, b) => a.start - b.start);

    let nextvideo = currentvideoitem.current;

    for (let i = 0; i < allvideos.length; i++) {
      if (
        allvideos[i].id == currentvideoitem.current.id &&
        i + 1 < allvideos.length
      ) {
        nextvideo = allvideos[i + 1];
        break;
      }
    }
    return nextvideo;
  };

  const getPrevVideo = function () {
    let allitems = props.timelineref.itemsData.get();
    let allvideos = allitems
      .filter(
        (el) => el.id.includes("videotrack0") || el.id.includes("blankvidtrack")
      )
      .slice()
      .sort((a, b) => a.start - b.start);

    let prevVideo;
    for (let i = 0; i < allvideos.length; i++) {
      if (allvideos[i].id == currentvideoitem.current.id && i - 1 >= 0) {
        prevVideo = allvideos[i - 1];
        break;
      }
    }
    return prevVideo;
  };

  const playnextvideo = function () {
    // if no video set currently take the first video
    if (currentvideoitem.current == -1) {
      let firstitem = getfirstvideo();
      currentvideoitem.current = firstitem;
      if (!currentVRef || !currentVRef.current) {
        currentVRef = videoRef;
      }
      if (!nextVRef || !nextVRef.current) {
        nextVRef = videoRefSecond;
      }
      videoLoadandPlay(currentvideoitem.current);
      let nextitem = getnextvideo();
      if (nextitem != firstitem) {
        loadSecondvideo(nextitem);
      }
    } else {
      // move to next video
      let nextitem = getnextvideo();
      if (nextitem != null) {
        currentvideoitem.current = nextitem;
        let tempVRef = currentVRef;
        currentVRef = nextVRef;
        nextVRef = tempVRef;
        videoPlay();
        let nextitemAfter = getnextvideo();
        if (nextitemAfter != nextitem) {
          loadSecondvideo(nextitemAfter);
        }
      }
    }
  };

  const loadvideo = function (item) {
    currentvideoitem.current = item;

    currentVRef = videoRef;
    nextVRef = videoRefSecond;
    let d = currentVRef.current;
    let nextVideoPlayer = nextVRef.current;

    let vstarttime = 0;
    if (item.item) {
      let inittime = new Date(1970, 0, 1);
      vstarttime =
        (TimelineModel.dateFromString(item.item.in) - inittime) / 1000;
    }
    if (nextVideoPlayer) {
      nextVideoPlayer.src = "";
      nextVideoPlayer.load();
    }
    if (d) {
      d.src = item.url;
      d.currentTime = vstarttime;
      d.load();
    }
    const nextVideoItem = getnextvideo();
    if (nextVideoItem != item) {
      loadSecondvideo(nextVideoItem);
    } else {
      // TODO: if there is no next video
    }
    drawVideoFrame();
    return currentvideoitem.current;
  };

  const loadSecondvideo = function (item) {
    let d = nextVRef.current;
    let vstarttime = 0;
    if (item.item) {
      let inittime = new Date(1970, 0, 1);
      vstarttime =
        (TimelineModel.dateFromString(item.item.in) - inittime) / 1000;
    }
    if (d) {
      d.src = item.url;
      d.currentTime = vstarttime;
      console.log("DEBUG loadSecondvideo loaded ========");
      d.load();
    }
  };

  const getAccurateAudioTime = function (currenttim, audioitem) {
    let itemstarttime = audioitem.start;
    let astarttime = getAudioStartTime(audioitem);
    let elapsedtime = (currenttim - itemstarttime) / 1000;
    let ctime = astarttime + elapsedtime;
    return ctime;
  };

  const audioseek = function (seektime) {
    let audios = getCurrentAudios(seektime);
    let audioitem1 = audios.audioitem1;
    let audioitem2 = audios.audioitem2;

    if (aud1) {
      if (audioitem1 != -1 && audioitem1.className != "blankaudio") {
        let ctime = getAccurateAudioTime(seektime, audioitem1);

        // if different audio load and play
        if (aud1.src.indexOf(audioitem1.url) == -1) {
          aud1.src = audioitem1.url;
          aud1.currentTime = ctime;
          aud1.load();
        } else {
          // same audio just play at correct time
          aud1.currentTime = ctime;
        }
      } else {
        aud1.removeAttribute("src");
        audio1Pause();
      }
    }

    if (aud2) {
      if (audioitem2 != -1 && audioitem2.className != "blankaudio") {
        let ctime = getAccurateAudioTime(seektime, audioitem2);

        // if different audio load and play
        if (aud2.src.indexOf(audioitem2.url) == -1) {
          aud2.src = audioitem2.url;
          aud2.currentTime = ctime;
          aud2.load();
        } else {
          // same audio just play at correct time
          aud2.currentTime = ctime;
        }
      } else {
        aud2.removeAttribute("src");
        audio2Pause();
      }
    }
  };

  const getPrevTransitionDuration = () => {
    // check previous video have transition effect
    const prevVideo = getPrevVideo();
    let transDuration = 0;
    if (
      prevVideo &&
      prevVideo.item.videoTransitions &&
      prevVideo.item.videoTransitions.length > 0
    ) {
      const startTime = parseFloat(splitTime(prevVideo.item.transitionFrom));
      const endTime = parseFloat(splitTime(prevVideo.item.transitionTo));
      transDuration = endTime - startTime;
    }
    return transDuration;
  };

  const videoSeek = function (seektime) {
    console.log("DEBUG videoSeek", seektime.toString());
    let seekedvideoitem;
    let firstvideo = getfirstvideo();
    let lastvideo = getlastvideo();

    audioseek(seektime);
    if (!lastvideo) {
      return;
    }

    if (seektime <= firstvideo.start) {
      seekedvideoitem = loadvideo(firstvideo);
      let vstarttime = getVideoStartTime(seekedvideoitem);
      const d = currentVRef.current;
      if (d) {
        d.currentTime = vstarttime;
      }
    } else {
      seekedvideoitem = getcurrentvideo(seektime);
      if (seekedvideoitem) {
        // when jumped to random video
        if (currentvideoitem.current.id != seekedvideoitem.id) {
          currentvideoitem.current = seekedvideoitem;
          setIsVideoLoading(true);
          loadvideo(seekedvideoitem);
        } else {
          const nextVideoItem = getnextvideo();
          if (nextVideoItem) {
            loadSecondvideo(nextVideoItem);
          }
        }

        const d = currentVRef.current;
        // get previous total transition duration
        const prevTransDuration = getPrevTransitionDuration();
        let currentseconds = (seektime - seekedvideoitem.start) / 1000;
        let vstarttime = getVideoStartTime(seekedvideoitem);
        if (d) {
          // set currenttime with previous transition duration
          d.currentTime = vstarttime + currentseconds + prevTransDuration;
        }

        if (
          seekedvideoitem.item.videoTransitions &&
          seekedvideoitem.item.videoTransitions.length > 0
        ) {
          const startTime = TimelineModel.dateFromString(
            seekedvideoitem.item.transitionFrom
          );
          const endTime = TimelineModel.dateFromString(
            seekedvideoitem.item.transitionTo
          );
          // set currenttime of second video player with transition duration
          if (startTime <= seektime && seektime <= endTime) {
            const videoSecond = nextVRef.current;
            videoSecond.currentTime = (seektime - startTime) / 1000;
          }
        }
      }
    }

    setmarkerposition(seektime);
    drawVideoFrame();
    setTime(seektime);
    forceUpdate();

    setTimeout(function () {
      if (isCanvasBlank(c1)) {
        drawVideoFrame();
        forceUpdate();
      }
    }, 500);
  };

  // returns true if every pixel's uint32 representation is 0 (or "blank")
  function isCanvasBlank(canvas) {
    if (!canvas) return null;
    try {
      const context = canvas.getContext("2d", { willReadFrequently: true });
      if (context && canvas) {
        context.crossOrigin = "Anonymous";
        let frame = context.getImageData(0, 0, canvas.width, canvas.height);
        if (frame) {
          frame.crossOrigin = "Anonymous";
          const pixelBuffer = new Uint32Array(frame.data.buffer);
          return !pixelBuffer.some((color) => color !== 0);
        }
      }
      return true;
    } catch (err) {
      console.log(err);
      return true;
    }
  }

  const fix_dpi = () => {
    c1 = canvasRef.current;
    c2 = canvasRef2.current;
    c3 = canvasRef3.current;
    let dpi = window.devicePixelRatio;
    //create a style object that returns width and height
    let style = {
      height() {
        return +getComputedStyle(c1)?.getPropertyValue("height").slice(0, -2);
      },
      width() {
        return +getComputedStyle(c1)?.getPropertyValue("width").slice(0, -2);
      },
    };
    let style2 = {
      height() {
        return +getComputedStyle(c2)?.getPropertyValue("height").slice(0, -2);
      },
      width() {
        return +getComputedStyle(c2)?.getPropertyValue("width").slice(0, -2);
      },
    };
    let style3 = {
      height() {
        return +getComputedStyle(c3)?.getPropertyValue("height").slice(0, -2);
      },
      width() {
        return +getComputedStyle(c3)?.getPropertyValue("width").slice(0, -2);
      },
    };
    //set the correct attributes for a crystal clear image!
    c1?.setAttribute("width", style.width() * dpi);
    c1?.setAttribute("height", style.height() * dpi);
    c2?.setAttribute("width", style2.width() * dpi);
    c2?.setAttribute("height", style2.height() * dpi);
    c3?.setAttribute("width", style3.width() * dpi);
    c3?.setAttribute("height", style3.height() * dpi);
  };

  let baseImage = new Image();

  const drawShapeAndImage = () => {
    const drawImageValue = async () => {
      let rect = (imageItems[imageIndex][0]?.value).split(" ");
      let X = manageImageXPosition(rect[0], 1280, c1.width);
      let Y = manageImageYPosition(rect[1], 720, c1.height);
      let imgWid = manageImageXPosition(rect[2], 1280, c1.width);
      let imgHei = manageImageYPosition(rect[3], 720, c1.height);
      rect?.length > 0
        ? ctx2.drawImage(baseImage, X, Y, imgWid, imgHei)
        : ctx2.drawImage(baseImage, 0, 0, c2.width, c2.height);
    };
    const baseImageHelper = (source, item) => {
      let adminImageSource = ImageAssets.find(
        (el) => el.source === source
      ).admin;

      let src;
      if (source.includes("jpeg")) {
        src = `${item.resource}.${item.name}`;
      } else {
        src = source;
      }

      baseImage.src = item.resource_s3
        ? item.resource_s3
        : adminImageSource
        ? `WORKER/AdminProjectFiles/${src}`
        : `WORKER/${projectUrl}/${src}`;

      baseImage.addEventListener("load", drawImageValue, false);
      drawImageValue();
    };

    let draw2CurrentTime = splitTime(currentVideoTime) * 1;
    ctx2.clearRect(0, 0, c1.width, c1.height);

    ImageAssets.map((el, i) => {
      if (el.start * 1 <= draw2CurrentTime && el.end > draw2CurrentTime) {
        imageIndex = i;
        baseImageHelper(el.source, el);
      }
    });
    shapeAssets.map((el, i) => {
      if (
        el.startSecond * 1 <= draw2CurrentTime &&
        el.endSecond * 1 > draw2CurrentTime
      ) {
        let shapePoistion = shapeItems[i];
        ctx2.fillStyle = shapePoistion[4].value;
        ctx2.fillRect(
          manageImageXPosition(shapePoistion[0].value, 640, c2.width),
          manageImageYPosition(shapePoistion[1].value, 360, c2.height),
          manageImageXPosition(shapePoistion[2].value, 640, c2.width),
          manageImageYPosition(shapePoistion[3].value, 360, c2.height)
        );
        ctx2.fill();
      }
    });
  };

  const drawText = () => {
    const x = c3.width;
    const y = c3.height;
    let canvasx, canvasy, textAlign;
    const type = ctx3;
    ctx3.clearRect(0, 0, x, y);

    Object.values(props.allAssetFilter).forEach((el) => {
      Object.values(el).forEach((el1) => {
        textApp[el1["name"]] = el1["value"];
      });
      // text in and out
      let textIn = textApp.in && splitTime(textApp.in) * 1;

      let textOut = textApp.out && splitTime(textApp.out) * 1;
      const geometrySplit = textApp.geometry.split("%");
      const yPositionSplit = geometrySplit[1].split("/")[1];

      const xPosition = geometrySplit[0] * 1;
      const yPosition = yPositionSplit * 1 + 10;
      const fgcolour = textApp.fgcolour;
      const argument = textApp.argument;
      const bgcolour = textApp.bgcolour;
      const size = textApp.size;

      const style = textApp.style;
      const weight = textApp.weight;
      const familyName = textApp.family;

      canvasx = Math.floor(x * (xPosition / 100));
      canvasy = Math.floor(y * (yPosition / 100));
      if (canvasx === 0) canvasx = 40;
      if (canvasy === 0) canvasy = 40;
      let messages = new Array();
      messages[messages.length] = {
        time: textIn,
        time2: textOut,
        message: argument,
        x: canvasx,
        y: canvasy,
        textAlign: textAlign,
      };

      let draw2CurrentTime = splitTime(currentVideoTime) * 1;
      for (let i = 0; i < messages.length; i++) {
        let tempMessage = messages[i];
        if (textIn <= draw2CurrentTime && textOut >= draw2CurrentTime) {
          type.save();
          type.font = ` ${weight} ${style} ${size}px ${familyName}`;
          type.textBaseline = "top";
          type.fillStyle = bgcolour;
          let width = type.measureText(tempMessage.message).width;
          type.fillRect(
            tempMessage.x,
            tempMessage.y,
            width,
            parseInt(size, 10)
          );
          type.textAlign = tempMessage.textAlign;
          type.fillStyle = fgcolour;
          type.fillText(tempMessage.message, tempMessage.x, tempMessage.y);
          type.restore();
        }
      }
    });
  };

  const setmarkerposition = function (timeobj) {
    setTime(timeobj);
    let timeline = props.timelineref;
    let timetext =
      timeobj.getMinutes() +
      " : " +
      timeobj.getSeconds() +
      " : " +
      timeobj.getMilliseconds();

    props.changedVideoSeek(timeobj);
    timeline.setCustomTime(timeobj);
    timeline.setCustomTimeMarker(timetext);

    checkplayifanyaudio(timeobj);
  };

  // for fadeIn and fadeOut effects
  const drawVideoEffect = (item, video) => {
    if (item.brightnessFilterLevel) {
      ctx.filter = `brightness(${Number(item.brightnessFilterLevel)}%)`;
    }

    if (item.fadeInLevel?.EndDuration) {
      if (
        video.currentTime >= item.fadeInLevel?.startDuration &&
        video.currentTime <= item.fadeInLevel?.EndDuration
      ) {
        const fadeInRate =
          (1 - 0.04) /
          (item.fadeInLevel?.EndDuration - item.fadeInLevel?.startDuration);
        ctx.globalAlpha = opacity;
        opacity =
          0.1 +
          fadeInRate * (video.currentTime - item.fadeInLevel?.startDuration);
      }
    }
    if (item.fadeOutLevel?.startDuration) {
      if (
        video.currentTime >= item.fadeOutLevel?.startDuration &&
        video.currentTime <= item.fadeOutLevel?.EndDuration
      ) {
        const fadeOutRate =
          (1 - 0.04) /
          (item.fadeOutLevel?.EndDuration - item.fadeOutLevel?.startDuration);
        ctx.globalAlpha = opacityFadeOut;
        opacityFadeOut =
          1 -
          fadeOutRate * (video.currentTime - item.fadeOutLevel?.startDuration);
      } else {
        opacityFadeOut = 1;
      }
    }
    if (muteref.current) {
      video.volume = 0;
    } else {
      if (item.videoVolumeLevel?.volumeEnd) {
        if (
          video.currentTime >= item.videoVolumeLevel?.volumeStart &&
          video.currentTime <= item.videoVolumeLevel?.volumeEnd
        ) {
          video.volume = item.videoVolumeLevel?.volumeLevel;
        } else {
          video.volume = 1;
        }
      } else {
        video.volume = 1;
      }
    }
    if (item.backgroundColorLevel && item.backgroundColorLevel?.gain_r) {
      const seektime = new Date(
        JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
      );
      if (item.item.videoTransitions && item.item.videoTransitions.length > 0) {
        const startTime = TimelineModel.dateFromString(
          item.item.transitionFrom
        );
        const endTime = TimelineModel.dateFromString(item.item.transitionTo);
        if (startTime <= seektime && seektime <= endTime) {
          const videoSecond = nextVRef.current;
          drawTransitionFrame(
            ctx,
            item.item.videoTransitions[0].options[0].resource,
            item.item.videoTransitions[0].options[0].invert,
            startTime,
            endTime,
            seektime,
            video,
            videoSecond
          );
        } else {
          drawImageAspectVideoFrame(ctx, video);
        }
      } else {
        drawImageAspectVideoFrame(ctx, video);
      }

      let frame = ctx.getImageData(0, 0, c1.width, c1.height);
      frame.crossOrigin = "Anonymous";
      let l = frame.data.length / 4;

      for (let i = 0; i < l; i++) {
        frame.data[i * 4 + 0] =
          frame.data[i * 4 + 0] * (item.backgroundColorLevel.gain_r * 1);
        frame.data[i * 4 + 1] =
          frame.data[i * 4 + 1] * (item.backgroundColorLevel.gain_g * 1);
        frame.data[i * 4 + 2] =
          frame.data[i * 4 + 2] * (item.backgroundColorLevel.gain_b * 1);
      }
      ctx.putImageData(frame, 0, 0);
    } else {
      const seektime = new Date(
        JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
      );
      if (item.item.videoTransitions && item.item.videoTransitions.length > 0) {
        const startTime = TimelineModel.dateFromString(
          item.item.transitionFrom
        );
        const endTime = TimelineModel.dateFromString(item.item.transitionTo);
        if (startTime <= seektime && seektime <= endTime) {
          const videoSecond = nextVRef.current;
          drawTransitionFrame(
            ctx,
            item.item.videoTransitions[0].options[0].resource,
            item.item.videoTransitions[0].options[0].invert,
            startTime,
            endTime,
            seektime,
            video,
            videoSecond
          );
        } else {
          drawImageAspectVideoFrame(ctx, video);
        }
      } else {
        drawImageAspectVideoFrame(ctx, video);
      }
    }
  };

  const draw = () => {
    let video = currentVRef.current;
    if (currentvideoitem?.current != -1) {
      if (
        currentvideoitem.current?.item?.filters.length === 0 &&
        currentvideoitem.current?.item?.videoTransitions?.length === 0
      ) {
        drawImageAspectVideoFrame(ctx, video);

        if (muteref.current) {
          video.volume = 0;
        } else {
          video.volume = 1;
        }
      } else {
        drawVideoEffect(currentvideoitem?.current, video);
      }
    }
  };

  const drawVideoFrame = function (runOnce) {
    fix_dpi();

    if (c1?.getContext && c2?.getContext && c3?.getContext) {
      ctx = c1?.getContext("2d");
      ctx2 = c2?.getContext("2d");
      ctx3 = c3?.getContext("2d");
      ctx.willReadFrequently = true;
      ctx2.willReadFrequently = true;
      ctx3.willReadFrequently = true;

      draw();
      drawShapeAndImage();
      drawText();
      return;
    }
  };

  let aud1 = audioRef1.current;
  let aud2 = audioRef2.current;
  if (!currentVRef) {
    currentVRef = videoRef;
  }
  let video = currentVRef.current;

  if (video) {
    video.addEventListener("playing", () => {
      setIsVideoLoading(false);
      if (aud1.src != "") {
        playAudioPromise = aud1.play();
      }
      if (aud2.src != "") {
        playSecondAudioPromise = aud2.play();
      }
    });

    video.addEventListener("loadstart", () => {
      audio1Pause();
      audio2Pause();
    });

    video.addEventListener("loadeddata", () => {
      setIsVideoLoading(false);
    });

    // buffering lack data
    video.addEventListener("waiting", () => {
      console.log("DEBUG", "video event waiting");
      setIsVideoLoading(true);
    });

    // Hide loading indicator when the video is ready to play
    video.addEventListener("canplaythrough", () => {
      setIsVideoLoading(false);
    });
  }

  const getCurrentAudios = function (currenttim) {
    let audioitem1 = -1;
    let audioitem2 = -1;

    let allitems = props.timelineref.itemsData.get();
    allitems = allitems.sort((a, b) => a.start - b.start);

    let currentitems = props.timelineref.getItemsAtCurrentTime(currenttim);

    for (let i = 0; i < currentitems.length; i++) {
      for (let j = 0; j < allitems.length; j++) {
        if (currentitems[i] == allitems[j].id) {
          let item = allitems[j];

          if (item.group == "audiotrack0") {
            audioitem1 = item;
          }

          if (item.group == "audiotrack1") {
            audioitem2 = item;
          }
        }
      }
    }

    return {
      audioitem1: audioitem1,
      audioitem2: audioitem2,
    };
  };

  const loadandplayaudio = function (audref, audioitem, type) {
    let astarttime = getAudioStartTime(audioitem);

    // if different audio load and play
    if (audref?.src?.indexOf(audioitem?.url) == -1) {
      audref.src = audioitem.url;
      audref.currentTime = astarttime;
      audref.load();
      if (type === 1) {
        playAudioPromise = audref.play();
      } else {
        playSecondAudioPromise = audref.play();
      }
    }
    a1playref.current = "playing";
  };

  const checkplayifanyaudio = function (currenttim) {
    let audios = getCurrentAudios(currenttim);

    let audioitem1 = audios.audioitem1;
    let audioitem2 = audios.audioitem2;

    // timeline in playing state
    if (audioitem1 != -1 && audioitem1?.className != "blankaudio") {
      loadandplayaudio(aud1, audioitem1, 1);
    } else {
      aud1?.removeAttribute("src");
      audio1Pause();
    }

    if (audioitem2 != -1 && audioitem2.className != "blankaudio") {
      loadandplayaudio(aud2, audioitem2, 2);
    } else {
      aud2?.removeAttribute("src");
      audio2Pause();
    }

    if (muteref.current) {
      if (aud1) aud1.volume = 0;
      if (aud2) aud2.volume = 0;
      if (video) video.volume = 0;
    } else {
      if (
        audioitem1?.volumeStart != null &&
        audioitem1?.volumeEnd != null &&
        audioitem1?.volumeLevel != null
      ) {
        let volstarttime = getAudioVolumeStartTime(audioitem1);
        let volendtime = getAudioVolumeEndTime(audioitem1);

        if (currenttim > volstarttime && currenttim < volendtime) {
          let volumelevel = parseFloat(audioitem1.volumeLevel);
          if (aud1 && aud1.volume != volumelevel) {
            aud1.volume = volumelevel;
          }
        } else {
          if (aud1) aud1.volume = 1;
        }
      } else {
        if (aud1) aud1.volume = 1;
      }

      if (
        audioitem2.volumeStart != null &&
        audioitem2.volumeEnd != null &&
        audioitem2.volumeLevel != null
      ) {
        let volstarttime = getAudioVolumeStartTime(audioitem2);
        let volendtime = getAudioVolumeEndTime(audioitem2);

        if (currenttim > volstarttime && currenttim < volendtime) {
          var volumelevel = parseFloat(audioitem2.volumeLevel);
          if (aud2 && aud2.volume != volumelevel) {
            aud2.volume = volumelevel;
          }
        } else {
          if (aud2) aud2.volume = 1;
        }
      } else {
        if (aud2) aud2.volume = 1;
      }
    }
  };

  const runcursor = function () {
    if (!currentVRef) {
      currentVRef = videoRef;
    }
    if (!nextVRef) {
      nextVRef = videoRefSecond;
    }
    let d = currentVRef.current;

    let timelinecurrentposition = new Date(
      JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
    );
    if (timelinecurrentposition.getTime() == inittime.getTime()) {
      timelinecurrentposition.setMilliseconds(
        timelinecurrentposition.getMilliseconds() + 1
      );
    }

    let vstarttime;
    let vendtime;
    let videoduration = TimelineModel.dateFromString(getDuration());
    if (currentvideoitem.current != -1) {
      vstarttime = getVideoStartTime(currentvideoitem?.current);
      vendtime = getVideoEndTime(currentvideoitem?.current);
    }

    // if no video is set play first video , initially it will load this
    if (currentvideoitem.current == -1) {
      playnextvideo();
    }
    // if there is video to play just play/resume it
    else if (
      currentvideoitem.current != -1 &&
      d?.currentTime >= vstarttime &&
      Math.round((d?.currentTime + 0.05) * 100) / 100 < vendtime
    ) {
      let itemstarttime = new Date(
        JSON.parse(JSON.stringify(currentvideoitem.current.start))
      );
      let elapsedtime =
        d?.currentTime - vstarttime - getPrevTransitionDuration();
      let elapseds = Math.trunc(elapsedtime);
      let elapsedms = (elapsedtime - Math.floor(elapsedtime)) * 1000;

      itemstarttime.setSeconds(itemstarttime.getSeconds() + elapseds);
      itemstarttime.setMilliseconds(
        itemstarttime.getMilliseconds() + elapsedms + 1
      );

      setmarkerposition(itemstarttime);

      if (!d?.src) {
        d.src = currentvideoitem.current?.url;
      }
      playPromise = d.play();

      if (
        currentvideoitem.current.item.videoTransitions &&
        currentvideoitem.current.item.videoTransitions.length > 0
      ) {
        const seektime = itemstarttime;
        const startTime = TimelineModel.dateFromString(
          currentvideoitem.current.item.transitionFrom
        );
        const endTime = TimelineModel.dateFromString(
          currentvideoitem.current.item.transitionTo
        );
        if (startTime <= seektime && seektime <= endTime) {
          const videoSecond = nextVRef.current;
          playSecondPromise = videoSecond.play();
        }
      }
    }
    // if tiemline reached end
    else if (
      videoduration.getTime() - timelinecurrentposition.getTime() <=
      250
    ) {
      tplayref.current = "paused";
      audio1Pause();
      audio2Pause();
      setTplay("paused");
    } else {
      // if video is there but finished move to next video
      // timeline focus on current item
      setItemTimelineFocus();
      playnextvideo();
    }
    drawVideoFrame(true);
  };

  const runtimeline = function (tim) {
    let diff;

    if (tim) {
      diff = tim - number;
      number = tim;
    }

    // here video duration means TOtal duration of timeline items
    let videoduration = TimelineModel.dateFromString(getDuration());

    // getCustomTime will get current time marker time and requestAnimation will only work then
    if (
      diff > 0 &&
      tplayref.current == "playing" &&
      props.timelineref.getCustomTime() < videoduration
    ) {
      runcursor();
      requestAnimationFrame(runtimeline);
    }
  };
  const setItemTimelineFocus = () => {
    setTimeout(() => {
      let itemId = props.timelineref
        .getItemsAtCurrentTime(
          new Date(
            JSON.parse(JSON.stringify(props.timelineref.getCustomTime()))
          )
        )
        .filter(
          (el) => el.includes("videotrack0") || el.includes("blankvidtrack")
        );

      props.timelineref.focus(itemId, { zoom: false });
    }, 10);
  };
  const clickHandler2 = async (e, param) => {
    props.timelineref?.itemsData?.get();

    if (tplayref.current == "playing") {
      videoPause();
    } else {
      videoPlay();
      // timeline focus on current item
      setItemTimelineFocus();
    }
  };

  const videoVolumeHandler = () => {
    if (volumeIcon === "volume_off") {
      setVolumeIcon("volume_up");

      muteref.current = false;
      localStorage.setItem("muteRef", false);
    } else {
      setVolumeIcon("volume_off");
      muteref.current = true;
      localStorage.setItem("muteRef", true);
    }
  };

  const cropMaskEffect = (ratio) => {
    if (canvasMaskRef.current) {
      const maskCanvas = canvasMaskRef.current.getContext("2d");
      maskCanvas.willReadFrequently = true;
      maskCanvas.clearRect(0, 0, 660, 380);
      const heightMask = 360;
      const widthMask = heightMask * ratio;
      maskCanvas.fillStyle = "#000000de";
      maskCanvas.fillRect(10, 10, (640 - widthMask) / 2, heightMask);
      maskCanvas.fillRect(
        (640 - widthMask) / 2 + widthMask + 10,
        10,
        (640 - widthMask) / 2,
        heightMask
      );
    }
  };

  const drawCustomizeMaskRect = () => {
    if (canvasMaskRef.current) {
      const x = cropStartX;
      const y = cropStartY;
      const width = cropWidth;
      const height = cropHeight;
      const maskCtx = canvasMaskRef.current.getContext("2d");
      maskCtx.willReadFrequently = true;
      maskCtx.clearRect(0, 0, 660, 380);

      maskCtx.fillStyle = "#000000de";
      maskCtx.fillRect(0, y, x, 380 - y);
      maskCtx.fillRect(0, 0, 660, y);
      maskCtx.fillRect(x + width, y, 660 - x - width, height);
      maskCtx.fillRect(x, y + height, 660 - x, 380 - y - height);

      maskCtx.setLineDash([15, 9]); /*dashes are 15px and spaces are 9px*/
      maskCtx.beginPath();
      maskCtx.moveTo(x, y);
      maskCtx.lineTo(x, y + height);
      maskCtx.lineTo(x + width, y + height);
      maskCtx.lineTo(x + width, y);
      maskCtx.lineTo(x, y);

      maskCtx.strokeStyle = "#fff";
      maskCtx.stroke();

      maskCtx.setLineDash([]);
      maskCtx.rect(x - 5, y - 5, 10, 10);
      maskCtx.rect(x - 5 + width, y - 5, 10, 10);
      maskCtx.rect(x - 5 + width, y - 5 + height, 10, 10);
      maskCtx.rect(x - 5, y - 5 + height, 10, 10);
      maskCtx.stroke();
    }
  };

  const updateCropRatio = async (ratio) => {
    try {
      const url = `${server.apiUrl}/project/${projectUrl}/updateCropRatio`;
      const data = { id: projectUrl, ratio: ratio };
      await axios.post(url, data);
    } catch (error) {
      console.log(error);
    }
  };
  const updateCropCustomRatio = async (customCropCoord) => {
    try {
      const url = `${server.apiUrl}/project/${projectUrl}/updateCustomRatio`;
      const data = { id: projectUrl, customCropCoord: customCropCoord };
      await axios.post(url, data);
    } catch (error) {
      console.log(error);
    }
  };

  const anchorHitRectangle = (x, y) => {
    if (
      cropStartX - resizerRadius < x &&
      cropStartX + resizerRadius > x &&
      cropStartY - resizerRadius < y &&
      cropStartY + resizerRadius > y
    ) {
      return 0;
    }
    if (
      cropStartX - resizerRadius + cropWidth < x &&
      cropStartX + resizerRadius + cropWidth > x &&
      cropStartY - resizerRadius < y &&
      cropStartY + resizerRadius > y
    ) {
      return 1;
    }
    if (
      cropStartX - resizerRadius + cropWidth < x &&
      cropStartX + resizerRadius + cropWidth > x &&
      cropStartY - resizerRadius + cropHeight < y &&
      cropStartY + resizerRadius + cropHeight > y
    ) {
      return 2;
    }
    if (
      cropStartX - resizerRadius < x &&
      cropStartX + resizerRadius > x &&
      cropStartY - resizerRadius + cropHeight < y &&
      cropStartY + resizerRadius + cropHeight > y
    ) {
      return 3;
    }
    return -1;
  };

  const hitImage = (x, y) => {
    return (
      x > cropStartX &&
      x < cropStartX + cropWidth &&
      y > cropStartY &&
      y < cropStartY + cropHeight
    );
  };

  const handleMouseDown = (e) => {
    const canvasOffset = getOffset(canvasMask);
    offsetX = canvasOffset.left;
    offsetY = canvasOffset.top;
    startX = parseInt(e.clientX - offsetX);
    startY = parseInt(e.clientY - offsetY);
    // check mouse point is in Text
    draggingResizer = anchorHitRectangle(startX, startY);
    mouseDown =
      hitImage(startX, startY) || anchorHitRectangle(startX, startY) > -1;
  };

  const handleMouseUp = (e) => {
    if (mouseDown) {
      // update database for custom point
      updateCropCustomRatio(
        JSON.stringify({ cropStartX, cropStartY, cropWidth, cropHeight })
      );
    }
    mouseDown = false;
    draggingResizer = -1;
    canvasMask.style.cursor = "default";
  };
  const handleMouseOut = (e) => {
    handleMouseUp(e);
  };

  const handleMouseMove = (e) => {
    if (mouseDown) {
      mouseX = parseInt(e.clientX - offsetX);
      mouseY = parseInt(e.clientY - offsetY);
      if (draggingResizer > -1) {
        // resize the crop rectangle
        switch (draggingResizer) {
          case 0:
            //top-left
            cropStartX = mouseX;
            cropWidth = cropStartRight - mouseX;
            cropStartY = mouseY;
            cropHeight = cropStartBottom - mouseY;
            canvasMask.style.cursor = "nw-resize";
            break;
          case 1:
            //top-right
            cropStartY = mouseY;
            cropWidth = mouseX - cropStartX;
            cropHeight = cropStartBottom - mouseY;
            canvasMask.style.cursor = "ne-resize";
            break;
          case 2:
            //bottom-right
            cropWidth = mouseX - cropStartX;
            cropHeight = mouseY - cropStartY;
            canvasMask.style.cursor = "nw-resize";
            break;
          case 3:
            //bottom-left
            cropStartX = mouseX;
            cropWidth = cropStartRight - mouseX;
            cropHeight = mouseY - cropStartY;
            canvasMask.style.cursor = "ne-resize";
            break;
        }
        if (cropStartX < 10) {
          cropStartX = 10;
        }
        if (cropStartY < 10) {
          cropStartY = 10;
        }
        if (cropWidth < 25) {
          cropWidth = 25;
        }
        if (cropHeight < 25) {
          cropHeight = 25;
        }
        cropStartRight = cropStartX + cropWidth;
        cropStartBottom = cropStartY + cropHeight;
      } else {
        // move the crop rectangle
        var dx = mouseX - startX;
        var dy = mouseY - startY;
        cropStartX += dx;
        cropStartY += dy;
        cropStartRight += dx;
        cropStartBottom += dy;
        canvasMask.style.cursor = "move";

        // check cropRect overflow in canvas size
        if (cropStartX < 10) {
          cropStartX = 10;
        }
        if (cropStartY < 10) {
          cropStartY = 10;
        }
        if (cropStartBottom > 370) {
          cropStartBottom = 370;
        }
        if (cropStartRight > 650) {
          cropStartRight = 650;
        }
        cropHeight = cropStartBottom - cropStartY;
        cropWidth = cropStartRight - cropStartX;
      }
      drawCustomizeMaskRect();
      startX = mouseX;
      startY = mouseY;
    } else {
      startX = parseInt(e.clientX - offsetX);
      startY = parseInt(e.clientY - offsetY);

      // check mouse point is in Text
      draggingResizer = anchorHitRectangle(startX, startY);
      if (draggingResizer > -1) {
        switch (draggingResizer) {
          case 0:
            canvasMask.style.cursor = "nw-resize";
            break;
          case 1:
            canvasMask.style.cursor = "ne-resize";
            break;
          case 2:
            canvasMask.style.cursor = "nw-resize";
            break;
          case 3:
            canvasMask.style.cursor = "ne-resize";
            break;
        }
      } else {
        canvasMask.style.cursor = "default";
      }
    }
  };

  const getOffset = (element) => {
    if (!element.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset,
    };
  };

  useEffect(() => {
    if (ratio != "custom") {
      if (canvasMaskRef.current) {
        cropMaskEffect(ratio);
        updateCropRatio(ratio);
      }
    } else {
      if (canvasMaskRef.current) {
        canvasMask = canvasMaskRef.current;
        canvasMask.addEventListener("mousedown", handleMouseDown);
        canvasMask.addEventListener("mousemove", handleMouseMove);
        canvasMask.addEventListener("mouseup", handleMouseUp);
        canvasMask.addEventListener("mouseout", handleMouseOut);
        drawCustomizeMaskRect();
        return () => {
          canvasMask.removeEventListener("mousedown", handleMouseDown);
          canvasMask.removeEventListener("mousemove", handleMouseMove);
          canvasMask.removeEventListener("mouseup", handleMouseUp);
          canvasMask.removeEventListener("mouseout", handleMouseOut);
        };
      }
    }
  }, [ratio]);

  return (
    <>
      {props && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            flex: 1,
            minHeight: "360px",
            position: "relative",
            justifyContent: "space-between",
            background: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                height: "5vh",
                maxHeight: "5vh",
                minHeight: "5vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "15px",

                gap: "5px",
              }}
            >
              <i
                className="material-icons"
                aria-hidden="true"
                style={{ fontSize: "14px", top: 0 }}
              >
                movie_creation
              </i>
              <div> {t("canvas.videoPreview")}</div>
            </h2>
          </div>
          <div
            style={{
              position: "relative",
              background: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "660px",
              minHeight: "380px",
              margin: "5px 10px",
            }}
            className="player"
          >
            {timelineLoading ? (
              <>
                <TailSpin
                  type="TailSpin"
                  color="#0a7aad"
                  height={50}
                  width={50}
                />
              </>
            ) : (
              <>
                {isVideoLoading ? (
                  <>
                    <TailSpin
                      type="TailSpin"
                      color="#0a7aad"
                      height={50}
                      width={50}
                    />
                  </>
                ) : (
                  <>
                    <canvas
                      id="c2"
                      width="640"
                      height="360"
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "2",
                        width: "640px",
                        height: "360px",
                      }}
                      ref={canvasRef2}
                    ></canvas>
                    <canvas
                      id="c3"
                      width="640"
                      height="360"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: "3",
                        width: "640px",
                        height: "360px",
                      }}
                      ref={canvasRef3}
                    ></canvas>
                    <canvas
                      id="c1"
                      width="640"
                      height="360"
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                        width: "640px",
                        height: "360px",
                      }}
                      ref={canvasRef}
                    ></canvas>
                  </>
                )}
                <canvas
                  id="c-mask"
                  width="660"
                  height="380"
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "5",
                    width: "660px",
                    height: "380px",
                  }}
                  ref={canvasMaskRef}
                ></canvas>
                <video
                  id="v1"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    objectFit: "cover",
                    justifyContent: "center",
                    opacity: "0",
                    position: "absolute",
                  }}
                  controls
                  preload="meta"
                  ref={videoRef}
                  // we might play video from s3 so need to set crossOrigin
                  // crossOrigin="Anonymous"
                ></video>

                <video
                  id="v2"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    objectFit: "cover",
                    justifyContent: "center",
                    position: "absolute",
                    opacity: "0",
                  }}
                  controls
                  preload="meta"
                  ref={videoRefSecond}
                  // we might play video from s3 so need to set crossOrigin
                  // crossOrigin="Anonymous"
                ></video>
                <audio id="a1" ref={audioRef1}></audio>
                <audio id="a2" ref={audioRef2}></audio>
              </>
            )}
          </div>
          <div
            style={{
              position: "relative",
              background: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="controls"
              style={{
                maxWidth: "660px",
              }}
            >
              <div className="controls-duration">
                <span
                  style={{
                    color: "#e1e1e1",
                    fontSize: "15px",
                  }}
                >
                  {currentVideoTime2}
                </span>
                <span
                  style={{
                    color: "#424e5c",
                    marginLeft: "5px",
                    fontSize: "12px",
                  }}
                >
                  | {getDuration()}
                </span>
              </div>
              <div className="controls-button">
                <button
                  className="play"
                  aria-label="play pause toggle"
                  onClick={videoRewind}
                  disabled={timelineLoading || isVideoLoading}
                >
                  <img src="/Image/fast_backward.png" alt="" />
                </button>
                <button
                  className="play"
                  aria-label="play pause toggle"
                  onClick={clickHandler2}
                  disabled={timelineLoading || isVideoLoading}
                >
                  {tplay == "playing" ? (
                    <img src="/Image/pause.png" alt="" />
                  ) : (
                    <img src="/Image/play.png" alt="" />
                  )}
                </button>
                <button
                  className="play"
                  aria-label="play pause toggle"
                  onClick={videoForward}
                  disabled={timelineLoading || isVideoLoading}
                >
                  <img src="/Image/fast_forward.png" alt="" />
                </button>

                <button
                  className="muted"
                  onClick={videoVolumeHandler}
                  disabled={timelineLoading || isVideoLoading}
                >
                  <i className="material-icons playIcon">{volumeIcon}</i>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ zIndex: 6 }}
                  element="react-select-crop"
                  onChange={(el) => setRatio(el?.value)}
                  selectedList={selectedCropList}
                  getOptionLabel={(option) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${
                            24 *
                            (option.value == "custom" ? 1.22 : option.value)
                          }px`,
                          height: "24px",
                          borderRadius: "4px",
                          border: "1px solid #777",
                          marginRight: "4px",
                        }}
                      >
                        &nbsp;
                      </div>
                      <span
                        style={{
                          color:
                            option.color === "white" || ratio === option.value
                              ? "white"
                              : "black",
                        }}
                      >
                        {option.label}
                      </span>
                    </div>
                  )}
                  defaultValue={{
                    label: ratio
                      ? selectedCropList.find((x) => x.value === ratio).label
                      : "16 : 9",
                    value: ratio ? ratio : 16 / 9,
                    color: "white",
                  }}
                />
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
