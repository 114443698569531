import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { server } from "../../config";
import WebcamStremCapture from "./WebcamStremCapture";
import ScreenCapture from "./ScreenCapture";
import AssetsFromSuite from "./AssetsFromSuite";
import Canvas from "./Canvas";
import AudioRecording from "./AudioRecording";
import { splitTime } from "../shared/utils";
import TimelineModel from "./TimelineModel";
import FetchErrorDialog from "./FetchErrorDialog";
import Sources from "./Sources";
import Timeline from "./Timeline";
import timeManager from "../../models/timeManager";
import PartialScreenCapture from "./PartialScreenCapture";
import PartialScreenInputModal from "./Modal/PartialScreenInputModal";
import { useMyContext } from "../contexts/StateHolder";
import LoadingDialog1 from "./LoadingDialog1";

export default function Editor(props) {
  const { t, i18n } = useTranslation();

  const [cookies] = useCookies(["lang"]);

  const [showRecordModal, setShowRecordModal] = useState(false);

  const [checkVideoPlayingCanvas, setCheckVideoPlayingCanvas] = useState(false);
  // gradientColor state
  const [gradientColor, setgradientColor] = useState(null);

  const [showAddTextModal, setshowAddTextModal] = useState(false);

  // gradient color(hex for showing in modal)
  const [hexColor, sethexColor] = useState(null);

  const [checkProps, setcheckProps] = useState(false);

  const [showAddFilterDialog, setshowAddFilterDialog] = useState(false);
  // duration to passed to canvas and timeline component
  const [duration, setduration] = useState("00:00:00,000");

  // allAsset Filter
  const [allAssetFilter, setAllAssetFilter] = useState(null);

  const [resources, setresources] = useState({});
  const [adminresources, setAdminResources] = useState({});
  // musicLibraryResources
  const [musicLibraryresources, setMusicLibraryResources] = useState({});
  const [processing, setProcessing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFetchError, setShowFetchError] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [checkUseEffectValue, setcheckUseEffectValue] = useState(false);

  const [loadTimelineIcon, setLoadTimelineIcon] = useState(false);

  // show assets modal
  const [showAssetModal, setshowAssetModal] = useState(false);
  // saving project Url in case the user refresh the page ( cause we will loose our url)

  // project asset
  const [projectAsset, setprojectAsset] = useState(null);
  const [category, setcategory] = useState(null);
  // saving all asset info
  const [allAssetInfo, setallAssetInfo] = useState(null);

  const [timelineref, settimelineref] = useState(null);
  const [cropRatio, setCropRatio] = useState(null);
  const [customCropCoord, setCustomCropCoord] = useState(null);

  // timeline time to pass to canvas
  const [timelineTimeToCanvas2, setTimelineTimeToCanvas2] =
    useState("00:00:00,000");
  const [timelineMarkerTime, setTimelineMarkerTime] = useState(null);

  // input modal for partial screen ->here cause to be used in Sources and partialScreeb component
  // partial screen state starts here

  const [showCropScreenCaptureInputModal, setShowCropScreenCaptureInputModal] =
    useState(false);
  const [CROP_X, setCROP_X] = useState(0);
  const [CROP_Y, setCROP_Y] = useState(0);
  const [CROP_WIDTH, setCROP_WIDTH] = useState(640);
  const [CROP_HEIGHT, setCROP_HEIGHT] = useState(360);

  // partial screen state ends here

  const {
    globalAvailableFonts,
    setGlobalAvailableFonts,
    setGlobalBrandColor,
    screenCapture,
    webcamCapture,
    setwebcamCapture,
    cropScreenCapture,
    setCropScreenCapture,
    settimeline,
    timeline,
    projectUrl,
    setprojectUrl,
    setTime,
    time,
    setUpdateTimeline,
    XMLFiles,
    setXMLFiles,
    globalFonts,
    setGlobalFonts,
  } = useMyContext();

  useEffect(() => {
    if (window.location.href.match(/project\/([^/]*)/)[1]) {
      setprojectUrl(window.location.href.match(/project\/([^/]*)/)[1]);

      if (cookies?.lang?.includes("fi")) {
        i18n.changeLanguage("fi_FI");
      } else {
        i18n.changeLanguage("en_US");
      }
      const loadDataFunction = async () => {
        await loadData(true);
        setcheckUseEffectValue(true);
      };
      loadDataFunction();
      console.log("hello");
    }
    return () => {
      setTime(new Date(1970, 0, 1));
    };
  }, []);

  const onAssetModalHandler = () => {
    setshowAssetModal(!showAssetModal);
  };

  const loadData = async (nohardReload) => {
    !nohardReload && setLoading(true);
    setLoadTimelineIcon(true);
    try {
      let url;
      if (props.projectUrl) {
        url = `${server.apiUrl}/project/${props.projectUrl} `;
        setprojectUrl(props.projectUrl);
      } else {
        url = `${server.apiUrl}/project/${window.location.href
          .match(/project\/([^/]*)/)[1]
          .trim()} `;
        setprojectUrl(window.location.href.match(/project\/([^/]*)/)[1]);
      }
      const res = await axios.get(url);
      if (processing !== null && res.data.processing === null)
        res.data.processing = 100;

      setCropRatio(res.data.cropRatio);
      setCustomCropCoord(res.data.customCropCoord);
      setcategory(res.data.category);
      setresources(res.data.resources);
      setAdminResources(res.data.resourcesAdmin);
      setMusicLibraryResources(res?.data?.resourcesMusicLibrary);
      settimeline(res.data.timeline);
      setProcessing(res.data.processing);
      setallAssetInfo(res.data?.allAsset?.assets);
      setLoading(false);
      setcheckProps(true);
      setAllAssetFilter(res.data?.allAssetFilter);
      props.setdashboardTokenExpiryTime(res.data.expiryTime);
      setGlobalBrandColor(res.data?.brandColors ? res.data?.brandColors : []);
      setGlobalFonts(res.data?.brandFonts ? res.data?.brandFonts : []);
      let filterBrandFonts = globalAvailableFonts.filter(
        (el) => !res.data?.brandFonts?.includes(el)
      );

      setGlobalAvailableFonts(filterBrandFonts);

      setLoadTimelineIcon(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.msg) {
        openFetchErrorDialog(error?.response.data.msg, error.response.data.err);
      } else {
        openFetchErrorDialog(error?.response?.data?.message);
      }

      setcheckProps(true);
      setLoading(false);
      setLoadTimelineIcon(false);
    }
  };

  const addResource = (resource, admin) => {
    // first thing we should check is whether it is musicLibrary files or not ->they are independent file system irrespective of organization
    let resourcesValue;
    if (resource?.musicLibraryFiles) {
      resourcesValue = Object.assign({}, musicLibraryresources);
      resourcesValue[resource.id] = resource;
      setMusicLibraryResources(resourcesValue);
    } else {
      // it will belong to admin or normal resources for that project
      resourcesValue = !resource.admin
        ? Object.assign({}, resources)
        : Object.assign({}, adminresources);
      resourcesValue[resource.id] = resource;
      resource.admin
        ? setAdminResources(resourcesValue)
        : setresources(resourcesValue);
    }
    setUpdateTimeline(true);
  };

  const delResource = (id) => {
    const resourcesValue = Object.assign({}, resources);
    delete resourcesValue[id];
    setresources(resourcesValue);
    setUpdateTimeline(true);
  };

  const putResource = async (
    id,
    duration,
    trackId,
    adminItems,
    currentItem,
    musicLibraryItems
  ) => {
    const timelineValue = Object.assign({}, timeline);

    const track = TimelineModel.findTrack(timelineValue, trackId);
    if (adminItems) {
      // it means they are resources from Brand Elements
      duration = adminresources[id].duration;
    } else if (musicLibraryItems) {
      // it means they are resources from music library
      duration = musicLibraryresources[id].duration;
    }
    if (duration === null && !adminItems) duration = resources[id].duration;
    const timeEnd = timeManager.addDuration(track.duration, duration);
    track.items.push({
      resource: id,
      in: "00:00:00,000",
      out: duration,
      start: track.duration,
      end: timeEnd,
      filters: [],
      transitionTo: null,
      transitionFrom: null,
      mime: resources[id]?.mime ? resources[id].mime : adminresources[id]?.mime,
      admin: adminItems ? true : false,
      musicLibraryItems,
      resource_s3: resources[id]?.resource_s3,
      resource_s3_preset: resources[id]?.resource_s3_preset,
    });

    // add  trannsition for audio
    try {
      if (track.id === "audiotrack0") {
        const url = `${server.apiUrl}/project/${projectUrl}/transition`;
        const data = {
          startDuration: track.duration,
          endDuration: timeEnd,
          transition: "mix",
          track: 0,
        };

        const res = await axios.post(url, data);
        if (res.data.msg === "Transition Applied") {
          toast.info("Successfully Loaded Audio File");
        }
      }
    } catch (error) {
      toast.error("Unable to Add Audio Files .Please Try later");
    }

    track.duration = timeEnd;
    settimeline(timelineValue);
    setUpdateTimeline(true);
  };

  const addShapesResource = async (
    id,
    duration,
    trackId,
    parameters,
    editMode
  ) => {
    const timelineValue = Object.assign({}, timeline);
    const track = TimelineModel.findTrack(timelineValue, trackId);
    let filters = [];
    let options = [];
    const entries = Object.entries(parameters.params);
    Object.values(entries).map((el) =>
      options.push({
        name: el[0],
        value: el[1],
      })
    );

    // if it is edit mode we have to edit value instead of adding
    if (editMode) {
      loadData(true);
    }

    filters.push({ service: parameters.filter, options: options });

    const timeEnd = timeManager.addDuration(track.duration, duration);
    track.items.push({
      resource: id,
      in: "00:00:00,000",
      out: duration,
      start: track.duration,
      end: timeEnd,
      admin: false,
      musicLibraryFiles: false,
      resource_s3: null,
      videoTransitions: [],
      filters: filters,
    });

    track.duration = timeEnd;

    // we also need to update the resource
    let resourcesValue = Object.assign({}, resources);
    let resource = {
      id: id,
      admin: null,
      composer_name: null,
      duration: null,
      mime: null,
      musicLibraryFile: null,
      name: null,
      resource_s3: null,
    };
    resourcesValue[id] = resource;

    setresources(resourcesValue);
    settimeline(timelineValue);
    setUpdateTimeline(true);
  };

  const addFilter = async (parameters, endValueForTrim, imageTrackAsset) => {
    try {
      const url = `${server.apiUrl}/project/${projectUrl}/filter`;
      const data = parameters;
      console.log(parameters, endValueForTrim, imageTrackAsset, "parameters");

      const addFilterRes = await axios.post(url, data);

      if (
        addFilterRes?.data?.msg ===
        "filter cannot be applied.Transition already present"
      ) {
        return toast.error(
          "Fade effects cannot be applied to video with any transitions."
        );
      }

      // while editing we dont save the xml file
      // if imageTrackAsset is true it means we are trying to add imgae into the track and we dont save this filter as XML cause we dont want to undo this
      if (!endValueForTrim && !imageTrackAsset) {
        // this means we are editing and before editing we have sent a backend request to delte the filter so we dont save that XML file in our data
        let oldXml = [...XMLFiles];
        oldXml.push(addFilterRes?.data?.projectXMLFILE);
        setXMLFiles(oldXml);
        console.log("oldXml", oldXml);
      }

      let options = [];

      const entries = Object.entries(parameters.params);
      Object.values(entries).map((el) =>
        options.push({
          name: el[0],
          value: el[1],
        })
      );
      console.log("options", options);
      if (parameters.textStatus) {
        setAllAssetFilter([...allAssetFilter, options]);
      } else if (imageTrackAsset) {
        // here we are adding filter to the image track so first we neeed to include that image in the timeline and then add that image

        console.log(adminresources, 123);

        const timelineValue = Object.assign({}, timeline);

        const track = TimelineModel.findTrack(timelineValue, parameters.track);

        let filters = [];

        let curDuration = parameters.duration;

        filters.push({ service: parameters.filter, options: options });

        const timeEnd = timeManager.addDuration(track.duration, curDuration);
        let item1 = {
          resource: parameters?.id,
          in: "00:00:00,000",
          out: curDuration,
          start: track.duration,
          end: timeEnd,
          filters: filters,

          // mime: "image/png",
          mime: parameters?.currentItem?.mime,

          admin: parameters?.currentItem?.admin ? true : false,

          resource_s3: parameters?.currentItem?.resource_s3,
          resource_s3_preset: parameters?.currentItem?.resource_s3_preset,
          videoTransitions: [],
          musicLibraryFile: parameters?.currentItem?.musicLibraryFiles,
          name: parameters?.currentItem?.name,
        };

        track.items.push(item1);
        let item2 = { ...item1 };
        item2.id = parameters?.id;

        let resourcesValue;
        resourcesValue = Object.assign({}, resources);
        resourcesValue[parameters?.id] = item2;

        setresources(resourcesValue);

        console.log("tracks", track);
        track.duration = timeEnd;
        settimeline(timelineValue);
      } else {
        const timelineValue = Object.assign({}, timeline);

        const track = TimelineModel.findTrack(timelineValue, parameters.track);
        let item = TimelineModel.findItem(track.items, parameters.item);
        console.log("track,item", track, item);

        if (parameters.filter === "fadeInBrightness") {
          let fadeInLevel = {};
          fadeInLevel.startDuration =
            splitTime(parameters.params.level.split("=")[0]) +
            splitTime(item.in);
          fadeInLevel.EndDuration =
            splitTime(parameters.params.level.split("=")[1].split(";")[1]) +
            splitTime(item.in);

          options.push({
            name: "alpha",
            value: 1,
          });

          let itemClone = { ...item };
          item = { ...itemClone, fadeInLevel };
        } else if (parameters.filter === "fadeOutBrightness") {
          let fadeOutLevel = {};

          options.push({
            name: "alpha",
            value: 1,
          });

          fadeOutLevel.startDuration =
            (splitTime(parameters.params.level.split("=")[0]) +
              splitTime(item.in)) *
            1;
          fadeOutLevel.EndDuration =
            (splitTime(parameters.params.level.split("=")[1].split(";")[1]) +
              splitTime(item.in)) *
            1;
          let itemClone = { ...item };
          item = { ...itemClone, fadeOutLevel };
          console.log(item, "newitem");
        }

        item.filters.push({ service: parameters.filter, options: options });

        settimeline(timelineValue);
      }

      if (endValueForTrim) {
        toast.info(`${t("shared.EffectEdited")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        toast.info(`${t("shared.EffectAdded")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
      setshowAddTextModal(false);
    } catch (error) {
      console.log(error);
      // console.log(error.response.data);
      // openFetchErrorDialog(error.response.data.msg, error.response.data.err);
    }
  };

  const addTransition = async (parameters, endValueForTrim) => {
    try {
      console.log("parameters", parameters);
      const timelineValue = Object.assign({}, timeline);
      const track = TimelineModel.findTrack(timelineValue, parameters.track);
      let item = TimelineModel.findItem(track.items, parameters.item);

      item.transitionTo = parameters.transitionTo;
      item.transitionFrom = parameters.transitionFrom;

      item.videoTransitions.push({
        service: parameters.service,
        options: [parameters.params],
      });

      console.log("item", item);
      console.log("timelineValue", timelineValue);
      settimeline(timelineValue);

      if (endValueForTrim) {
        loadData(true);

        toast.info(`${t("shared.EffectEdited")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        toast.info(`${t("shared.EffectAdded")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteTransition = async (parameters, endValueForTrim) => {
    try {
      console.log("parameters", parameters);
      const timelineValue = Object.assign({}, timeline);
      const track = TimelineModel.findTrack(timelineValue, parameters.track);
      let item = TimelineModel.findItem(track.items, parameters.item);

      item.transitionTo = null;
      item.transitionFrom = null;

      item.videoTransitions = [];

      console.log("item", item);
      console.log("timelineValue", timelineValue);
      settimeline(timelineValue);

      if (endValueForTrim) {
        toast.info(`${t("shared.EffectEdited")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        toast.info(`${t("shared.EffectAdded")}`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delFilter = async (parameters, backendRes, DONT_SAVE_XML_FILE) => {
    const timelineValue = { ...timeline };
    const track = TimelineModel.findTrack(timelineValue, parameters.track);
    const item = TimelineModel.findItem(track.items, parameters.item);
    item.filters = item.filters.filter(
      (filter) => filter.service !== parameters.filter
    );
    settimeline(timelineValue);

    let oldXml = [...XMLFiles];
    oldXml.push(backendRes?.projectXMLFILE);
    setXMLFiles(oldXml);
  };

  const openFetchErrorDialog = (msg, err) => {
    setShowFetchError(true);
    setFetchError([msg, err]);
  };

  const closeFetchErrorDialog = () => {
    setShowFetchError(false);
    setFetchError("");
  };

  const [audioCapture, setAudioCapture] = useState(false);

  const changedVideoSeek = (time) => {
    setTimelineMarkerTime(time);
  };

  return !checkUseEffectValue ? (
    <LoadingDialog1 />
  ) : (
    <>
      <>
        {showFetchError && (
          <FetchErrorDialog msg={fetchError} onClose={closeFetchErrorDialog} />
        )}
        {fetchError && (
          <FetchErrorDialog
            msg={fetchError}
            onClose={closeFetchErrorDialog}
            hideButton
          />
        )}

        {showAssetModal && !fetchError && (
          <AssetsFromSuite
            onAssetModalHandler={onAssetModalHandler}
            projectAsset={projectAsset}
            category={category}
            setprojectAsset={setprojectAsset}
            allAssetInfo={allAssetInfo}
            setallAssetInfo={setallAssetInfo}
            project={projectUrl}
            loadData={loadData}
          />
        )}
      </>
      {checkUseEffectValue && !loading && !fetchError && (
        <>
          <main>
            <div>
              {webcamCapture && (
                <WebcamStremCapture
                  webcamCapture={webcamCapture}
                  setwebcamCapture={setwebcamCapture}
                  project={projectUrl}
                  onAddResource={addResource}
                  loadData={loadData}
                  resources={resources}
                  setresources={setresources}
                  setShowRecordModal={setShowRecordModal}
                />
              )}

              {audioCapture && (
                <AudioRecording
                  showRecordModal={showRecordModal}
                  setShowRecordModal={setShowRecordModal}
                  project={projectUrl}
                  loadData={loadData}
                  audioCapture={audioCapture}
                  setAudioCapture={setAudioCapture}
                  resources={resources}
                  setresources={setresources}
                />
              )}
              {showCropScreenCaptureInputModal && (
                <PartialScreenInputModal
                  showCropScreenCaptureInputModal={
                    showCropScreenCaptureInputModal
                  }
                  setShowCropScreenCaptureInputModal={
                    setShowCropScreenCaptureInputModal
                  }
                  setCropScreenCapture={setCropScreenCapture}
                  CROP_X={CROP_X}
                  setCROP_X={setCROP_X}
                  CROP_Y={CROP_Y}
                  setCROP_Y={setCROP_Y}
                  CROP_WIDTH={CROP_WIDTH}
                  setCROP_WIDTH={setCROP_WIDTH}
                  CROP_HEIGHT={CROP_HEIGHT}
                  setCROP_HEIGHT={setCROP_HEIGHT}
                />
              )}

              {screenCapture && (
                <ScreenCapture
                  onAddResource={addResource}
                  loadData={loadData}
                  resources={resources}
                  setresources={setresources}
                />
              )}

              {cropScreenCapture && (
                <PartialScreenCapture
                  onAddResource={addResource}
                  loadData={loadData}
                  resources={resources}
                  setresources={setresources}
                  cropScreenCapture={cropScreenCapture}
                  setCropScreenCapture={setCropScreenCapture}
                  showCropScreenCaptureInputModal={
                    showCropScreenCaptureInputModal
                  }
                  setShowCropScreenCaptureInputModal={
                    setShowCropScreenCaptureInputModal
                  }
                  CROP_X={CROP_X}
                  setCROP_X={setCROP_X}
                  CROP_Y={CROP_Y}
                  setCROP_Y={setCROP_Y}
                  CROP_WIDTH={CROP_WIDTH}
                  setCROP_WIDTH={setCROP_WIDTH}
                  CROP_HEIGHT={CROP_HEIGHT}
                  setCROP_HEIGHT={setCROP_HEIGHT}
                />
              )}
              <Sources
                items={resources}
                adminItems={adminresources}
                onAddResource={addResource}
                onDelResource={delResource}
                onPutResource={putResource}
                fetchError={openFetchErrorDialog}
                setshowAssetModal={setshowAssetModal}
                musicLibraryresources={musicLibraryresources}
                setMusicLibraryResources={setMusicLibraryResources}
                showAssetModal={showAssetModal}
                checkVideoPlayingCanvas={checkVideoPlayingCanvas}
                setCheckVideoPlayingCanvas={setCheckVideoPlayingCanvas}
                showRecordModal={showRecordModal}
                setShowRecordModal={setShowRecordModal}
                onAdd={addFilter}
                audioCapture={audioCapture}
                setAudioCapture={setAudioCapture}
                // projectAsset={projectAsset}
                // category={category}
                // setprojectAsset={setprojectAsset}
                // allAssetInfo={allAssetInfo}
                // setallAssetInfo={setallAssetInfo}
                // setcategory={setcategory}
                timelineref={timelineref}
                setShowCropScreenCaptureInputModal={
                  setShowCropScreenCaptureInputModal
                }
                globalFonts={globalFonts}
                setGlobalFonts={setGlobalFonts}
              />

              <Canvas
                timelineref={timelineref}
                timelineTimeToCanvas2={timelineTimeToCanvas2}
                allAssetFilter={allAssetFilter}
                duration={duration}
                resources={resources}
                changedVideoSeek={changedVideoSeek}
                cropRatio={cropRatio}
                customCropCoord={customCropCoord}
              />
            </div>
          </main>

          <Timeline
            resources={resources}
            duration={duration}
            setduration={setduration}
            settimelineref={settimelineref}
            items={timeline}
            onAddFilter={addFilter}
            onDelFilter={delFilter}
            loadData={loadData}
            fetchError={openFetchErrorDialog}
            checkProps={checkProps}
            setshowAddFilterDialog={setshowAddFilterDialog}
            showAddFilterDialog={showAddFilterDialog}
            gradientColor={gradientColor}
            setgradientColor={setgradientColor}
            hexColor={hexColor}
            sethexColor={sethexColor}
            timelineTimeToCanvas2={timelineTimeToCanvas2}
            setTimelineTimeToCanvas2={setTimelineTimeToCanvas2}
            setAllAssetFilter={setAllAssetFilter}
            allAssetFilter={allAssetFilter}
            showAddTextModal={showAddTextModal}
            setshowAddTextModal={setshowAddTextModal}
            adminItems={adminresources}
            globalFonts={globalFonts}
            setGlobalFonts={setGlobalFonts}
            musicLibraryresources={musicLibraryresources}
            setMusicLibraryResources={setMusicLibraryResources}
            addTransition={addTransition}
            deleteTransition={deleteTransition}
            addShapesResource={addShapesResource}
            loading={loadTimelineIcon}
            timelineMarkerTime={timelineMarkerTime}
          />
        </>
      )}
    </>
  );
}
