import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import StateHolder from "./contexts/StateHolder";

import "./i18n/config";
ReactDOM.render(
    <BrowserRouter>
        <StateHolder>
            <App />
        </StateHolder>
    </BrowserRouter>,
    document.getElementById("app")
);
