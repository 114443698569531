import React from "react";

export default function BrandColorItem(props) {
    return (
        <>
            {props.item && (
                <tr style={{ display: "flex" }}>
                    <td style={{ width: '6.5em', display: 'flex' }}>
                        <div>
                            <i className="material-icons resource-preview" aria-hidden="true" style={{color: `${props.item}`}}>
                                palette
                            </i>
                        </div>
                        <div style={{width: '60px', height: '24px', borderRadius: '4px', border: '1px solid #777', backgroundColor: `${props.item}`}}>&nbsp;</div>
                    </td>
                    <td style={{ flex: 2 }}>
                        <span>{props.item}</span>
                        <br />
                    </td>
                    <td className="column-right">
                        <button onClick={() => props.onRemove(props.item)} className="sources-delete">
                            <i className="material-icons" aria-hidden="true">
                                delete
                            </i>
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
}
