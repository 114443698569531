import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

export default function ExportModal({setOpenExist,setOpenNew,setOpen}) {
   const { t } = useTranslation();
   return <Modal isOpen={true} contentLabel="Select Any One" className={"modalVideo"} overlayClassName={"overlay"} onRequestClose={()=>setOpen(false)}>
      <div style={{ padding: "50px" }}>
         <h1
            style={{
               padding: "10px",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               margin: "0 0 20px 0",
            }}
         >
            {t("exportMethod.selectAnyOne")}
         </h1>
         <div
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <button
               onClick={() => {
                  setOpen(false);
                  setOpenNew(true);
               }}
               style={{ padding: "10px 30px", background: "#0a7aad" }}
            >
               {t("exportMethod.new")}
            </button>
            <button
               onClick={() => {
                  setOpen(false);
                  setOpenExist(true);
               }}
               style={{ padding: "10px 30px", background: "#0a7aad" }}
            >
               {t("exportMethod.exist")}
            </button>
            <button onClick={() => setOpen(false)} style={{ padding: "10px 30px" }}>
               {t("shared.cancel")}
            </button>
         </div>
      </div>
   </Modal>;
}
