import timeManager from "../models/timeManager";

export default {
    videoFilters: [
        {
            id: "brightness",
            title: "Brightness",
            in: [
                {
                    id: "level",
                    title: "Level",
                    type: "int",
                    value: 100,
                    min: 0,
                    max: 200,
                },
            ],
            out: [
                {
                    id: "start",
                    value: () => 1,
                },
                {
                    id: "level",
                    value: (input) => input.level / 100,
                },
            ],
        },

        {
            id: "lift_gamma_gain",
            title: "Background Colour",
            in: [
                {
                    id: "backgroundColour",
                    title: "Level",
                    type: "int",
                    value: 100,
                    min: 0,
                    max: 200,
                },
            ],
            out: [
                {
                    id: "lift_r",
                    value: () => 1.5259e-5,
                },
                {
                    id: "lift_g",
                    value: () => 1.5259e-5,
                },
                {
                    id: "lift_b",
                    value: () => 1.5259e-5,
                },
                {
                    id: "gamma_r",
                    value: (input) => 1.00002,
                },
                {
                    id: "gamma_g",
                    value: () => 1.00002,
                },
                {
                    id: "gamma_b",
                    value: () => 1.00002,
                },
                {
                    id: "gain_r",
                    value: (input) => {
                        let num = ((input.r ^ 2) * 0.0001 + 2 * 0.02002 * input.r + 0.99997).toFixed(2);
                        if (num >= 4) {
                            return 4;
                        } else {
                            return num;
                        }
                    },
                },
                {
                    id: "gain_g",
                    value: (input) => {
                        let num = ((input.g ^ 2) * 0.0001 + 2 * 0.02002 * input.g + 0.99997).toFixed(2);
                        if (num >= 4) {
                            return 4;
                        } else {
                            return num;
                        }
                    },
                },
                {
                    id: "gain_b",
                    value: (input) => {
                        let num = ((input.b ^ 2) * 0.0001 + 2 * 0.02002 * input.b + 0.99997).toFixed(2);
                        if (num >= 4) {
                            return 4;
                        } else {
                            return num;
                        }
                    },
                },
            ],
        },
        {
            id: "fadeInBrightness",
            title: "Fade In",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    value: "00:00:00,000",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input) => `00:00:00,000=0;${input.duration}=1`,
                },
                {
                    id: "alpha",
                    value: () => 1,
                },
            ],
        },

        {
            id: "dynamictext",
            title: "Text",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    startvalue: "00:00:00,000",
                    endvalue: "00:00:00,000",
                    fgcolour: "fgcolor",
                    bgcolour: "bgcolour",
                    size: "size",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input, resource) => `${timeManager.subDuration(resource.out, input.duration)}=1;${resource.out}=0`,
                },
                {
                    id: "alpha",
                    value: () => 1,
                },
            ],
        },

        {
            id: "watermark",
            title: "Text Animation",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    startvalue: "00:00:00,000",
                    endvalue: "00:00:00,000",
                    fgcolour: "fgcolor",
                    bgcolour: "bgcolour",
                    size: "size",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input, resource) => `${timeManager.subDuration(resource.out, input.duration)}=1;${resource.out}=0`,
                },
                {
                    id: "alpha",
                    value: () => 1,
                },
            ],
        },
        {
            id: "fadeOutBrightness",
            title: "Fade Out",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    value: "00:00:00,000",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input, resource) => `${timeManager.subDuration(resource.out, input.duration)}=1;${resource.out}=0`,
                },
                {
                    id: "alpha",
                    value: () => 1,
                },
            ],
        },
    ],

    audioFilters: [
        {
            id: "fadeInVolume",
            title: "Change Volume",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    value: "00:00:00,000",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input) => `${input.startDuration}=${input.sound};${input.endDuration}=${input.sound}`,
                },
            ],
        },
        {
            id: "fadeOutVolume",
            title: "Change Volume",
            in: [
                {
                    id: "duration",
                    title: "Duration",
                    type: "duration",
                    value: "00:00:00,000",
                },
            ],
            out: [
                {
                    id: "level",
                    value: (input, resource) => `${input.startDuration}=${input.sound};${input.endDuration}=${input.sound}`,
                },
            ],
        },
    ],
};
