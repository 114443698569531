import React, { useEffect, useState } from "react";

import AddText from "./AddText";

export default function TextModal(props) {
    let app = {};

    const [currentTextInfo, setCurrentTextInfo] = useState(null);
    const [openEditTextModal, setOpenEditTextModal] = useState(false);
    const [indexCurrentText, setindexCurrentText] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if (props.textModalInfo) {
            props.textModalInfo.map((el, i) => {
                app[el["name"]] = el["value"];
            });

            const geometrySplit = app.geometry.split("%");
            const yPositionSplit = geometrySplit[1].split("/")[1];

            app.xPosition = geometrySplit[0] * 1;
            app.yPosition = yPositionSplit * 1;

            setCurrentTextInfo(app);
            setindexCurrentText(props.deleteTextIndex);
            setOpenEditTextModal(true);
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
        return () => {
            setOpenEditTextModal(false);
            setLoading(true);

            setCurrentTextInfo(null);
        };
    }, [props.textModalInfo, props.deleteTextIndex]);

    return (
        <>
            {openEditTextModal && currentTextInfo && !loading && (
                <AddText
                    timelineTime={props.timelineTime}
                    openEditTextModal={openEditTextModal}
                    setOpenEditTextModal={setOpenEditTextModal}
                    currentTextInfo={currentTextInfo}
                    setCurrentTextInfo={setCurrentTextInfo}
                    project={props.project}
                    indexCurrentText={indexCurrentText}
                    addTextClicked={props.addTextClicked}
                    setAddTextClicked={props.setAddTextClicked}
                    allAssetFilter={props.allAssetFilter}
                    setAllAssetFilter={props.setAllAssetFilter}
                    timelineRef={props.timelineRef}
                />
            )}
        </>
    );
}
