import React, { useState, useEffect, useRef } from "react";
import { drawImageAspectVideoFrame, getVideoStartTime } from "../../shared/utils";
import TimelineModel from "../TimelineModel";

import date from "date-and-time";
import { toast } from "react-toastify";
import axios from "axios";
const timestampToSeconds = require("timestamp-to-seconds");
import { useTranslation } from "react-i18next";

import timeManager from "../../../models/timeManager";
import ColorGrading from "../ColorGrading";
import { server } from "../../../config";
import { useMyContext } from "../../contexts/StateHolder";
import Modal from "../../shared/UI/Modal/Modal";
import Input from "../../shared/UI/Input/Input";

import classes from "./AddText.module.css";
import Button from "../../shared/UI/Button/Button";

let canvas, ctx, txtPosX, txtPosY, txtWidth, txtHeight, resizerRadius, pi2, draggingResizer;
let fgColor, bgColor, argument, fontSize, fontStyle, fontFamily;

export default function AddText(props) {
    const { t } = useTranslation();
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const { globalAvailableFonts, globalBrandColor, setXMLFiles, XMLFiles, globalFonts } = useMyContext();

    const [textArgument, settextArgument] = useState("");
    const [startDuration, setstartDuration] = useState("");
    const [endDuration, setendDuration] = useState("");

    const [textWeightFinalValue, settextWeightFinalValue] = useState("regular");
    const [textStyleFinalValue, settextStyleFinalValue] = useState("normal");
    const [textFontSize, settextFontSize] = useState(100);
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);
    const textWeight = ["light", "regular", "bold"];
    const textStyle = ["normal", "italic"];
    const [inn, setInn] = useState(null);
    const [out, setOut] = useState(null);
    const [familyName, setfamilyName] = useState(globalFonts);
    const [currentFamilyName, setCurrentFamilyName] = useState("");
    const [showColorGradientModalText, setShowColorGradientModalText] = useState(false);
    const [showColorGradientModalBackground, setShowColorGradientModalBackground] = useState(false);
    // hexcolor is for text color
    const [hexColor, sethexColor] = useState(null);
    /// hexcolor 1 is for background
    const [hexColor1, sethexColor1] = useState(null);
    const [editingMode, setEditingMode] = useState(false);

    let canvasOffset, offsetX, offsetY, startX, startY, mouseX, mouseY;
    let mouseDown = false;

    useEffect(() => {
        if (globalFonts?.length > 0) {
            setfamilyName(globalFonts);
            setCurrentFamilyName(globalFonts[0]);
        } else {
            // if there is not any brand global font ->use all of existing available font
            setfamilyName(globalAvailableFonts);
            // arial as default font
            setCurrentFamilyName("Arial");
        }
    }, [globalFonts, JSON.stringify(globalFonts)]);

    const globalBrandColorList = globalBrandColor?.map((el) => {
        return {
            label: el,
            value: el,
            color: el,
        };
    });

    const CancelButtonHandler = () => {
        if (editingMode) {
            props.setOpenEditTextModal(false);
            props.setAddTextClicked(false);
            props.setCurrentTextInfo(null);
            setEditingMode(false);
            return;
        }
        props.setshowAddTextModal(false);
    };

    const changeTextFont = (e) => {
        settextStyleFinalValue(e.target.value);
    };

    const changeTextFamily = (e) => {
        setCurrentFamilyName(e.target.value);
    };

    const changeTextWeight = (e) => {
        settextWeightFinalValue(e.target.value);
    };

    const startTimeHandler = (e) => {
        if (e._d) {
            setInn(null);
            const d = new Date(e._d.getTime());
            const start = date.format(d, "HH:mm:ss,SSS");
            setstartDuration(start);
        }
    };

    const endTimeHandler = (e) => {
        if (e._d) {
            setOut(null);
            const d = new Date(e._d.getTime());
            const end = date.format(d, "HH:mm:ss,SSS");
            setendDuration(end);
        }
    };

    const formHandler = async () => {
        let timeStart;
        let timeEnd;

        if (startDuration) {
            timeStart = startDuration;
        }
        if (endDuration) {
            timeEnd = endDuration;
        }

        if (editingMode) {
            if (startDuration === "") {
                timeStart = inn;
            }
            if (endDuration === "") {
                timeEnd = out;
            }
        }
        if (!timeStart) {
            return toast.error(`${t("error.enterStartDuration")}`);
        }
        if (!timeEnd) {
            return toast.error(`${t("error.enterEndDuration")}`);
        }
        if (xPosition < -100 || xPosition > 100) {
            return toast.error(`${t("error.enterXPosition")}`);
        }
        if (yPosition < -100 || yPosition > 100) {
            return toast.error(`${t("error.enterYPosition")}`);
        }
        if (timestampToSeconds(timeStart) > timestampToSeconds(props.timelineTime)) {
            return toast.error(`${t("error.startTimeExceedTimeline")}`);
        }
        if (timestampToSeconds(timeStart) > timestampToSeconds(timeEnd)) {
            return toast.error(`${t("error.startTimeExceedEndTime")}`);
        }
        if (timestampToSeconds(timeEnd) > timestampToSeconds(props.timelineTime)) {
            return toast.error(`${t("error.endTimeExceedTimeline")}`);
        }
        if (timestampToSeconds(timeEnd) <= timestampToSeconds(timeStart)) {
            return toast.error(`${t("error.endTimeLessThanStartTime")}`);
        }
        if (!timeManager.isValidDurationWithZeroIncluded(timeStart) && !timeManager.isValidDuration(timeEnd)) {
            return toast.error(`${t("error.invalidDuration")}`);
        }

        if (!textArgument && !editingMode) {
            return toast.error(`${t("addText.enterText")}`);
        }

        let weight = 400;
        if (textWeightFinalValue === "light") {
            weight = 200;
        } else if (textWeightFinalValue === "bold") {
            weight = 800;
        }

        let xValue = xPosition === 0 && editingMode && props.currentTextInfo.xPosition ? props.currentTextInfo.xPosition : xPosition;
        let yValue = yPosition === 0 && editingMode && props.currentTextInfo.yPosition ? props.currentTextInfo.yPosition : yPosition;

        let newFilter = {
            filter: "dynamictext",
            params: {
                in: timeStart,
                out: timeEnd,
                fgcolour: editingMode && !hexColor ? props.currentTextInfo.fgcolour : hexColor ? hexColor : "white",
                bgcolour: editingMode && !hexColor1 ? props.currentTextInfo.bgcolour : hexColor1 ? hexColor1 : "transparent",
                argument: !textArgument && props.currentTextInfo.argument && editingMode ? props.currentTextInfo.argument : textArgument,
                size: textFontSize,
                style: textStyleFinalValue,
                weight: weight,
                geometry: `${xValue}%/${yValue}%:100%x100%:100`,
                family: !currentFamilyName && props.currentTextInfo && props.currentTextInfo.family && editingMode ? props.currentTextInfo.family : currentFamilyName,
            },
            textStatus: true,
        };
        
        if (!editingMode) {
            props.onAdd(newFilter);
            CancelButtonHandler();
        }
        if (editingMode) {
            try {
                const url = `${server.apiUrl}/project/${props.project}/dynamicText/textUpdate/${props.indexCurrentText}`;
                const res = await axios.post(url, newFilter);
                
                let oldXml = [...XMLFiles];
                oldXml.push(res?.data?.projectXMLFILE);
                setXMLFiles(oldXml);
                
                const cloneAllAssetFilter = [...props.allAssetFilter];
                let singleFilter = cloneAllAssetFilter[props.indexCurrentText];

                let options = [];

                const entries = Object.entries(newFilter.params);
                Object.values(entries).map((el) =>
                    options.push({
                        name: el[0],
                        value: el[1],
                    })
                );

                singleFilter = options;
                cloneAllAssetFilter[props.indexCurrentText] = singleFilter;
                props.setAllAssetFilter(cloneAllAssetFilter);
                setEditingMode(false);
                toast.success(`${t("addText.textEditedSuccess")}`);

                props.setCurrentTextInfo(null);
                props.setOpenEditTextModal(false);
                props.setAddTextClicked(false);
            } catch (error) {
                toast.error(`${t("error.serverError")}`);
            }
        }

        return;
    };
    const textColourButtonHandler = () => {
        setShowColorGradientModalText(!showColorGradientModalText);
    };
    const backgroundColourButtonHandler = () => {
        setShowColorGradientModalBackground(!showColorGradientModalBackground);
    };
    const fontSizeHandler = (val) => {
        settextFontSize(val);
    };
    const textArgumentHandler = (val) => {
        settextArgument(val);
    };

    const xPositionChangeHandler = (val) => {
        setXPosition(val);
    };
    const yPositionChangeHandler = (val) => {
        setYPosition(val);
    };

    const changeTextFgColor = (el) => {
        sethexColor(el?.value);
    };

    const changeTextBgColor = (el) => {
        sethexColor1(el?.value);
    };
    useEffect(() => {
        if (props.currentTextInfo) {
            setEditingMode(true);
            setInn(props.currentTextInfo.in);
            setstartDuration(props.currentTextInfo.in);
            setOut(props.currentTextInfo.out);
            setXPosition(props.currentTextInfo.xPosition ? props.currentTextInfo.xPosition : xPosition);
            setYPosition(props.currentTextInfo.yPosition ? props.currentTextInfo.yPosition : yPosition);
            sethexColor(props.currentTextInfo.fgcolour);
            sethexColor1(props.currentTextInfo.bgcolour);
            settextArgument(props.currentTextInfo.argument);
            settextStyleFinalValue(props.currentTextInfo.style);
            setCurrentFamilyName(props.currentTextInfo.family);
            settextWeightFinalValue(props.currentTextInfo.weight == 200 ? "light" : props.currentTextInfo.weight == 400 ? "bold" : "regular");
            settextFontSize(props.currentTextInfo.size);
            //set the txtWidth, txtHeight from props.currentTextInfo
        }
    }, [props.currentTextInfo]);
    
    useEffect(() => {
        canvas = canvasRef.current;

        ctx = canvas.getContext("2d");

        canvasOffset = getOffset(canvas);
        offsetX = canvasOffset.left;
        offsetY = canvasOffset.top; 
        canvas.addEventListener("mousedown", (e) => {
            handleMouseDown(e);
        });
        canvas.addEventListener("mousemove", (e) => {
            handleMouseMove(e);
        });
       
        canvas.addEventListener("mouseup", (e) => {
            handleMouseUp(e);
        });
        canvas.addEventListener("mouseout", (e) => {
            handleMouseOut(e);
        });
    }, [canvasRef]);

    useEffect(() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext("2d");
        
        if (ctx) {
            const x = canvas.width;
            const y = canvas.height;
            txtPosX = Math.floor(x * (xPosition / 100));
            txtPosY = Math.floor(y * (yPosition / 100));
            if (txtPosX === 0) txtPosX = 40;
            if (txtPosY === 0) txtPosY = 40;
            
            fgColor = hexColor ? hexColor : "white";
            bgColor = hexColor1 ? hexColor1 : "transparent";
            argument = textArgument;
            fontSize = textFontSize;
            fontStyle = textStyleFinalValue;
            fontFamily = currentFamilyName;

            drawText();
        }
    }, [textArgument, hexColor, hexColor1, textFontSize, textWeightFinalValue, textStyleFinalValue, xPosition, yPosition, currentFamilyName]);
    
    useEffect(() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext("2d");
        
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            
            let timeStart;
            
            if (startDuration) {
                timeStart = startDuration;
            }
            
            if (timeStart && timeStart !== "") {
                let currentseconds, vstarttime, videoCurrentTime;

                const allItems = props.timelineRef.getItemsAtCurrentTime(TimelineModel.dateFromString(timeStart)).find((el) => el.includes("videotrack0") || el.includes("blankvidtrack"));
                const seekedvideoitem = props.timelineRef.itemsData.get().find((el) => el.id == allItems);
                
                if (seekedvideoitem) {
                    currentseconds = (TimelineModel.dateFromString(timeStart) - seekedvideoitem.start) / 1000;

                    // we need to find the current time of that particular video
                    vstarttime = getVideoStartTime(seekedvideoitem);
                    videoCurrentTime = vstarttime + currentseconds;

                    if (videoRef.current) {
                        videoRef.current.src = seekedvideoitem.url;
                        videoRef.current.currentTime = videoCurrentTime;
                        videoRef.current.load();
                    }
                } else {
                    videoRef.current.src = "blankVideo/blank.mp4#t=1";
                    videoRef.current.currentTime = 1;
                    videoRef.current.load();
                }
                drawImageAspectVideoFrame(ctx, videoRef.current);
                setTimeout(function () {
                    drawImageAspectVideoFrame(ctx, videoRef.current);
                    drawText();
                    setTimeout(function () {
                        drawImageAspectVideoFrame(ctx, videoRef.current);
                        drawText();
                    }, 300);
                }, 200);
            }
            drawText();
        }
    }, [startDuration]);

    const getOffset = (element) => {
        if (!element.getClientRects().length) {
            return { top: 0, left: 0 };
        }

        let rect = element.getBoundingClientRect();
        let win = element.ownerDocument.defaultView;
        return {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset,
        };
    };

    pi2 = Math.PI * 2;
    resizerRadius = 8;

    const drawText = () => {
        canvas = canvasRef.current;
        
        ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        if (startDuration && startDuration !== "") {
            drawImageAspectVideoFrame(ctx, videoRef.current);
        }
        let weight = 400;
        if (textWeightFinalValue === "light") {
            weight = 200;
        } else if (textWeightFinalValue === "bold") {
            weight = 800;
        }
        
        ctx.font = `${fontStyle} ${weight} ${fontSize}px ${fontFamily}`;
        ctx.textBaseline = "top";

        ctx.fillStyle = bgColor;
        let width = ctx.measureText(argument).width;
        
        txtWidth = width;
        txtHeight = parseInt(fontSize, 10);
        
        ctx.fillRect(txtPosX, txtPosY, width, parseInt(fontSize, 10));
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.strokeRect(txtPosX, txtPosY, width, parseInt(fontSize, 10));

        drawDragAnchor(txtPosX, txtPosY);
        drawDragAnchor(txtPosX + width, txtPosY);
        drawDragAnchor(txtPosX + width, txtPosY + txtHeight);
        drawDragAnchor(txtPosX, txtPosY + txtHeight);

        ctx.fillStyle = fgColor;
        ctx.fillText(argument, txtPosX, txtPosY);
    };

    const drawDragAnchor = (x, y) => {
        ctx.fillStyle='red';
        ctx.beginPath();
        ctx.arc(x, y, resizerRadius, 0, pi2, false);
        ctx.closePath();
        ctx.fill();
    };

    const anchorHitTest = (x, y) => {
        if (txtPosX - resizerRadius < x && txtPosX + resizerRadius > x && txtPosY - resizerRadius < y && txtPosY + resizerRadius > y) {
            return 0;
        }
        if (txtPosX - resizerRadius + txtWidth < x && txtPosX + resizerRadius + txtWidth > x && txtPosY - resizerRadius < y && txtPosY + resizerRadius > y) {
            return 1;
        }
        if (txtPosX - resizerRadius + txtWidth < x&& txtPosX + resizerRadius + txtWidth > x && txtPosY - resizerRadius + txtHeight < y && txtPosY + resizerRadius + txtHeight > y) {
            return 2;
        }
        if (txtPosX - resizerRadius < x && txtPosX + resizerRadius > x && txtPosY - resizerRadius + txtHeight < y && txtPosY + resizerRadius + txtHeight > y) {
            return 3;
        }
        return -1;
    };
    const hitImage = (x, y) => {
        return x > txtPosX && x < txtPosX + txtWidth && y > txtPosY && y < txtPosY + txtHeight;
    };

    const handleMouseDown = (e) => {
        startX = parseInt(e.clientX - offsetX);
        startY = parseInt(e.clientY - offsetY);
        // check mouse point is in Text
        draggingResizer = anchorHitTest(startX, startY);
        mouseDown = hitImage(startX, startY) || anchorHitTest(startX, startY) > -1;
    };
   
    const handleMouseUp = (e) => {
        mouseDown = false;
        let canvas1 = canvasRef.current;
        const x = canvas1.width;
        const y = canvas1.height;
        let newXPoistion = Math.floor((txtPosX)/x * 100);
        let newYPosition = Math.floor((txtPosY)/y * 100);
        
        setYPosition(newYPosition);
        setXPosition(newXPoistion);
        draggingResizer = -1;
        canvas.style.cursor = "default";
    };
    const handleMouseOut = (e) => {
        handleMouseUp(e);
    };

    const handleMouseMove = (e) => {
        offsetX = offsetX * 1;
        offsetY = offsetY * 1;
        if (mouseDown) {
            mouseX = parseInt(e.clientX - offsetX);
            mouseY = parseInt(e.clientY - offsetY);
            // move the image by the amount of the latest drag
            var dx = mouseX - startX;
            var dy = mouseY - startY;
            var fontResize = parseInt(Math.sqrt(dx * dx + dy * dy) / 2);
            let fontRealSize = parseInt(fontSize);
            if (draggingResizer > -1) {
                switch (draggingResizer) {
                    case 0:
                        if(dx > 0 && dy > 0) settextFontSize(fontRealSize - fontResize);
                        else settextFontSize(fontRealSize + fontResize);
                        canvas.style.cursor = "nw-resize";
                        break;
                    case 1:
                        if(dx < 0 && dy > 0) settextFontSize(fontRealSize - fontResize);
                        else settextFontSize(fontRealSize + fontResize);
                        canvas.style.cursor = "ne-resize";
                        break;
                    case 2:
                        if(dx < 0 && dy < 0) settextFontSize(fontRealSize - fontResize);
                        else settextFontSize(fontRealSize + fontResize);
                        canvas.style.cursor = "nw-resize";
                        break;
                    case 3:
                        if(dx > 0 && dy < 0) settextFontSize(fontRealSize - fontResize);
                        else settextFontSize(fontRealSize + fontResize);
                        canvas.style.cursor = "ne-resize";
                        break;
                }
                // canvas.style.cursor = "ew-resize";
                // redraw the image with resizing anchors
                drawText();
            }else {
                
                let canvas1 = canvasRef.current;
                txtPosX += dx;
                txtPosY += dy;
                drawText();
                // reset the startXY for next time
                canvas1.style.cursor = "auto";
            }
            startX = mouseX;
            startY = mouseY;
        } else {
            const startX = parseInt(e.clientX - offsetX);
            const startY = parseInt(e.clientY - offsetY);
            // check mouse point is in Text
            draggingResizer = anchorHitTest(startX, startY);
            if (draggingResizer > -1) {
                switch (draggingResizer) {
                    case 0:
                        canvas.style.cursor = "nw-resize";
                        break;
                    case 1:
                        canvas.style.cursor = "ne-resize";
                        break;
                    case 2:
                    canvas.style.cursor = "nw-resize";
                        break;
                    case 3:
                        canvas.style.cursor = "ne-resize";
                        break;
                }
            } else {
                canvas.style.cursor = "default";
            }
        }
    };
    
    return (
        <>
            {showColorGradientModalText && <ColorGrading sethexColor={sethexColor} closeHandleDialog={textColourButtonHandler} />}
            {showColorGradientModalBackground && <ColorGrading sethexColor1={sethexColor1} closeHandleDialog={backgroundColourButtonHandler} />}

            <Modal show="true">
                <h2 className={classes.addTextH2}>{props.openEditTextModal ? `${t("addText.editText")}` : `${t("addText.addText")}`}</h2>
                <div 
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <canvas id="canvas103" ref={canvasRef} width="640" height="360" style={{ background: "black", zIndex: 3 }}></canvas>
                    <video controls={false} preload="metadata" ref={videoRef} style={{ opacity: 0, position: "absolute", width: "640px", zIndex: 1 }}></video>
                </div>
                <div className="text-modal" style={{ display: "flex", flexDirection: "column" }}>
                    <div className="filterForm">
                        <Input
                            label={`${t("addText.text")}`}
                            onChange={(e) => {
                                textArgumentHandler(e.target.value);
                            }}
                            type="text"
                            required
                            value={textArgument}
                        />
                        <Input
                            element="select"
                            onChange={changeTextFont}
                            value={textStyleFinalValue}
                            selectedList={textStyle}
                            label={`${t("addText.fontStyle")}`}
                        />
                    </div>
                    <div className="filterForm">
                        <Input
                            element="select"
                            onChange={changeTextWeight}
                            value={
                                textWeightFinalValue
                            }
                            selectedList={textWeight}
                            label={`${t("addText.fontWeight")}`}
                        />
                        <div className="filterInput">
                            {/* // if no brand color is defined use color picker instead */}

                            {globalBrandColor?.length > 0 ? (
                                <Input
                                    element="react-select"
                                    onChange={changeTextFgColor}
                                    selectedList={globalBrandColorList}
                                    label={`${t("addText.textColour")}`}
                                    placeholder={`${t("addText.textColour")}`}
                                    getOptionLabel={(option) => (
                                        <div style={{ display: 'flex'}}>
                                            <div style={{width: '60px', height: '24px', borderRadius: '4px', border: '1px solid #777', marginRight: '4px', backgroundColor: `${option.color === 'default' ? 'white' : option.color}`}}>&nbsp;</div>
                                            <span style={{ color: `${ option.color === 'default' || hexColor === option.color ? 'white': 'black'}`}}>{option.label}</span>
                                        </div>
                                    )}
                                    defaultValue={{
                                        label: props?.currentTextInfo ? props?.currentTextInfo?.fgcolour : `${t("addText.textColour")}`,

                                        value: props?.currentTextInfo ? props?.currentTextInfo?.fgcolour : "",
                                        color: props?.currentTextInfo ? props?.currentTextInfo?.fgcolour : 'default',
                                    }}
                                />
                            ) : (
                                <>
                                    <label htmlFor={"duration"}>{`${t("addText.textColour")}`}</label>
                                    <div className="textColour">
                                        <div className="textColour-input"></div>
                                        <div className="textColour-button">
                                            {" "}
                                            <p>{(hexColor && hexColor) || (props.currentTextInfo && props?.currentTextInfo?.fgcolour)}</p>
                                            <button onClick={textColourButtonHandler}>{hexColor ? `${t("addText.change")}` : `${t("addText.select")}`}</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>{" "}
                    </div>
                    <div className="filterForm">
                        {globalBrandColor?.length > 0 ? (
                            <Input
                                onChange={changeTextBgColor}
                                selectedList={globalBrandColorList}
                                label={`${t("addText.backgroundColour")}`}
                                element="react-select"
                                placeholder={`${t("addText.backgroundColour")}`}
                                getOptionLabel={(option) => (
                                    <div style={{ display: 'flex'}}>
                                        <div style={{width: '60px', height: '24px', borderRadius: '4px', border: '1px solid #777', marginRight: '4px', backgroundColor: `${option.color ==='default' ? 'transparent' : option.color}`}}>&nbsp;</div>
                                        <span style={{ color: `${ option.color === 'default' || hexColor1 === option.color ? 'white': 'black'}`}}>{option.label}</span>
                                    </div>
                                )}
                                defaultValue={{
                                    label: props?.currentTextInfo ? props?.currentTextInfo?.bgcolour : `${t("addText.backgroundColour")}`,
                                    value: props?.currentTextInfo ? props?.currentTextInfo?.bgcolour : " ",
                                    color: props?.currentTextInfo ? props?.currentTextInfo?.bgcolour : 'default',
                                }}
                            />
                        ) : (
                            <div className="filterInput">
                                <label htmlFor={"duration"}>{`${t("addText.backgroundColour")}`}</label>
                                <div className="textColour">
                                    <div className="textColour-input"></div>
                                    <div className="textColour-button">
                                        {" "}
                                        <p> {(hexColor1 && hexColor1) || (props.currentTextInfo && props.currentTextInfo.bgcolour)}</p>
                                        <button onClick={backgroundColourButtonHandler}>
                                            {hexColor1 || (props.currentTextInfo && props.currentTextInfo.bgcolour) ? "Change" : "Select"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Input
                            type="number"
                            label={`${t("addText.textFontSizeInPixel")}`}
                            required
                            value={textFontSize}
                            onChange={(e) => {
                                fontSizeHandler(e.target.value);
                            }}
                        />
                    </div>
                    <div className="filterForm">
                        <Input
                            label={`${t("addText.xPosition")}`}
                            labelSpan={
                                <span style={{ fontSize: "10px" }}>
                                    <br />
                                    {`${t("addText.valueRange")}`}
                                    (-100 - 100)
                                </span>
                            }
                            type="number"
                            max="100"
                            min="-100"
                            required
                            value={xPosition}
                            onChange={(e) => xPositionChangeHandler(e.target.value)}
                        />

                        <Input
                            type="number"
                            label={`${t("addText.yPosition")}`}
                            required
                            value={yPosition}
                            max="100"
                            min="-100"
                            onChange={(e) => yPositionChangeHandler(e.target.value)}
                            labelSpan={
                                <span style={{ fontSize: "10px" }}>
                                    <br />
                                    {`${t("addText.valueRange")}`}
                                    (-100-100)
                                </span>
                            }
                        />
                    </div>

                    <div className="filterForm">
                        <Input
                            element="datetime"
                            input={true}
                            timeFormat="HH:mm:ss,SSS"
                            dateFormat={false}
                            className="datetime-picker"
                            timeConstraints={{
                                hours: { min: 0, max: 99 },
                                minutes: { min: 0, max: 59 },
                                seconds: { min: 0, max: 59 },
                                milliseconds: { min: 0, max: 999 },
                            }}
                            value={inn ? inn : startDuration}
                            onChange={(e) => startTimeHandler(e)}
                            labelSpan={
                                <span style={{ fontSize: "10px" }}>
                                    <br />

                                    {`${t("addText.hour-minute-second-milli")}`}
                                </span>
                            }
                            label={`${t("addText.startTime")}`}
                        />

                        <Input
                            element="datetime"
                            input={true}
                            timeFormat="HH:mm:ss,SSS"
                            dateFormat={false}
                            className="datetime-picker"
                            timeConstraints={{
                                hours: { min: 0, max: 99 },
                                minutes: { min: 0, max: 59 },
                                seconds: { min: 0, max: 59 },
                                milliseconds: { min: 0, max: 999 },
                            }}
                            value={out ? out : endDuration}
                            onChange={(e) => endTimeHandler(e)}
                            label={`${t("addText.endTime")}`}
                            labelSpan={
                                <span style={{ fontSize: "10px" }}>
                                    <br />
                                    {`${t("addText.hour-minute-second-milli")}`}
                                </span>
                            }
                        />
                    </div>
                    <div className="filterForm">
                        <Input
                            element="select"
                            onChange={changeTextFamily}
                            value={currentFamilyName}
                            selectedList={familyName}
                            label={`${t("addText.fontFamily")}`}
                        />
                        <Input
                            style={{ visibility: "hidden" }}
                        />
                    </div>

                    <div className={classes.addTextH2}>
                        <Button onClick={formHandler} inverse>
                            {editingMode ? "Edit" : "Add"}
                        </Button>
                        <Button onClick={CancelButtonHandler}> {`${t("shared.cancel")}`}</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
