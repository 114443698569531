import React from "react";
import classes from "./Input.module.css";
import Datetime from "react-datetime";
import Select from "react-select";

const defaultColorStyles = {
    control: (styles, state) => ({
        ...styles,
        height: "50px",
        minHeight: "50px",
        maxHeight: "50px",
        with: "250px",
        maxWidth: "250px",
        minWidth: "250px",
        border: "1px solid #555",
        background: "#25282d",
        color: "white",
    }),
    option: (styles, { data }) => {
        console.log("data", data);
        return {
            ...styles,
            color: data.color,
            ":active": {
                ...styles[":active"],
                backgroundColor: data.color,
            },
        };
    },
    singleValue: (styles, { data }) => ({ ...styles, color: data.color }),
    placeholder: (styles) => ({ ...styles, color: "white" }),
};

const defaultCropStyles = {
    control: (styles, state) => ({
        ...styles,
        height: "40px",
        minHeight: "40px",
        maxHeight: "40px",
        with: "150px",
        maxWidth: "150px",
        minWidth: "150px",
        border: "1px solid #555",
        background: "#25282d",
        color: "white",
    }),
    option: (styles, { data }) => {
        return {
            ...styles,
            ":active": {
                ...styles[":active"],
            },
        };
    },
    singleValue: (styles, { data }) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: "white" }),
};

const Input = ({
    label,
    onChange,
    value,
    style,
    required,
    type,
    labelSpan,
    element = "input",
    selectedList,
    colorStyles,
    removeMainClass,
    stylesLabel,
    ...props
}) => {
    if (element === "input") {
        return (
            <div className={classes.filterInput} style={style}>
                <label>
                    {label}

                    {labelSpan && labelSpan}
                </label>
                <input type={type} required value={value} onChange={onChange} {...props} />
            </div>
        );
    } else if (element === "datetime") {
        return (
            <div className={!removeMainClass ? `${classes.filterInput}` : `${classes.filterInputDateTime}`} style={style}>
                <label>
                    {label}

                    {labelSpan && labelSpan}
                </label>
                <Datetime value={value} onChange={onChange} {...props} />
            </div>
        );
    } else if (element === "select") {
        return (
            <div className={removeMainClass ? `${classes.filterInput1}` : `${classes.filterInput}`} style={style}>
                <label>
                    {label}

                    {labelSpan && labelSpan}
                </label>
                <select onChange={onChange}>
                    <option>{value}</option>

                    {selectedList.map((el) => (
                        <option key={el} value={el}>
                            {el}
                        </option>
                    ))}
                </select>
            </div>
        );
    } else if (element === "react-select") {
        return (
            <div className={removeMainClass ? `${classes.filterInput1}` : `${classes.filterInput}`} style={style}>
                <label style={stylesLabel}>
                    {label}

                    {labelSpan && labelSpan}
                </label>

                <Select options={selectedList} styles={defaultColorStyles} onChange={onChange} {...props}/>
            </div>
        );
    } else if (element === "react-select-crop") {
        return (
            <div className="d-flex" style={style}>
                <Select options={selectedList} styles={defaultCropStyles} onChange={onChange} {...props}/>
            </div>
        );
    }
};

export default Input;
