import React from "react";
import Modal from "react-modal";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import filters from "../../filters";
import { server } from "../../../config";
import LoadingDialog1 from "../LoadingDialog1";
import { useMyContext } from "../../contexts/StateHolder";
import Button from "../../shared/UI/Button/Button";

export default function ShowAllFilterModal(props) {
    const { t } = useTranslation();

    const { setXMLFiles } = useMyContext();

    const item = props.getItem(props.item);

    const getFilter = (id) => {
        for (let filter of filters.videoFilters) {
            if (filter.id === id) {
                return filter;
            }
        }
        for (let filter of filters.audioFilters) {
            if (filter.id === id) {
                return filter;
            }
        }
        return null;
    };

    const handleEditFilter = (id) => {
        if (id === "brightness") {
            props.setfilterName("Brightness");
        }
        if (id === "lift_gamma_gain") {
            props.setfilterName("Background Colour");
        }
        if (id === "fadeOutVolume") {
            props.setfilterName("Fade Out Volume");
        }
        if (id === "fadeInVolume") {
            props.setfilterName("Fade In Volume");
        }
        if (id === "fadeOutBrightness") {
            props.setfilterName("Fade Out Brightness");
        }
        if (id === "fadeInBrightness") {
            props.setfilterName("Fade In Brightness");
        }
        if (id === "dynamictext") {
            return props.setshowAddTextModal(true);
        }

        props.setshowAddFilterDialog(true);
        props.setOpenAddFilterDialogEdit(true);
        props.setShowAllFilterModal(false);
    };

    const handleDelFilter = (filterId) => {
        const itemPath = props.item.split(":");
        const url = `${server.apiUrl}/project/${props.project}/filter`;
        const bodyParams = {
            track: itemPath[0],
            item: Number(itemPath[1]),
            filter: filterId,
        };
        const params = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyParams),
        };

        fetch(url, params)
            .then((response) => response.json())
            .then((data) => {
                if (typeof data.err === "undefined") {
                    console.log(data);
                    setXMLFiles((el) => [...el, data?.projectXMLFILE]);

                    props.onDel(bodyParams, data);
                    toast.info("Effect Deleted Succesfully");
                }
            })
            .catch((error) => toast.error("Server Error.Please try Later"));
    };
    const handleCloseDialog = () => {};

    const closeButtonHandler = () => {
        props.setshowAddFilterDialog(false);
        props.setOpenAddFilterDialogEdit(false);
        props.setShowAllFilterModal(false);
    };
    return (
        <Modal isOpen={true} contentLabel="Add New FIlter" className={"modal"} overlayClassName={"overlay"} onRequestClose={handleCloseDialog}>
            {props.loading ? (
                <LoadingDialog1 />
            ) : (
                <>
                    <div>
                        <h2 style={{ padding: "20px", fontFamily: "Lato" }}>{t("showAllFilterModal.allAppliedEffects")}</h2>
                        <table>
                            <tbody>
                                {item.filters.length === 0 && (
                                    <tr>
                                        <td>{t("showAllFilterModal.noAffectYet")}</td>
                                    </tr>
                                )}

                                {item.filters.map((filter, i) => {
                                    let title = getFilter(filter.service).title;

                                    return (
                                        <tr key={i}>
                                            <td>{title}</td>
                                            <td>
                                                <Button
                                                    inverse
                                                    onClick={() => handleEditFilter(filter.service)}
                                                    style={{
                                                        padding: "5px 10px",
                                                        margin: 0,
                                                        minWidth: "auto",
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="edit" />
                                                    {t("shared.edit")}
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    danger
                                                    onClick={() => handleDelFilter(filter.service)}
                                                    style={{
                                                        padding: "5px 10px",
                                                        margin: 0,
                                                        minWidth: "auto",
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="trash" />
                                                    {t("shared.delete")}
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button onClick={closeButtonHandler} inverse>
                            {t("shared.close")}
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
}
