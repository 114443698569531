import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import timeManager from "../../../models/timeManager";
import { splitTime } from "../../shared/utils";

export default function TransitionModal(props) {
    console.log(props);
    const { t } = useTranslation();
    const [startDuration, setstartDuration] = useState(3);
    const [selectedTranstion, setSelectedTranstion] = useState("");
    const [editingItem, setEditingItem] = useState(null);
    const [editingItemLumaFilter, setEditingItemLumaFilter] = useState(null);
    const [editingMode, setEditingMode] = useState(false);

    // wipe vertical ->luma02.pgm
    // wipe horizonal ->luma01.pgm
    // dissolve -<put empty
    // diagonal top-letf -< luma07.pgm
    // diagonal top-right -< luma08.pgm

    useEffect(() => {
        if (props.selectedItems[0]) {
            let item = props.getItemFromTrackIndex(props.selectedItems[0]);
            if (item?.transitionTo && item?.transitionFrom) {
                let transitionFilter = Object.values(item?.videoTransitions).find((el) => el.service === "luma").options;
                if (transitionFilter?.length > 0) {
                    // this is editing mode
                    setEditingMode(true);
                    setEditingItem(item);
                    setEditingItemLumaFilter(transitionFilter[0]);

                    let t = timeManager.subDuration(transitionFilter[0].out, transitionFilter[0].in);
                    let t1 = splitTime(t);
                    setstartDuration(t1);
                    let i1 = listOfTransitions.find((el) => el.resource === transitionFilter[0].resource && el.invert * 1 === transitionFilter[0].invert * 1);
                    setSelectedTranstion(i1?.name);
                }
            }
        }
    }, []);

    const listOfTransitions = [
        {
            id: 1,
            transition: "luma",
            resource: "luma01.pgm",
            name: "Wipe Right",
            // here invert should be 0
            invert: 0,
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/WipeRight.png",
        },
        {
            id: 2,
            transition: "luma",
            resource: "luma01.pgm",
            name: "Wipe Left",
            invert: 1,
            // here invert should be 1
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/WipeLeft.png",
        },
        {
            id: 3,
            transition: "luma",
            resource: "luma02.pgm",
            name: "Wipe Up",
            invert: 0,
            // here invert should be 0
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/WipeUp.png",
        },
        {
            id: 4,
            transition: "luma",
            resource: "luma02.pgm",
            name: "Wipe Down",
            invert: 1,
            // here invert should be 1
            image: "https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/WipeDown.png",
        },
    ];

    const insertTransition = (el) => {
        console.log(el);
        if (!startDuration || !selectedTranstion) {
            return toast.error(`Please select both time and transition`);
        }

        let item = listOfTransitions.find((el) => el?.name === selectedTranstion);
        props.addTransitionHandler(item, startDuration, editingItemLumaFilter);
        props.setShowTransitionModal(false);
    };
    const deleteTransition = (el) => {
        if (!startDuration || !selectedTranstion) {
            return toast.error(`Please select both time and transition`);
        }

        let item = listOfTransitions.find((el) => el?.name === selectedTranstion);
        props.deleteTransitionHandler(item, startDuration, editingItemLumaFilter);
        props.setShowTransitionModal(false);
    };

    return (
        <>
            <Modal isOpen={true} contentLabel="Select Any One" className={"modalTransition"} overlayClassName={"overlayAsset"}>
                <h2 style={{ textAlign: "center", paddingBottom: "20px" }}>Video Transition</h2>

                {editingMode && editingItem && (
                    <table>
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        padding: "20px auto",
                                        background: "#6c4343 none repeat scroll 80% 0%",
                                        textAlign: "center",
                                        fontSize: "10px",
                                    }}
                                >
                                    {t("addFilterDialog.currentlyEditing")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}

                <div className="wrapperPrimary">
                    {listOfTransitions.map((el) => (
                        <div className="wrapper" key={el?.id.toString()}>
                            <div className="product-img">
                                <img src={el?.image} />
                            </div>
                            <div className="product-info">
                                <div className="product-text" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <h3>{el.name}</h3>
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        padding: "10px 30px",
                                        background: el?.name === selectedTranstion ? "#147aa9" : "",
                                        minWidth: "150px",
                                        marginTop: "20px !important",
                                    }}
                                    onClick={() => setSelectedTranstion(el?.name)}
                                >
                                    {/* {t("shared.import")} */}
                                    {/* Select */}
                                    {el?.name === selectedTranstion ? "Selected" : "Select"}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="filterForm">
                    <div className="filterInput">
                        <label htmlFor={"duration"}>{t("shared.duration")}</label>{" "}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {" "}
                            <span>
                                {startDuration} {t("shared.second")}
                            </span>{" "}
                            {/* https://stackoverflow.com/questions/56591039/html-range-input-wont-fill-all-the-way-when-step-greater-than-remaining-value */}
                            <input
                                type={"range"}
                                name={"level"}
                                min={1}
                                max={3}
                                style={{ padding: "0" }}
                                step={1}
                                value={startDuration}
                                onChange={(e) => {
                                    console.log(e.target);
                                    setstartDuration(e.target.value);
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>1</div>
                                <div>2</div>
                                <div>3</div>
                                {/* <div>4</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "0", paddingBottom: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                        onClick={insertTransition}
                        style={{
                            padding: "10px 30px",
                            minWidth: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#147aa9",
                        }}
                        disabled={!startDuration || !selectedTranstion}
                    >
                        {editingItem && editingMode ? "Edit" : "Insert"}
                        {/* {`${t("shared.cancel")}`} */}
                    </button>
                    {editingMode && editingItem && (
                        <button
                            onClick={deleteTransition}
                            style={{
                                padding: "10px 30px",
                                minWidth: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgb(108, 67, 67)",
                            }}
                        >
                            {/* {`${t("shared.cancel")}`} */}
                            Delete
                        </button>
                    )}
                    <button
                        onClick={() => {
                            props.setShowTransitionModal(false);
                        }}
                        style={{
                            padding: "10px 30px",
                            minWidth: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {`${t("shared.cancel")}`}
                    </button>
                </div>
                <p
                    style={{
                        textAlign: "center",
                        padding: "10px 0",
                        // background: "#0a7aad none repeat scroll 80% 0%",
                        fontSize: "12px",
                    }}
                >
                    Note:Transitions will be visible only in final video in suite and not be available in preview.
                </p>
            </Modal>
        </>
    );
}
