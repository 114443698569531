import React, { useState } from "react";
import { SketchPicker } from "react-color";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

// Modal.setAppElement(document.body);

export default function ColorGrading(props) {
    const { t } = useTranslation();

    const [background, setbackground] = useState("#fff");
    
    const handleCloseDialog = () => {
        props.closeHandleDialog();
    };

    const handleChange = (color) => {
        setbackground(color.hex);
        props.sethexColor && props.sethexColor(color.hex);
        props.sethexColor1 && props.sethexColor1(color.hex);

        // to change color value to percent multiply by 0.39 for melt
        props.setgradientColor &&
            props.setgradientColor({
                r: color.rgb.r * 0.39,
                g: color.rgb.g * 0.39,
                b: color.rgb.b * 0.39,
            });
    };
    return (
        <Modal
            isOpen={true}
            contentLabel="Color Grading"
            className={"modalColor"}
            overlayClassName={"overlayloadingDialog1"}
            onRequestClose={handleCloseDialog}
        >
            <h2 style={{ padding: "20px" }}>{t("shared.selectColor")}</h2>
            <SketchPicker color={background} onChangeComplete={handleChange} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px ",
                }}
            >
                <button onClick={props.showColorGradientModal || props.closeHandleDialog} style={{ padding: "10px 30px", background: "#0A7AAD" }}>
                    {t("shared.select")}
                </button>
                <button onClick={props.closeHandleDialog} style={{ padding: "10px 30px" }}>
                    {t("shared.cancel")}
                </button>
            </div>
        </Modal>
    );
}
